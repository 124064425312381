import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ProtectedComponent from '../base/ProtectedComponent';
import Dashboard from '../Dashboard';
import Home from '../Home';
import Login from '../Login';
import ProfileView from '../ProfileView';
import RouteNoMatch from '../RouteNoMatch';
import Users from '../user/Users';
import UserFullEdit from '../user/UserFullEdit';
import UserNew from '../user/UserNew';
import Registration from '../Registration';
import ConnectionSettings from '../ConnectionSettings';
import Settings from '../../entities/Settings';
import Profile from '../../entities/Profile';
import NetworkDevices from '../network-device/NetworkDevices';
import AccessLogView from '../access-log/AccessLogView';

const routes = (
    <Switch>
        <Route exact path='/registration'>
            <Registration />
        </Route>
        <Route exact path='/login'>
            <Login />
        </Route>
        <Route exact path='/'>
            <ProtectedComponent component={ <Home /> } action='redirect' />
        </Route>
        <Route exact path='/dashboard'>
            <ProtectedComponent component={ <Dashboard /> } />
        </Route>
        <Route exact path='/users'>
            <ProtectedComponent component={ <Users /> } />
        </Route>
        {/* <Route exact path='/users/user/:id'>
            <ProtectedComponent component={ <UserFullEdit /> } />
        </Route> */}
        <Route exact path='/users/new-user'>
            <ProtectedComponent component={ <UserNew /> } />
        </Route>
        <Route exact path='/network-devices'>
            <ProtectedComponent component={ <NetworkDevices /> } />
        </Route>
        <Route exact path='/access-log'>
            <ProtectedComponent component={ <AccessLogView /> } />
        </Route>
        <Route exact path='/connection-settings'>
            <ProtectedComponent component={ <ConnectionSettings Model={new Settings(true)}/> } />
        </Route>
        <Route exact path='/profile'>
            <ProtectedComponent component={ <ProfileView  Model={new Profile(true)}/> } />
        </Route>
        <Route exact path='*'>
            <RouteNoMatch />
        </Route>
    </Switch>
);

export default routes;