import React, { useEffect, useState, Fragment } from 'react';
import { defaultAPIClient } from '../api-client/config';
import { requestAPI } from '../actions/apiRequestActions';
import { CircularProgress, Card, CardContent, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import CRUDLEscortFlatEntityFormComponent from './common/crudl/CRUDLEscortFlatEntityFormComponent';

export default connect(null, {
    requestAPI,
})(function ConnectionSettings({
    Model,
    requestAPI
}) {

    const [loading, setLoading] = useState(true);
    const [connectionSettings, setConnectionSettings] = useState(null);

    useEffect(() => {
        requestAPI({
            apiClient: defaultAPIClient,
            requestParams: {
                url: Model.getDefinitions().endpoints.getSingle.path,
                method: 'GET',
            },
            requestAlias: Model.getDefinitions().endpoints.getSingle.alias,
            responseCallBack: (response) => { 
                processAPIResponse(response);
            },
        });
    }, []);

    const processAPIResponse = (response) => {
        setConnectionSettings(Model.mapDataToProperties(response.data));
        setLoading(false);
    }

    return(
        <Fragment>
            {loading
                ? <CircularProgress />
                : 
                <Fragment>
                    {connectionSettings &&
                        <Card variant="outlined">
                            <CardContent>
                                <Typography variant="h6" component="h2">
                                    {Model.getDefinitions().decorators.singleHeading}
                                    <hr/>
                                </Typography>


                                <CRUDLEscortFlatEntityFormComponent
                                    Model={Model} 
                                    selectedEntity={connectionSettings}
                                    labelsOrientation='row'
                                    showLabeledOnly={true}
                                    minLabelsColumnWidth={'200px'}
                                />

                            </CardContent>
                        </Card>
                    }
                </Fragment>
            }
         </Fragment>
    );
})