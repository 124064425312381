import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { toggleSideMenuBar, selectSideMenuItem } from '../actions/sideMenuBarActions';
import { Box, List, ListSubheader, ListItem, ListItemIcon, ListItemText, Hidden, Button, Collapse } from '@material-ui/core';
import { menuContentPadding } from '../ui-constants';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import menu from './config/menu';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const useStyles = theme => ({
    sideMenu: {
        flexGrow: 1,
        flexDirection: 'column',
        height: '100vh',
        padding: menuContentPadding,
        [theme.breakpoints.up('md')]: {
            marginTop: -10,
        }
    },
    sideMenuList: {
        width: '100%',
    },
    brandLogo: {
        height: 'auto',
        maxWidth: '200px',
    },
    nestedMenuSibling: {
        paddingLeft: theme.spacing(4),
    },
});

class SideMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        menu.forEach((menuItem, index) => {
            /* Set default expansions from menu config*/
            if (menuItem.siblings && menuItem.siblings.length > 0) {
                const menuItemKey = 'menuItemExpandable' + index;
                this.setState({ [menuItemKey]: menuItem.open === true ? true : false });
            }
        });
    }

    constructSingleListItem(menuItem, index, sIndex, menuExpandableItemKey) {
        const { 
            classes,
            toggleSideMenuBar,
            selectSideMenuItem,
            selectedMenuItem,
            menuBarCollapsed,
        } = this.props;

        const {
            pathname
        } = this.props.location;

        let collapseIcon;
        if (menuItem.siblings && menuItem.siblings.length > 0) collapseIcon = this.state['menuItemExpandable' + index] ? <ExpandLess /> : <ExpandMore />;
        const menuItemKey = 'menuItem' + index + (sIndex !== undefined ? '_' + sIndex : '');
        const pathnameMatchSelected = (menuItem.route === pathname);

        // console.log(menuItem.route + '  ?  ' + pathname);

        // if (pathnameMatchSelected) selectSideMenuItem(null);

        return (
            <ListItem 
                button 
                // selected={((selectedMenuItem === menuItemKey) || pathnameMatchSelected)}
                selected={pathnameMatchSelected}
                component={menuItem.route ? RouterLink : null} to={menuItem.route} 
                onClick={() => {
                    if (menuItem.route 
                        /* do not toggle if route is not changed */
                        && pathname !== menuItem.route) {
                            toggleSideMenuBar();
                    }
                    if (menuItem.siblings && menuItem.siblings.length > 0) this.handleExpandableMenuItemClick('menuItemExpandable' + index);
                    selectSideMenuItem(menuItemKey);
                }} 
                key={menuItemKey} 
                className={menuExpandableItemKey ? classes.nestedMenuSibling : null}
            >
                {menuItem.icon &&
                    <ListItemIcon>
                        {menuItem.icon}
                    </ListItemIcon>
                }
                {!menuBarCollapsed &&
                    <ListItemText primary={menuItem.label} />
                }
                {collapseIcon}
            </ListItem>
        )
    }

    constructExpandableListItem = (menuItem, index) => {
        const {
            pathname
        } = this.props.location;

        let expandableMenuItem = [this.constructSingleListItem(menuItem, index)];
        const menuExpandableItemKey = 'menuItemExpandable' + index;

        let pathnameMatchExpanded = null;
        // if (menuItem.route === pathname) pathnameMatchExpanded = true;
        menuItem.siblings.forEach(menuItemSibling => {
            if (menuItemSibling.route === pathname) {
                pathnameMatchExpanded = true;
            }
        });

        const menuItemSiblingsCollapsable = 
            <Collapse in={this.state[menuExpandableItemKey] || pathnameMatchExpanded === true} timeout='auto' unmountOnExit key={menuExpandableItemKey}>
                <List component='div' disablePadding>
                    {menuItem.siblings.map((menuItemSibling, sIndex) => (
                        this.constructSingleListItem(menuItemSibling, index, sIndex, menuExpandableItemKey)
                    ))}
                </List>
            </Collapse>;
        expandableMenuItem = [...expandableMenuItem, menuItemSiblingsCollapsable];
        return expandableMenuItem;
    }

    handleExpandableMenuItemClick(menuItemKey){
        this.setState({ [menuItemKey]: !this.state[menuItemKey] });
    }

    render() {
        const { 
            classes,
            toggleSideMenuBar,
            toggleMenuBarCollapsed,
            menuBarCollapsed,
        } = this.props;

        return (
            <Box className={classes.sideMenu}>
                <List className={classes.sideMenuList}
                    component='nav'
                    subheader={
                        <ListSubheader component='div' id='menu-list-subheader'>
                          <Hidden mdUp>
                            {menuBarCollapsed 
                            ? 
                                <img src='./favicon.ico' alt='AuthVela' style={{ height: 30, marginTop: 10 }}/>
                            : 
                                <Button fullWidth component={RouterLink} to='/' onClick={toggleSideMenuBar}>
                                    <img src='./logo.png' alt='AuthVela' className={classes.brandLogo} />
                                </Button>
                            }
                          </Hidden>
                        </ListSubheader>
                    }
                >
                    {/**
                     * 
                     * Collapse / Expand menu 
                     * 
                     * */}
                    <ListItem style={{ borderBottom: 'solid 1px', padding: 0 }}>
                        <Box style={{ width: '100%', textAlign: 'right' }}>
                            <Button
                                onClick={toggleMenuBarCollapsed}
                                style={{ borderRadius: 0 }}
                            >
                                {menuBarCollapsed ? <ArrowForwardIosIcon color='secondary'/> : <ArrowBackIosIcon color='secondary' />}
                            </Button>
                        </Box>
                    </ListItem>

                     <Fragment>
                        {menu.map((menuItem, index) => (
                            <Fragment key={'menuItemFragment' + index}>
                                { (menuItem.siblings.length === 0) ? this.constructSingleListItem(menuItem, index) : this.constructExpandableListItem(menuItem, index) }
                            </Fragment>
                        ))}
                    </Fragment>
                </List>
            </Box>
        )
    }
}


const mapStateToProps = state => ({
    selectedMenuItem: state.sideMenuBarReducer.selectedMenuItem,
});

export default connect(mapStateToProps, { 
    toggleSideMenuBar,
    selectSideMenuItem,
})(withTheme(withStyles(useStyles)(withRouter(props => <SideMenu {...props}/>))));


SideMenu.propTypes = {
    toggleSideMenuBar: PropTypes.func,
    selectSideMenuItem: PropTypes.func,
    toggleMenuBarCollapsed: PropTypes.func,

    classes: PropTypes.object,
    selectedMenuItem: PropTypes.string,
    location: PropTypes.object,
    menuBarCollapsed: PropTypes.bool,
}