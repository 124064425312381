import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defaultAPIClient } from '../api-client/config';
import { requestAPI } from '../actions/apiRequestActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Snackbar, makeStyles, TableContainer, Table, TableHead, TableRow, TableCell, TableSortLabel, Toolbar, Typography, LinearProgress, TableBody } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import JSONPretty from 'react-json-pretty';

/* am4charts Imports */
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import HumanizeDuration from 'humanize-duration';
import Millify from 'millify';

/* Chart code */
// Themes begin
am4core.useTheme(am4themes_animated);

class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
          errorSnakBarOpened: false
        }

        this.activeacctsessions = null;
        this.activeacctsessions = null;
    }

    componentDidMount() {
        const {
            requestAPI
        } = this.props;


        this.chart = am4core.create("chartdiv", am4charts.XYChart);
        // Set input format for the dates
        this.chart.dateFormatter.inputDateFormat = "yyyy-MM-ddTHH:mm:ss";
        this.chart.dateFormatter.dateFormat = "yyyy-MM-dd HH:mm:ss";

        // Add data
        this.chart.data = [];

        // Create axes
        var dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.minGridDistance = 60;
        dateAxis.baseInterval = {
          "timeUnit": "minute",
          "count": 30
        } 

        var valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.maxPrecision = 0;

        // Create series
        var series = this.chart.series.push(new am4charts.StepLineSeries());
        series.fillOpacity = 0.3;
        series.dataFields.valueY = "numberOfSessions";
        series.dataFields.dateX = "sessionTimeMark";
        series.tooltipText = "{dateX}\n{numberOfSessions} sessions";

        series.tooltip.pointerOrientation = "vertical";

        this.chart.cursor = new am4charts.XYCursor();
        this.chart.cursor.snapToSeries = series;
        this.chart.cursor.xAxis = dateAxis;

        //chart.scrollbarY = new am4core.Scrollbar();
        this.chart.scrollbarX = new am4core.Scrollbar();



      /**
       * API Calls
       */
        this.activeacctsessionsRequestId = requestAPI({
            apiClient: defaultAPIClient,
            requestParams: {
                url: '/api/activeacctsessions',
                method: 'GET',
            }
        }).requestId;

        this.userSessionsHistoryRequestId = requestAPI({
            apiClient: defaultAPIClient,
            requestParams: {
                url: '/api/dashboard-analytics/user-session-history',
                method: 'GET',
            }
        }).requestId;

    }

    componentWillUnmount() {
      if (this.chart) {
        this.chart.dispose();
      }
    }

    render() {

        const {
            apiRequestId,
            apiRequestResult,
            apiIsRequested,
        } = this.props;

        if (apiRequestId === this.activeacctsessionsRequestId && !apiIsRequested) {
            if (!apiRequestResult.error) {
              this.activeacctsessions = apiRequestResult.data.sessions;
              // console.log('>>>>>>>>>>>>>>>', activeacctsessions);
            }
            else {
                return <Snackbar open={this.state.errorSnakBarOpened} autoHideDuration={2000} anchorOrigin={{vertical: 'top', horizontal: 'center'}} onClose={() => {this.setState({errorSnakBarOpened: false})}}>
                    <Alert severity='error'>
                        API Request error
                    </Alert>
                </Snackbar>
            }
        }

        if (apiRequestId === this.userSessionsHistoryRequestId && !apiIsRequested) {
            if (!apiRequestResult.error) {
              // console.log('>>>>>>>>>>>>>>>', apiRequestResult.data);
              this.chart.data = apiRequestResult.data
            }
            else {
                return <Snackbar open={this.state.errorSnakBarOpened} autoHideDuration={2000} anchorOrigin={{vertical: 'top', horizontal: 'center'}} onClose={() => {this.setState({errorSnakBarOpened: false})}}>
                    <Alert severity='error'>
                        API Request error
                    </Alert>
                </Snackbar>
            }
        }

        return (
            <div>

              <Toolbar>
                <Typography variant="h6" component="div">
                    Session History
                </Typography>
              </Toolbar>
              <div id="chartdiv" style={{ width: "100%", height: "50vh"}}></div>

              <Toolbar>
                <Typography variant="h6" id="onlinUsersTableTitle" component="div">
                  Online Users
                </Typography>
              </Toolbar>
              {this.activeacctsessions
              ?
                <TableContainer>
                  <Table
                    className="fullWidth"
                    aria-labelledby="onlineUsers"
                    size='medium'
                    aria-label="users online"
                  >
                    <TableHead>
                      <TableRow>

                        <TableCell>
                          {/* <TableSortLabel
                            onClick={() => {
                              console.log('Order by `login`');
                            }}
                          >
                            Username
                          </TableSortLabel> */}
                          Username
                        </TableCell>

                        <TableCell>
                          {/* <TableSortLabel
                            onClick={() => {
                              console.log('Order by `sessionTime`');
                            }}
                          >
                            Session Time
                          </TableSortLabel> */}
                          Session Time
                        </TableCell>

                        <TableCell>
                          {/* <TableSortLabel
                            onClick={() => {
                              console.log('Order by `inputBytes`');
                            }}
                          >
                            Download
                          </TableSortLabel> */}
                          Download
                        </TableCell>

                        <TableCell>
                          {/* <TableSortLabel
                            onClick={() => {
                              console.log('Order by `outputBytes`');
                            }}
                          >
                            Upload
                          </TableSortLabel> */}
                          Upload
                        </TableCell>

                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.activeacctsessions.map(activeacctsession => {
                        return (
                          <TableRow key={activeacctsession.id}>
                            <TableCell>
                              {activeacctsession.login}
                            </TableCell>
                            <TableCell>
                              {HumanizeDuration(activeacctsession.sessionTime * 1000)}
                            </TableCell>
                            <TableCell>
                              {
                                Millify(activeacctsession.downloadedBytes, {
                                  units: ['B', 'KB', 'MB', 'GB'],
                                  space: true,
                                })
                              }
                            </TableCell>
                            <TableCell>
                              {
                                Millify(activeacctsession.uploadedBytes, {
                                  units: ['B', 'KB', 'MB', 'GB'],
                                  space: true,
                                })
                              }
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                : <LinearProgress className="flexExpander"/>
              }
            </div>
        )
    }
}


const mapStateToProps = state => ({
    apiRequestId:           state.apiRequestReducer.apiRequestId,
    apiRequestResult:       state.apiRequestReducer.apiRequestResult,
    apiIsRequested:         state.apiRequestReducer.apiIsRequested,
});

export default connect(mapStateToProps, {
    requestAPI
})(Dashboard);


Dashboard.propTypes = {
    requestAPI:  PropTypes.func,
    
    apiRequestId: PropTypes.string,
    apiIsRequested:  PropTypes.bool,
    apiRequestResult:   PropTypes.object,
};