import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { Container, withStyles, Card, Typography, InputAdornment, CardContent, CardActions, Button, Box, LinearProgress } from '@material-ui/core';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import { getAuthenticated, authenticate, verifyAuthentication, reAuthenticate, logout } from '../actions/authActions';
import AccountCircleOutlined from '@material-ui/icons/AccountCircleOutlined';
import VpnKeyOutlined from '@material-ui/icons/VpnKeyOutlined';
import Alert from '@material-ui/lab/Alert';
import { AppContext } from './config/contexts';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { rememberMePreset } from './config/storage';
import storage from 'local-storage-fallback';
import { defaultAPIClient } from '../api-client/config';
import { extinguishException } from '../actions/exceptionActions';
import { fireInfo }  from '../actions/infoActions';

const useStyles = theme => ({
    root: {
    },
    card: {
        maxWidth: '600px',
        minWidth: '350px',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        padding: theme.spacing(1),
    },
    rememberMe: {
        width: '100%',
        textAlign: 'right',
    },
    snapToRight: {
        marginLeft: 'auto',
        marginRight: '0',
    },
    cardActions: {
        padding: '5%',
    },
    exceptionButtonSpacer: {
        margin: '10px'
    }
});



class Login extends Component {

    state = {
        identity: '',
        password: '',
        rememberMe: rememberMePreset(),
        authenticationInProgress: false,
    }

    constructor(props) {
        super(props);

        this.serviceAvailabilityCheckRetryInterval = null;
    }

    _isMounted = false;

    componentDidMount() {
        const {
            search,
            fireInfo,
        } = this.props;
        this._isMounted = true;

        if (search && search.indexOf('email_confirmation') > -1)  {
            const query = new URLSearchParams(search);
            defaultAPIClient.processRequest({
                url: '/api/registration/email_confirmation?organizationid=' + query.get('email_confirmation'),
                method: 'GET',
            });
            fireInfo('Your email has been verified!');
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleChange = (event) => {
        const key = event.target.id;
        const value = event.target.value;
        this.setState({ [key]: value });
    }

    handleChangeChecked = name => event => {
        this.setState({ ...this.state, [name]: event.target.checked });
        if (name === 'rememberMe') storage.setItem('rememberMe', event.target.checked);
    }

    handleSubmit = () => {

        const {
            failedAuthentication,
            reAuthenticate,
        } = this.props;

        if (failedAuthentication) reAuthenticate();

        const credentials = {
            identity: this.state.identity,
            password: this.state.password,
        };

        this.setState({ authenticationInProgress: true });

        this.props.authenticate(credentials);
    }

    componentDidUpdate() {
        const {
            failedAuthentication,
        } = this.props;

        if (failedAuthentication && this.state.authenticationInProgress) {
            this.setState({
                authenticationInProgress: false,
                password: ''
            });
            return;
        }
    }

    render() {
        const {
            classes,
            isAuthenticated,
            failedAuthentication,
            exception,
            exceptionType,
            logout,
            verifyAuthentication,
            reAuthenticate,
            extinguishException,
            authenticationVerification,
        } = this.props;

        /** 
         * 
         * Do not show while authenticationVerification taking place 
         * 
         * */
        if (authenticationVerification) return null;

        return (
            <Fragment>

                {/**
                 * 
                 * 
                 *  Redirect to appContext.accessedPathname if already authenticated  
                 * 
                 * 
                 * */}
                {isAuthenticated && !exception &&
                    <AppContext.Consumer>
                        {appContext => {
                            return <Redirect to={(appContext.accessedPathname && appContext.accessedPathname !== '/login') ?  appContext.accessedPathname : '/'} /> 
                        }}
                    </AppContext.Consumer>
                }

                <Container maxWidth='sm' className={classes.root}>
                    <ValidatorForm
                        ref={(ref) => this.loginForm = ref}
                        onSubmit={this.handleSubmit}
                        // onError={errors => console.log(errors)}
                    >
                        <Card className={classes.card}>
                            <CardContent>
                                <div style={{ textAlign: 'center' }}><img src={"./logo.png"} alt="AuthVela" style={{ width: '100%', maxWidth: '350px' }}/></div>
                                {!exception &&
                                    <Fragment>
                                        <Typography align='center' variant='h5' gutterBottom>Welcome!</Typography>
                                        <Typography align='center' variant='body2'>Sign in to your account to continue</Typography>
                                        <Container>
                                                <TextValidator
                                                    id='identity'
                                                    value={this.state.identity}
                                                    onChange={this.handleChange}
                                                    autoFocus
                                                    fullWidth
                                                    margin='normal'
                                                    label='Identity'
                                                    helperText='@mail or username'
                                                    validators={['required']}
                                                    errorMessages={['this field is required']}
                                                    InputProps={{
                                                        startAdornment: (
                                                        <InputAdornment position='start'>
                                                            <AccountCircleOutlined />
                                                        </InputAdornment>
                                                        ),
                                                        readOnly: this.state.authenticationInProgress,
                                                    }}
                                                />
                                                <TextValidator
                                                    id='password'
                                                    value={this.state.password}
                                                    onChange={this.handleChange}
                                                    type='password'
                                                    fullWidth
                                                    margin='normal'
                                                    label='Password'
                                                    validators={['required']}
                                                    errorMessages={['this field is required']}
                                                    InputProps={{
                                                        startAdornment: (
                                                        <InputAdornment position='start'>
                                                            <VpnKeyOutlined />
                                                        </InputAdornment>
                                                        ),
                                                        readOnly: this.state.authenticationInProgress,
                                                    }}
                                                />
                                                {!this.state.authenticationInProgress &&
                                                    <Box className={classes.rememberMe}>
                                                        <FormControlLabel 
                                                            control={
                                                                <Checkbox
                                                                    checked={this.state.rememberMe}
                                                                    onChange={this.handleChangeChecked('rememberMe')}
                                                                    color='default'
                                                                    size='medium'
                                                                    value='rememberMe'
                                                                    readOnly={this.state.authenticationInProgress}
                                                                />}
                                                            label='REMEMBER ME'
                                                            labelPlacement='start'
                                                        />
                                                    </Box>
                                                }
                                        </Container>
                                    </Fragment>
                                }

                                {((failedAuthentication !== null && !this.state.authenticationInProgress) || (exception && exceptionType === 'api-request-error')) && 
                                    <Alert severity='error'>
                                        {failedAuthentication ? failedAuthentication.displayText : 'Service is temporarily unavailable'}
                                    </Alert>
                                }

                                {!exception ?
                                    <CardActions className={classes.cardActions}>
                                        {this.state.authenticationInProgress 
                                            ? 
                                                <LinearProgress style={{width: '100%'}}/>
                                            : 
                                                <div className="fullWidth horizontalFlex">
                                                    <Link to="/registration">
                                                        <Button size='small' color='primary'>
                                                            <u>Registration</u>
                                                        </Button>
                                                    </Link>
                                                    {/* <Button fullWidth size='small' color='primary'>
                                                        <u>Forgot Password</u>
                                                    </Button> */}
                                                    <div className="flexExpander"></div>
                                                    <Button style={{ minWidth: "160px" }} size='small' color='primary' type='submit' variant='outlined'>
                                                        Sign In
                                                    </Button>
                                                </div>
                                        }
                                    </CardActions>
                                : 
                                    <Fragment>
                                        <hr/>
                                        <Button 
                                            onClick={() => {
                                                verifyAuthentication();
                                            }}
                                            variant='contained' className={classes.exceptionButtonSpacer}>Try to login</Button>
                                        {defaultAPIClient.hasHepsistedParams() &&
                                            <Button 
                                                onClick={() => { 
                                                    logout(); 
                                                    extinguishException();
                                                    reAuthenticate();
                                                }}
                                                size='small'
                                                variant='outlined' className={classes.exceptionButtonSpacer}>
                                                Log out and try again later
                                            </Button>
                                        }
                                    </Fragment>
                                }
                            </CardContent>
                        </Card>
                    </ValidatorForm>
                </Container>

            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isAuthenticated:                state.authReducer.isAuthenticated,
    failedAuthentication:           state.authReducer.failedAuthentication,
    authenticationVerification:     state.authReducer.authenticationVerification,
    exception:                      state.exceptionReducer.exception,
    exceptionType:                  state.exceptionReducer.exceptionType,
    pathname: state.router.location.pathname,
    search: state.router.location.search,
    hash: state.router.location.hash,
});

export default connect(mapStateToProps, { 
    getAuthenticated,
    authenticate,
    logout,
    reAuthenticate,
    verifyAuthentication,
    extinguishException,
    fireInfo,
})(withStyles(useStyles)(Login));


Login.propTypes = {
    reAuthenticate:  PropTypes.func,
    authenticate:  PropTypes.func,
    logout:  PropTypes.func,
    verifyAuthentication:  PropTypes.func,
    extinguishException: PropTypes.func,
    fireInfo:  PropTypes.func,

    failedAuthentication: PropTypes.object,
    classes: PropTypes.object,
    isAuthenticated: PropTypes.bool,
    exception: PropTypes.bool,
    exceptionType: PropTypes.string,
    authenticationVerification: PropTypes.bool,
};