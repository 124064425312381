import '../styles/registration.css';

import React, { useState, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { defaultAPIClient } from '../api-client/config';
import storage from '../components/config/storage';
import { Button } from '@material-ui/core';

import Home from '@material-ui/icons/Home';

const Registration = (props) => {

    const [isAuthenticated] = useState(storage.getItem(defaultAPIClient.initParams.alias) !== null);
    const [registrationRequestError, setRegistrationRequestError] = useState(false);

    let registration = {
        companyName: '',
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        password2: '',
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    };

    const handleChange = event => {
        registration[event.target.name] = event.target.value;
    };

    const setSubmittedState = (submitted = false) => {
        if (submitted) {
            let inputFileds = document.getElementsByClassName("input");
            for (let i = 0; i < inputFileds.length; i++) {
                inputFileds[i].classList.add("inputSubmitted");
                inputFileds[i].setAttribute("readonly", true);
            }
            document.getElementById("actionButtons").classList.add("hidden");
            document.getElementsByClassName("lds-roller")[0].classList.remove("hidden");
            document.getElementById("requestError").classList.add("hidden");

            document.getElementById("emailErrorLabel").innerText = '';
            document.getElementById("emailErrorLabel").classList.add("hidden");
        } else {
            let inputFileds = document.getElementsByClassName("input");
            for (let i = 0; i < inputFileds.length; i++) {
                inputFileds[i].classList.remove("inputSubmitted");
                inputFileds[i].removeAttribute("readonly");
            }
            document.getElementById("actionButtons").classList.remove("hidden");
            document.getElementsByClassName("lds-roller")[0].classList.add("hidden");
        }
    }

    const submit = (event) => {
        event.preventDefault();
        let submittable = true;
        let pattern = /^[a-zA-Z0-9\-_]+(\.[a-zA-Z0-9\-_]+)*@[a-z0-9]+(-[a-z0-9]+)*(\.[a-z0-9]+(-[a-z0-9]+)*)*\.[a-z]{2,4}$/;
        if(!pattern.test(String(document.getElementsByName("email")[0].value))) {
            document.getElementById("emailError").classList.remove("hidden");
            document.getElementsByName("email")[0].classList.add("errorBg");
            document.getElementById("emaiLabel").classList.remove("smallSceenHidden");
            submittable = false;
        } else {
            document.getElementById("emailError").classList.add("hidden");
            document.getElementsByName("email")[0].classList.remove("errorBg");
            document.getElementById("emaiLabel").classList.add("smallSceenHidden");
        }
        if(String(document.getElementsByName("password")[0].value) !== String(document.getElementsByName("password2")[0].value)) {
            document.getElementById("passwordError").classList.remove("hidden");
            document.getElementsByName("password2")[0].classList.add("errorBg");
            document.getElementById("password2Label").classList.remove("smallSceenHidden");
            submittable = false;
        } else {
            document.getElementById("passwordError").classList.add("hidden");
            document.getElementsByName("password2")[0].classList.remove("errorBg");
            document.getElementById("password2Label").classList.add("smallSceenHidden");
        }
        if (String(document.getElementsByName("password")[0].value).length < 7) {
            document.getElementsByName("password")[0].classList.add("errorBg");
            document.getElementById("passwordValidationError").classList.remove("hidden");
            document.getElementById("passwordValidationError").innerText = 'size must be between 6 and 255 characters';
            submittable = false;
        } else {
            document.getElementById("passwordValidationError").classList.add("hidden");
            document.getElementsByName("password")[0].classList.remove("errorBg");
        }
        if (submittable) {
            setSubmittedState(true);
            /**
             * 
             * Submit registration form data
             * 
             */
            console.log('registration: ', registration);
            defaultAPIClient.processRequest({
                url: '/api/registration/organization',
                method: 'POST',
                data: registration,
            })
            .then(result => {
                var authenticationCredentials = new FormData();
                authenticationCredentials.append('username', registration.email);
                authenticationCredentials.append('password', registration.password);
                authenticationCredentials.append('grant_type', 'password');

                const authenticationRequestParams = {
                    url: '/oauth/token',
                    method: 'POST',
                    data: authenticationCredentials,
                    headers: { 
                        'Content-Type': 'multipart/form-data',
                    },
                    auth: {
                        username: defaultAPIClient.initParams.basicHTTPAuthorization.clientId,
                        password: defaultAPIClient.initParams.basicHTTPAuthorization.password,
                    },
                };
                defaultAPIClient.processRequest(authenticationRequestParams)
                .then(result => {
                    if (!result.error) {
                        defaultAPIClient.persistParams(result.data);
                        window.location.href = './';
                    }
                })
                .catch(error => {
                    setRegistrationRequestError(true);
                });
            })
            .catch(error => {
                setSubmittedState(false);
                let handled = false;
                if (error.response) {
                    if (error.response.status === 409) {
                        document.getElementById("emailErrorLabel").innerText = 'Email address registered in the system.';
                        document.getElementById("emailErrorLabel").classList.remove("hidden");
                        document.getElementsByName("email")[0].classList.add("errorBg");
                        handled = true;
                    }
                }
                document.getElementById("requestError").innerHTML = 'Failed to submit form.';
                document.getElementById("requestError").classList.remove("hidden");
                if (!handled) {
                    document.getElementById("requestError").innerHTML += '<br/>' + error + '.';
                }
            });
        }
    }

    return (
        <Fragment>
            {isAuthenticated &&
                <Redirect to='/' /> 
            }
            {registrationRequestError &&
                <Redirect to='/login' /> 
            }

            <div className="registrationFormContainerWrapper">
                <div className="registrationFormContainer">
                    <div className="center">
                        <img src="./logo.png" alt="AuthVela" className="brandLogo"/>
                    </div>

                    <div className="row">
                        <div className="column center heading" style={{color: "black", display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <Home style={{fontSize: "34px", color: "#62d47b"}}/>
                            <span style={{marginLeft: "10px", fontWeight: "500", fontSize: "34px"}}>Home Edition</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="column hairLine"/>
                        <div className="column center heading">Register</div>
                        <div className="column hairLine"/>
                    </div>
                    <div className="row caption">
                        It's free and only takes a minute.
                    </div>
                    <br/>
                    <div className="lds-roller hidden"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    <form method="post" onSubmit={submit}>
                        <label className="captionMedium smallSceenHidden">Company Name</label>
                        <input type="text" required name="companyName" placeholder="Company Name" className="input" onChange={handleChange}/>
                        <br/>
                        <label className="captionMedium smallSceenHidden">First Name</label>
                        <input type="text" required name="firstName" placeholder="First Name" className="input" onChange={handleChange}/>
                        <br/>
                        <label className="captionMedium smallSceenHidden">Last Name</label>
                        <input type="text" required name="lastName" placeholder="Last Name" className="input" onChange={handleChange}/>
                        <br/>
                        <label className="captionMedium smallSceenHidden" id="emaiLabel">Email <span id="emailError" className="captionMedium error hidden">is malformed</span></label>
                        <div id="emailErrorLabel" className="caption error hidden"></div>
                        <input type="email" required name="email" placeholder="Email" className="input" onChange={handleChange}/>
                        <br/>
                        <label className="captionMedium smallSceenHidden">Password</label>
                        <div id="passwordValidationError" className="caption error hidden"></div>
                        <input type="password" required name="password" placeholder="Password" className="input" onChange={handleChange}/>
                        <br/>
                        <label className="captionMedium smallSceenHidden" id="password2Label">Password Confirmation <span id="passwordError" className="captionMedium error hidden">does not match Password</span></label>
                        <input type="password" required name="password2" placeholder="Confirm Password" className="input" onChange={handleChange}/>
                        <br/><br/>
                        <div id="requestError" className="error hidden"></div>
                        {/* <button type="submit" className="input submitBtn">Register Now</button> */}

                        <div id="actionButtons" className="fullWidth horizontalFlex">
                            <Link to="/login">
                                <Button size='small' color='primary'>
                                    <u>Log In</u>
                                </Button>
                            </Link>
                            <div className="flexExpander"></div>
                            <Button style={{ flexGrow: 0.75, backgroundColor: "#e2fce4" }} size='medium' color='primary' type='submit' variant='outlined'>
                                Register Now
                            </Button>
                        </div>

                    </form>
                </div>
            </div>

        </Fragment>
    )
}

export default Registration;