import { SIDE_MENU_TOGGLE_BAR, SIDE_MENU_SELECT_ITEM, SIDE_MENU_COLLAPSE_BAR } from '../actions/types';

const initialState = {
    responsiveSideMenuBarVisibility: false,
    selectedMenuItem: null,
    menuBarCollapsed: false,
};

const sideMenuBarReducer = (state = initialState, action) => {
    switch (action.type) {
        case SIDE_MENU_TOGGLE_BAR:
            return {
                ...state,
                responsiveSideMenuBarVisibility: !state.responsiveSideMenuBarVisibility,
            }
        case SIDE_MENU_COLLAPSE_BAR:
            return {
                ...state,
                menuBarCollapsed: action.payload,
            }
        case SIDE_MENU_SELECT_ITEM:
            return {
                ...state,
                selectedMenuItem: action.payload,
            }
        default:
            return state;
    }
}

export default sideMenuBarReducer;