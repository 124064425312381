import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import storage from '../../../components/config/storage';
import { Link as RouterLink } from 'react-router-dom';
import { setListFilterApplied, setListFilterChanged } from '../../../actions/CRUDLEscortComponentActions';
import { fireWarning } from '../../../actions/warningActions';
import PropTypes from 'prop-types';
import { Box, withStyles, withTheme, Typography, Hidden, Paper, Divider, Button, Menu, MenuItem, Grid, Checkbox, ListItemIcon, ListItemText, Tooltip, InputAdornment, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import CRUDLStyles from '../../../styles/crudl-styles'
import BackspaceOutlinedIcon from '@material-ui/icons/BackspaceOutlined';

const useStyles = theme => ({
    root: {
    },
    grow: {
        flexGrow: 1,
    },
    toolsetComponent: {
        width: '100%',
        display: 'flex',
        flexDrection: 'row',
    },
    filterContainer: {
        width: '100%',
        display: 'flex',
        flexDrection: 'row',
    },
    divider: {
        margin: 2,
    },
    spaced: {
        margin: 'auto',
    },
    actionButtons: {
        borderRadius: 0,
    },
    filterComponent: {
        marginLeft: theme.spacing(1),
    },
    resizedInput:{
        fontSize: 14,
    },
    resizedInputLabel:{
        fontSize: 14,
    },
});

class CRUDLToolsetComponent extends Component {

    state = {
        filterInputs: {},
        filterChanged: false,
        filterApplied: false,
        menuAnchorEl : null,
        filtersInUse: [],
    }

    handleFilterInput = (property, value) => {
        const {
            Model,
            CRUDLEntityComponentImpl,
            modified,
            /**
             * Actions
             */
            fireWarning,
        } = this.props;

        if (!modified) {
            this.setState({
                filterInputs: Object.assign(this.state.filterInputs, { [property]: value }),
            });
    
            let filterChanged = false;
            const sessionStoredFilter = CRUDLEntityComponentImpl.getListFilter();
            Model.getPropertiesFilters().forEach(propertyFilter => {
                const property = Object.keys(propertyFilter)[0];
    
                if (this.state.filtersInUse.indexOf(property) > -1) {
                    filterChanged |= sessionStoredFilter[property] !== this.state.filterInputs[property] && this.state.filterInputs[property] !== '';
                    filterChanged |= Boolean(sessionStoredFilter[property]) && this.state.filterInputs[property] === '';
                    filterChanged |= Boolean(sessionStoredFilter[property]) && this.state.filterInputs[property] !== sessionStoredFilter[property];
                }
            });
            this.setState({ filterChanged: Boolean(filterChanged) });
        } else {
            fireWarning('crudl-modified-warning', 'You haven\'t saved your changes!');
        }
    }

    applyFilter = () => {
        const {
            Model,
            CRUDLEntityComponentImpl,
            setListFilterApplied,
        } = this.props;

        let filtersAreNotEmpty = false;
        Model.getPropertiesFilters().forEach(propertyFilter => {
            const property = Object.keys(propertyFilter)[0];

            if (this.state.filtersInUse.indexOf(property) > -1) {
                filtersAreNotEmpty |= this.state.filterInputs[property] !== '';
                CRUDLEntityComponentImpl.setListFilter({ ...this.state.filterInputs, [property]:  this.state.filterInputs[property]});
            }
        });
        this.setState({ 
            filterApplied: Boolean(filtersAreNotEmpty),
            filterChanged: false 
        });
        setListFilterApplied(Boolean(filtersAreNotEmpty));
    }

    clearFilterInputs = () => {
        const {
            Model,
            CRUDLEntityComponentImpl,
            setListFilterApplied,
        } = this.props;

        Model.getPropertiesFilters().forEach(propertyFilter => {
            const property = Object.keys(propertyFilter)[0];

            this.setState({
                filterInputs: Object.assign(this.state.filterInputs, { [property]: '' })
            });
        });

        CRUDLEntityComponentImpl.reSetListFilter({ page: 0 });

        this.setState({ 
            filterApplied: false,
            filterChanged: false,
        });
        setListFilterApplied(false);
    }

    componentDidMount() {
        const {
            Model,
            CRUDLEntityComponentImpl,
            setListFilterApplied,
        } = this.props;

        const filtersInUseFromStorage = JSON.parse(storage.getItem(Model.constructor.name + 'CRUDLFiltersInUse'));

        const sessionStoredFilter = CRUDLEntityComponentImpl.getListFilter();
        let filterApplied = false;
        let filtersInUse = [];
        Model.getPropertiesFilters().forEach((propertyFilter) => {
            const property = Object.keys(propertyFilter)[0];
            let filterInUse = true;
            if (filtersInUseFromStorage) 
                filterInUse &= filtersInUseFromStorage.indexOf(property) > -1;
            else
                filterInUse &= Boolean(propertyFilter[property].default)


            if (filterInUse) {
                filtersInUse.push(property);
            }

            /**
             * Prevent unctrolled input
             */
            this.setState({
                filterInputs: Object.assign(this.state.filterInputs, { [property]: sessionStoredFilter[property] || '' }),
            });

            filterApplied |= sessionStoredFilter[property] !== undefined && sessionStoredFilter[property] !== '';
        });

        this.setState({ 
            filterApplied: Boolean(filterApplied),
            filtersInUse,
        });

        setListFilterApplied(Boolean(filterApplied));
    }

    initializeFilters() {
        
    }

    handleMenuOpen = (event) => {
        this.setState({ menuAnchorEl: event.currentTarget });
    }

    handleMenuClose = () => {
        this.setState({ menuAnchorEl: null });
    }

    applyFilterClickHandler = () => {
        const {
            requestList,
        } = this.props;

        requestList({
            page: 0,
        }, this.applyFilter);
        this.handleMenuClose();
    }

    clearFilterClickHandler = () => {
        const {
            requestList,
        } = this.props;

        requestList(null, this.clearFilterInputs);
        this.handleMenuClose();
    }

    toggleFilterInUse = (property) => {
        const {
            Model,
            CRUDLEntityComponentImpl,
            modified,
            fireWarning,
        } = this.props;

        if (!modified) {
            let filtersInUse = [...this.state.filtersInUse];

            if (filtersInUse.indexOf(property) === -1) {
                filtersInUse.push(property);
            } else {
                filtersInUse.splice(filtersInUse.indexOf(property), 1);
                if (this.state.filterInputs[property] !== '')
                    this.setState({ filterInputs: {...this.state.filterInputs, [property]: ''} });
            }

            storage.setItem(Model.constructor.name + 'CRUDLFiltersInUse', JSON.stringify(filtersInUse));

            this.setState({ filtersInUse });

            const sessionStoredFilter = CRUDLEntityComponentImpl.getListFilter();
            let filterChanged = Boolean(this.state.filterInputs[property] !== '') && filtersInUse.indexOf(property) > -1;
            if (sessionStoredFilter[property] !== undefined)
                filterChanged = (sessionStoredFilter[property] !== this.state.filterInputs[property]) || filtersInUse.indexOf(property) === -1;

            this.setState({ filterChanged: this.state.filterChanged || Boolean(filterChanged) });
        } else {
            fireWarning('crudl-modified-warning', 'You haven\'t saved your changes!');
        }
    }

    componentDidUpdate() {
        const {
            setListFilterChanged,
        } = this.props;

        setListFilterChanged(this.state.filterChanged);
    }

    render() {
        const {
            theme,
            classes,

            Model,
        } = this.props;

        return (
            <Fragment>

                <Menu
                    id='filter-menu'
                    anchorEl={this.state.menuAnchorEl}
                    keepMounted
                    open={Boolean(this.state.menuAnchorEl)}
                    onClose={this.handleMenuClose}
                >

                    {Model.getPropertiesFilters().map((propertyFilter, key) => {
                            const property = Object.keys(propertyFilter)[0];
                            const propertyFilterComponent = propertyFilter[property].component;
                            const decorartorLabel = Model.getDefinitions().decorators.labels[property];
                            const style = { backgroundColor: this.state.filterChanged ? '#fff0b9' : this.state.filterApplied ? CRUDLStyles.filterAppliedColor :  theme.palette.background.paper };

                            return (
                                <Box key={'MenuItem' + key} style={style}>
                                    <MenuItem>
                                        <Grid container direction='row'>
                                            <Grid item>
                                                <Checkbox
                                                    checked={Boolean(this.state.filtersInUse.indexOf(property) > -1)}
                                                    onClick={() => {
                                                        this.toggleFilterInUse(property);
                                                    }}
                                                    size='small'
                                                />
                                            </Grid>
                                            <Grid item className={classes.spaced}>
                                                {propertyFilterComponent({
                                                    value: this.state.filterInputs[property],
                                                    onChange: (event) => {
                                                        this.handleFilterInput(property, event.target.value)
                                                    },
                                                    onKeyDown: (event) => { event.stopPropagation(); },
                                                    label: decorartorLabel || property,
                                                    className: classes.filterComponent,
                                                    variant: 'outlined',
                                                    size: 'small',
                                                    key: key,
                                                    fullWidth: true,
                                                    InputProps: {
                                                        readOnly: this.state.filtersInUse.indexOf(property) === -1,
                                                        classes: {
                                                        input: classes.resizedInput,
                                                        },
                                                    },
                                                    InputLabelProps: {
                                                        classes: {
                                                            root: classes.resizedInputLabel,
                                                        },
                                                    }
                                                })}
                                            </Grid>
                                        </Grid>
                                    </MenuItem>
                                    <Divider />
                                </Box>
                            )
                        })
                    }

                    {this.state.filterChanged && <Divider /> }
                    {this.state.filterChanged &&
                        <MenuItem onClick={this.applyFilterClickHandler}>
                            <Tooltip title='Filter not applied'>
                                <ListItemIcon>
                                    <SearchIcon />
                                </ListItemIcon>
                            </Tooltip>
                            <ListItemText>Apply Filter</ListItemText>
                        </MenuItem>
                    }
                    {(this.state.filterChanged || this.state.filterApplied) && <Divider /> }
                    {(this.state.filterChanged || this.state.filterApplied) &&
                        <MenuItem onClick={this.clearFilterClickHandler}>
                            <ListItemIcon>
                                <ClearIcon />
                            </ListItemIcon>
                            <ListItemText>Clear Filter</ListItemText>
                        </MenuItem>
                    }
                </Menu>

                <Box className={classes.toolsetComponent} style={(this.props.tableToolsetSettings && this.props.tableToolsetSettings.toolsetComponentStyle) ? this.props.tableToolsetSettings.toolsetComponentStyle : null}>

                    <Hidden smDown>
                        <Divider className={classes.divider} orientation='vertical' />
                    </Hidden>

                    {/** 
                     * 
                     * 
                     * New Button 
                     * 
                     * 
                     * */}
                     {((!this.props.tableToolsetSettings) || (this.props.tableToolsetSettings && this.props.tableToolsetSettings.createButton !== false)) &&
                        <Button 
                            component={RouterLink}
                            to={Model.getDefinitions().UIRoutes.new}
                            variant='outlined'
                            size='small'
                        >
                            <AddIcon style={{ color: theme.palette.primary.dark }} />
                            <Hidden smDown>
                                <Typography variant='caption'>Create</Typography>
                            </Hidden>
                        </Button>
                    }

                    <Divider className={classes.divider} orientation='vertical' />

                    <Paper variant='outlined' 
                        className={classes.filterContainer} 
                        style={{ backgroundColor: this.state.filterChanged ? '#fff0b9' : this.state.filterApplied ? CRUDLStyles.filterAppliedColor :  theme.palette.background.paper }}>
                        <Button
                            onClick={this.handleMenuOpen}
                            size='small'
                            className={classes.actionButtons}
                        >
                            <FilterListIcon/>
                        </Button>


                        {/** 
                         * 
                         * Entity Model Filters 
                         * 
                         * */}
                        <Hidden xsDown>
                            {Model.getPropertiesFilters().map((propertyFilter, key) => {
                                    const property = Object.keys(propertyFilter)[0];

                                    if (this.state.filtersInUse.indexOf(property) === -1) return null;

                                    const propertyFilterComponent = propertyFilter[property].component;
                                    const decorartorLabel = Model.getDefinitions().decorators.labels[property];

                                    const filterAdornment = this.state.filterInputs[property] &&
                                                            <InputAdornment position='start'>
                                                                <IconButton 
                                                                    tabIndex={-1}
                                                                    onClick={() => {
                                                                        this.handleFilterInput(property, '');
                                                                    }}
                                                                    color='primary' aria-label='prev' size='small'>
                                                                    <BackspaceOutlinedIcon fontSize='small'/>
                                                                </IconButton>
                                                            </InputAdornment>;

                                    return propertyFilterComponent({
                                        value: this.state.filterInputs[property],
                                        onChange: (event) => {
                                            this.handleFilterInput(property, event.target.value);
                                        },
                                        onKeyDown: (event) => {
                                            if (event.key === 'Enter') {
                                                this.applyFilterClickHandler();
                                            }
                                        },
                                        label: decorartorLabel || property,
                                        className: classes.filterComponent,
                                        size: 'small',
                                        key: key,
                                        fullWidth: true,
                                        InputProps: {
                                            classes: {
                                              input: classes.resizedInput,
                                            },
                                            endAdornment: filterAdornment,
                                        },
                                        InputLabelProps: {
                                            classes: {
                                                root: classes.resizedInputLabel,
                                            },
                                        }
                                    });
                                })
                            }
                        </Hidden>


                        <Hidden xsDown>
                            <div className={classes.grow} />

                            {this.state.filterChanged &&
                                <Button
                                    onClick={this.applyFilterClickHandler}
                                    size='small'
                                    className={classes.actionButtons}
                                >
                                    <Tooltip title='Apply filter'>
                                        <SearchIcon />
                                    </Tooltip>
                                </Button>
                            }
                        </Hidden>

                        {(this.state.filterChanged || this.state.filterApplied) &&
                            <Button
                                onClick={this.clearFilterClickHandler}
                                size='small'
                                className={classes.actionButtons}
                            >
                                <ClearIcon />
                            </Button>
                        }

                    </Paper>

                    <Divider className={classes.divider} orientation='vertical' />
                </Box>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    modified:               state.CRUDLEscortComponentReducer.modified,
});

export default connect(mapStateToProps, {
    setListFilterApplied,
    setListFilterChanged,
    fireWarning,
})(withTheme(withStyles(useStyles)(CRUDLToolsetComponent)));


CRUDLToolsetComponent.propTypes = {
    requestList: PropTypes.func,
    setListFilterApplied: PropTypes.func,
    setListFilterChanged: PropTypes.func,
    fireWarning: PropTypes.func,

    theme: PropTypes.object,
    classes: PropTypes.object,
    CRUDLEntityComponentImpl: PropTypes.object,
    Model: PropTypes.object,
    modified: PropTypes.bool,
}