import { 
    AUTH_VERIFY_AUTHENTICATION, 
    AUTH_AUTHENTICATION_TOKEN_EXPIRED, 
    AUTH_GET_AUTHENTICATED, 
    AUTH_SET_AUTHENTICATED, 
    AUTH_SET_UNAUTHENTICATED,
    AUTH_AUTHENTICATION_FAILED, 
    AUTH_REAUTHENTICATION,
    AUTH_LOG_OUT } from '../actions/types';

const initialState = {
    authenticationVerification: false,
    isAuthenticated: false,
    failedAuthentication: null,
    authenticationTokenExpired: false,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTH_VERIFY_AUTHENTICATION:
            return {
                ...state,
                authenticationTokenExpired: false,
                authenticationVerification: true,
                failedAuthentication: null,
            }
        case AUTH_AUTHENTICATION_TOKEN_EXPIRED:
            return {
                ...state,
                authenticationTokenExpired: true,
                authenticationVerification: true,
                failedAuthentication: null,
            }
        case AUTH_GET_AUTHENTICATED:
            return {
                ...state,
            }
        case AUTH_SET_AUTHENTICATED:
            return {
                ...state,
                isAuthenticated: true,
                authenticationVerification: false,
                authenticationTokenExpired: false,
                failedAuthentication: null,
            }
        case AUTH_SET_UNAUTHENTICATED:
            return {
                ...state,
                isAuthenticated: false,
            }
        case AUTH_AUTHENTICATION_FAILED:
            return {
                ...state,
                isAuthenticated: false,
                failedAuthentication: action.payload,
                authenticationVerification: false,
            }
        case AUTH_REAUTHENTICATION:
            return {
                ...state,
                isAuthenticated: false,
                failedAuthentication: null,
            }
        case AUTH_LOG_OUT:
            return {
                ...state,
                isAuthenticated: false,
                authenticationVerification: false,
                failedAuthentication: null,
            }
        default:
            return state;
    }
}

export default authReducer;