import APIClient from './apiClient';

export const defaultAPIClient = new APIClient({
    alias: 'authvela-api-client',
    // baseURL: 'http://109.87.34.156:8090/',

    // Live API
   baseURL: 'https://authvela.com/',
    // baseURL: 'http://192.168.0.80:8090/',

    // Local API
    // baseURL: 'http://localhost:8080/',

    basicHTTPAuthorization: {
        clientId: 'authVelaClientId',
        password: 'secret'
    }
});

export const API_ERRORS_IGNORINGS = [
    'Bad Request',
    'invalid_token',
];