import React, { Fragment } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import HelpIcon from '@material-ui/icons/Help';
import { IconButton, Tooltip, Toolbar, withStyles, Button, CircularProgress, Typography } from '@material-ui/core';
import CRUDLEscortToolsetComponent, { connect } from '../../base/crudl/CRUDLEscortToolsetComponent';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';

import clsx from 'clsx';

const useStyles = theme => ({
    root: {
    },
    grow: {
        flexGrow: 1,
    },
    buttonMargin: {
        margin: theme.spacing(1),
    },
    modifiedSaveButtonState: {
        boxShadow: '0px 0px 4px 4px ' + theme.palette.warning.light,
    }
});

class FullEditFormToolsetComponent extends CRUDLEscortToolsetComponent {
    
    render() {
        const {
            classes,
            editMode,
            modified,
            saveState,
            /**
             * Actions
             */
            setEditMode,
            cancel,
            setSaveState, 
        } = this.props;

        return (
            <Toolbar position='fixed'>
                {saveState === 'saving' &&
                    <Fragment>
                        <CircularProgress size={24} />&nbsp;&nbsp;saving...
                    </Fragment>
                }

                {saveState !== 'saving' &&
                    <Fragment>
                        {editMode && 
                            <Fragment>
                                {(saveState === null || saveState === 'saved' || saveState === 'not-saved') &&
                                    <Fragment>
                                        <Button
                                            onClick={() => { 
                                                cancel();
                                            }}
                                            variant='contained'
                                            color='default'
                                            size='small'
                                            startIcon={<CancelIcon />}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            onClick={() => { 
                                                setSaveState('initiated');
                                            }}
                                            variant='contained'
                                            color='primary'
                                            size='small'
                                            className={clsx(classes.buttonMargin, (modified) ? classes.modifiedSaveButtonState : null)}
                                            startIcon={<CheckCircleIcon />}
                                        >
                                            Save
                                        </Button>
                                    </Fragment>
                                }
                                &nbsp;
                                {saveState === 'not-saved' &&
                                    <Fragment>
                                        <WarningIcon color='error' />
                                        <Typography variant='caption' color='error'>
                                            (not saved)
                                        </Typography>
                                    </Fragment>
                                }
                            </Fragment>
                        }

                        {!editMode &&
                            <Fragment>
                                {/* <Tooltip title='Edit'>
                                    <IconButton onClick={() => { 
                                        setEditMode(!editMode); 
                                    }}>
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip> */}
                                <Button
                                    onClick={() => {
                                        setEditMode(!editMode);
                                    }}
                                    variant='contained'
                                    color='primary'
                                    size='small'
                                    className={clsx(classes.buttonMargin, (modified) ? classes.modifiedSaveButtonState : null)}
                                    startIcon={<EditIcon />}
                                >
                                    Edit
                                </Button>
                            </Fragment>
                        }
                    </Fragment>
                }

                    <div className={classes.grow} />

                    <Typography variant='subtitle2'>
                        {this.Model && this.Model.getDefinitions().decorators.editHeading}
                    </Typography>

                    <div className={classes.grow} />

                    <IconButton onClick={() => { console.log('Help'); }}>
                        <Tooltip title='Help'>
                            <HelpIcon />
                        </Tooltip>
                    </IconButton>
            </Toolbar>
        )
    }
}

export default connect(withStyles(useStyles)(FullEditFormToolsetComponent));