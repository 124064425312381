import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CRUDLEscortFlatEntityFormComponent from './CRUDLEscortFlatEntityFormComponent';
import { connect } from 'react-redux';
import { setSaveState, setEditMode } from '../../../actions/CRUDLEscortComponentActions';

class OperationalUpdateForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            validationErrors: null
        }
    }

    componentDidUpdate() {
        const {
            cancelled,
        } = this.props;

        if (cancelled && this.state.validationErrors !== null) this.setState({ validationErrors: null });
    }

    processSaveResponse = (response) => {
        const {
            setSaveState,
            setEditMode,
        } = this.props;

        if (response.data) {
            if(response.data.error) {
                if(response.data.errors) {
                    let validationErrors = {};
                    response.data.errors.forEach(error => {
                        const {
                            field,
                            defaultMessage,
                        } = error;
    
                        validationErrors[field] = defaultMessage;
                    });
    
                    this.setState({ validationErrors });
                }
                setSaveState('not-saved');
                setEditMode(true);
            } else {
                this.setState({ validationErrors: null });
                setSaveState('saved', response.data);
            }
        }
    }

    render() {
        const {
            Model,
            propertiesDefinition,
            saveAPIEndpoint,
            selectedEntity,
        } = this.props;

        return (
            <CRUDLEscortFlatEntityFormComponent
                Model={Model} 
                selectedEntity={selectedEntity}
                propertiesDefinition={propertiesDefinition}
                labelsOrientation='row'
                saveAPIEndpoint={saveAPIEndpoint}
                processSaveResponse={this.processSaveResponse}
                validationErrors={this.state.validationErrors}
            />
        )
    }
}

const mapStateToProps = state => ({
    cancelled:              state.CRUDLEscortComponentReducer.cancelled,
});

export default connect(mapStateToProps, {
    setSaveState,
    setEditMode,
})(OperationalUpdateForm);


OperationalUpdateForm.propTypes = {
    setSaveState: PropTypes.func,
    setEditMode: PropTypes.func,

    Model: PropTypes.object,
    selectedEntity: PropTypes.object,
    cancelled: PropTypes.bool,
    propertiesDefinition: PropTypes.string,
    saveAPIEndpoint: PropTypes.string,
}