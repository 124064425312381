import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defaultAPIClient } from '../api-client/config';
import { withTheme, withStyles } from '@material-ui/core/styles';
import TopAppBar from './TopAppBar';
import SideMenuBar from './SideMenuBar';
import MainDisplay from './MainDisplay';
import { CssBaseline, Box, LinearProgress, Snackbar } from '@material-ui/core';
import ProtectedComponent from './base/ProtectedComponent';
/**
 * Actions
 */
import { authenticate, unauthenticate, logout, verifyAuthentication, refreshAuthenticationToken } from '../actions/authActions';
import { extinguishException } from '../actions/exceptionActions';
import { extinguishWarning } from '../actions/warningActions';
import { requestAPI } from '../actions/apiRequestActions';
import { extinguishInfo }  from '../actions/infoActions';

import Backdrop from '@material-ui/core/Backdrop';
import { withRouter, Redirect } from 'react-router-dom';
import { AppContext } from './config/contexts';
import { Alert } from '@material-ui/lab';


const useStyles = theme => ({
    root: {
        position: 'fixed',
        right: 0,
        left: 0,
        display: 'flex',
        height: '100vh',
        userSelect: 'none',
    },
    grow: {
        flexGrow: 1,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1E10,
        color: theme.palette.primary.contrastText,
        backgroundColor: '#000000aa',
    },
});

class AppRoot extends Component {
    state = {
        apiErrorSnackbar: {
            opened: false,
            errorMessage: null,
        },
        warningSnackbar: {
            opened: false,
            warningMessage: null,
        },
        infoSnackbar: {
            opened: false,
            infoMessage: null,
        }
    }

    constructor(props) {
        super(props);

        this.accessedPathname = this.props.location.pathname;

        const {
            verifyAuthentication,
        } = this.props;

        verifyAuthentication();
    }

    componentDidUpdate() {
        const {
            authenticationTokenExpired,
            refreshAuthenticationToken,
            exception, 
            exceptionType,
            exceptionMessage, 
            extinguishException,
            warning,
            warningType,
            warningMessage,
            extinguishWarning,
            info,
            infoMessage,
            extinguishInfo,
        } = this.props;

        if (!exception) {

            if (authenticationTokenExpired === true) {
                refreshAuthenticationToken();
                return;
            }

            this.retryPostponedRequests();
        }

        if(exception && exceptionType.indexOf('api-response-') > -1) {
            this.setState({ apiErrorSnackbar: {
                opened: true,
                errorMessage: exceptionMessage
            } });
            extinguishException();
        }

        if (warning && warningType.indexOf('crudl-') > - 1) {
            extinguishWarning();
            if (!this.state.warningSnackbar.opened) {
                this.setState({
                    warningSnackbar: {
                        opened: true,
                        warningMessage,
                    }
                });
                console.log(this.state.warningSnackbar);
            }
        }

        if (info) {
            extinguishInfo();
            if (!this.state.infoSnackbar.opened) {
                this.setState({
                    infoSnackbar: {
                        opened: true,
                        infoMessage,
                    }
                });
            }
        }
    }

    retryPostponedRequests() {
        const {
            authenticationVerification,

            /**
             * Actions
             */
            verifyAuthentication,

            requestAPI,
            apiIsRequested,
        } = this.props;

        /**
         * 
         * Repeat requestAPI calls if there is a postponed queue
         * 
         */
        if ((!apiIsRequested 
            && !authenticationVerification)) 
        {
            if (defaultAPIClient.getPostponedRequestAPICallParamsQueue().length > 0) {
                defaultAPIClient.getPostponedRequestAPICallParamsQueue(true).forEach(postponedRequestAPICallParams => {
                    if (postponedRequestAPICallParams.requestAlias === 'authentication-verification') {
                        verifyAuthentication();
                    } else {
                        requestAPI(postponedRequestAPICallParams);
                    }
                });
            }

        }
    }

    render() {
        const { 
            classes, 
            authenticationVerification, 
            exception, 
            exceptionType, 
            /**
             * Actions
             */
            unauthenticate,
        } = this.props;

        if (exception && exceptionType === 'api-request-error') {
            unauthenticate();
        }

        return (
            <Fragment>

                {(exception && exceptionType === 'api-request-error') &&
                    <Redirect to={'/login'} />
                }

                {/** 
                 * 
                 * 
                 * API Internal Server Error Alert 
                 * 
                 * 
                 * */}
                <Snackbar 
                    open={this.state.apiErrorSnackbar.opened} 
                    autoHideDuration={10000}
                    onClose={() => { this.setState({ apiErrorSnackbar: {
                        opened: false,
                        errorMessage: null,
                    } }); }}
                >
                    <Alert severity='error' variant='filled'>{this.state.apiErrorSnackbar.errorMessage}</Alert>
                </Snackbar>

                {/** 
                 * 
                 * 
                 * Warnings
                 * 
                 * 
                 * */}
                <Snackbar 
                    open={this.state.warningSnackbar.opened} 
                    autoHideDuration={2000}
                    onClose={() => {
                        this.setState({ warningSnackbar: {
                            opened: false,
                            warningMessage: null,
                            } 
                        }); 
                    }}
                    anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                >
                    <Alert severity='warning' variant='filled'>{this.state.warningSnackbar.warningMessage}</Alert>
                </Snackbar>

                {/** 
                 * 
                 * 
                 * Infos
                 * 
                 * 
                 * */}
                <Snackbar 
                    open={this.state.infoSnackbar.opened} 
                    autoHideDuration={8000}
                    onClose={() => {
                        this.setState({ infoSnackbar: {
                            opened: false,
                            infoMessage: null,
                            } 
                        }); 
                    }}
                    anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                >
                    <Alert severity='info' variant='filled'>{this.state.infoSnackbar.infoMessage}</Alert>
                </Snackbar>

                {/** 
                 * 
                 * Authentication verification 
                 * 
                 * */}
                {authenticationVerification &&
                    <Backdrop className={classes.backdrop} open={true}>
                        <Box style={{ textAlign: 'center' }}>
                            <img src={"./logo.png"} alt="AuthVela"/>
                            <LinearProgress variant='indeterminate' size={40} style={{ borderRadius: '4px' }}/>
                            <br/>
                            <Alert severity='info'className={classes.grow}>
                                Reauthentication attempt...
                            </Alert>
                        </Box>
                    </Backdrop>
                }


                <Box className={classes.root}>
                    <CssBaseline/>

                    <AppContext.Provider 
                        value={{
                            accessedPathname: this.accessedPathname,
                        }}
                    >
                        <ProtectedComponent component={ <TopAppBar /> } action='exclude' />
                        <ProtectedComponent component={ <SideMenuBar /> } action='exclude' />
                        <MainDisplay />
                    </AppContext.Provider>

                </Box>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isAuthenticated:                state.authReducer.isAuthenticated,
    authenticationTokenExpired:     state.authReducer.authenticationTokenExpired,
    authenticationVerification:     state.authReducer.authenticationVerification,
    exception:                      state.exceptionReducer.exception,
    exceptionType:                  state.exceptionReducer.exceptionType,
    exceptionMessage:               state.exceptionReducer.exceptionMessage,
    apiIsRequested:                 state.apiRequestReducer.apiIsRequested,
    warning:                        state.warningReducer.warning,
    warningType:                    state.warningReducer.warningType,
    warningMessage:                 state.warningReducer.warningMessage,
    info:                           state.infoReducer.info,
    infoMessage:                    state.infoReducer.infoMessage,
});

export default connect(mapStateToProps, { 
    authenticate,
    unauthenticate,
    logout,
    verifyAuthentication,
    refreshAuthenticationToken,
    requestAPI,
    extinguishException,
    extinguishWarning,
    extinguishInfo,
})(withTheme(withStyles(useStyles)(withRouter(AppRoot))));


AppRoot.propTypes = {
    requestAPI:  PropTypes.func,
    verifyAuthentication: PropTypes.func,
    refreshAuthenticationToken: PropTypes.func,
    extinguishException: PropTypes.func,
    unauthenticate: PropTypes.func,
    extinguishWarning: PropTypes.func,
    extinguishInfo: PropTypes.func,

    location: PropTypes.object,
    authenticationTokenExpired: PropTypes.bool,
    exception: PropTypes.bool,
    exceptionType: PropTypes.string,
    exceptionMessage: PropTypes.string,
    authenticationVerification: PropTypes.bool,
    apiIsRequested: PropTypes.bool,
    classes: PropTypes.object,
    warning:  PropTypes.bool,
    warningType: PropTypes.string,
    warningMessage: PropTypes.string,
    info: PropTypes.bool,
    infoMessage: PropTypes.string,
};