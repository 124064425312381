import { EXCEPTION_FIRE, EXCEPTION_EXTINGUISH } from '../actions/types';

const initialState = {
    exception: false,
    exceptionType: null,
    exceptionMessage: null,
};

const exceptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case EXCEPTION_FIRE:
            return {
                ...state,
                exception: true,
                exceptionType: action.payload.exceptionType,
                exceptionMessage: action.payload.exceptionMessage ? action.payload.exceptionMessage : null,
            }
        case EXCEPTION_EXTINGUISH:
            return {
                ...state,
                exception: false,
                exceptionType: null,
                exceptionMessage: null,
            }
        default:
            return state;
    }
}

export default exceptionReducer;