import { SIDE_MENU_TOGGLE_BAR, SIDE_MENU_SELECT_ITEM, SIDE_MENU_COLLAPSE_BAR } from '../actions/types';

export const toggleSideMenuBar = () => dispatch => {
    dispatch({
        type: SIDE_MENU_TOGGLE_BAR,
        payload: {}
    });
}

export const setSideMenuBarCollapsed = (collapsed) => dispatch => {
    dispatch({
        type: SIDE_MENU_COLLAPSE_BAR,
        payload: collapsed
    });
}

export const selectSideMenuItem = (selectedMenuItem) => dispatch => {
    dispatch({
        type: SIDE_MENU_SELECT_ITEM,
        payload: selectedMenuItem
    });
}