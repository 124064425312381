import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import rootReducer from '../../reducers';
import { routerMiddleware } from 'connected-react-router';

const initialState = {};

const middleware = [];

middleware.push(thunk);

export const history = createBrowserHistory({
    basename: process.env.PUBLIC_URL
});
middleware.push(routerMiddleware(history));

const composeEnhancers =
    typeof window === 'object' &&
    window.location.href.indexOf('localhost') > -1 && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ?   
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        })
    :
        compose;

const enhancer = composeEnhancers(
    applyMiddleware(...middleware),
    // other store enhancers if any
);

const store = createStore(
    rootReducer(history), 
    initialState, 
    enhancer
);

export default store;