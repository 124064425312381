import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toggleSideMenuBar } from '../actions/sideMenuBarActions';
import { logout } from '../actions/authActions';
import { withTheme, withStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, IconButton, Button, Hidden, Tooltip, Fade, Menu, MenuItem, Grid, ListItemIcon } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import SettingsPower from '@material-ui/icons/SettingsPower';
import { Link as RouterLink } from 'react-router-dom';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const useStyles = theme => ({
    grow: {
        flexGrow: 1,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        display: 'flex',
        paddingLeft: 0,
    },
    menuButton: {
        marginRight: theme.spacing(1),
    },
    brandLogo: {
        backGroundImage: 'url("./logo.png")',
        maxHeight: '40px',
        flexGrow: 1,
    }
});

class TopAppBar extends Component {
    state = {
        anchorEl: null
    }

    handleSettingsMenuClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    }
    handleSettingsMenuClose = () => {
        this.setState({ anchorEl: null });
    };

    render() {
        const { anchorEl } = this.state;
        const { 
            classes,
            toggleSideMenuBar,
            logout
        } = this.props;

        return (
            <Fragment>

                <Menu
                    id='settings-menu'
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={this.handleSettingsMenuClose}
                    PaperProps={{
                        style: {
                            marginTop: '30px'
                        }
                    }}
                >
                    <MenuItem 
                        onClick={this.handleSettingsMenuClose} 
                        component={RouterLink} 
                        to='/profile'
                    >
                        <ListItemIcon>
                            <AccountCircleIcon />
                        </ListItemIcon>
                        Profile
                    </MenuItem>
                    <MenuItem onClick={() => {
                        this.handleSettingsMenuClose();
                        logout();
                    }}>
                        <ListItemIcon>
                            <ExitToAppIcon />
                        </ListItemIcon>
                        Logout
                    </MenuItem>
                </Menu>

                <AppBar position='fixed' className={classes.appBar}>
                    <Toolbar>
                        <Hidden mdUp>
                            <IconButton
                                color='inherit'
                                edge='start'
                                className={classes.menuButton}
                                onClick={toggleSideMenuBar}
                            >
                                <MenuIcon />
                            </IconButton>
                        </Hidden>
                        <Hidden smDown>
                            <Tooltip title='Home' placement='right' TransitionComponent={Fade}>
                                <Button component={RouterLink} to='/'>
                                    <img src="./logo.png" alt='AuthVela' className={classes.brandLogo} />
                                </Button>
                            </Tooltip>
                        </Hidden>

                        <div className={classes.grow} />


                        <Tooltip title={
                            <Fragment>
                                <Grid container direction='column'>
                                    <Grid item>
                                        Profile
                                    </Grid>
                                    <Grid item>
                                        <hr/>
                                    </Grid>
                                    <Grid>
                                        Logout
                                    </Grid>
                                </Grid>
                            </Fragment>}
                            placement='left'
                        >
                            <IconButton
                                color='inherit'
                                onClick={this.handleSettingsMenuClick}
                            >
                                <SettingsPower />
                            </IconButton>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
            </Fragment>
        )
    }
}

export default connect(null, { 
    toggleSideMenuBar,
    logout,
})(withTheme(withStyles(useStyles)(TopAppBar)));


TopAppBar.propTypes = {
    toggleSideMenuBar: PropTypes.func,
    logout: PropTypes.func,

    classes: PropTypes.object,
}
