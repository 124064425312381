import { INFO_FIRE, INFO_EXTINGUISH } from '../actions/types';

const initialState = {
    info: false,
    infoMessage: null,
};

const infoReducer = (state = initialState, action) => {
    switch (action.type) {
        case INFO_FIRE:
            return {
                ...state,
                info: true,
                infoMessage: action.payload.infoMessage ? action.payload.infoMessage : null,
            }
        case INFO_EXTINGUISH:
            return {
                ...state,
                info: false,
                infoMessage: null,
            }
        default:
            return state;
    }
}

export default infoReducer;