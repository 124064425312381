// import React from 'react';
import { getDecoratorForRoute } from './routesDecorators';
// import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplit';
// import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const menu = [
    {
        ...getDecoratorForRoute('/dashboard'),
        siblings: []
    },
    {
        ...getDecoratorForRoute('/users'),
        siblings: []
    },
    {
        ...getDecoratorForRoute('/network-devices'),
        siblings: []
    },
    {
        ...getDecoratorForRoute('/access-log'),
        siblings: []
    },
    {
        ...getDecoratorForRoute('/connection-settings'),
        siblings: []
    },
    {
        ...getDecoratorForRoute('/profile'),
        siblings: []
    },
    // {
    //     ...getDecoratorForRoute('/identities'),
    //     siblings: [
    //         {
    //             ...getDecoratorForRoute('/identities/accounts'),
    //         },
    //         {
    //             ...getDecoratorForRoute('/identities/devices'),
    //         },
    //     ]
    // },
    // {
    //     label: 'Misc',
    //     route: null,
    //     icon: <HorizontalSplitIcon />,
    //     siblings: [
    //         {
    //             ...getDecoratorForRoute('/'),
    //         },
    //         {
    //             ...getDecoratorForRoute('/'),
    //         },
    //     ],
    //     open: true
    // },
    // {
    //     label: '404 Test',
    //     route: '/*',
    //     icon: <ErrorOutlineIcon />,
    //     siblings: []
    // },
];

export default menu;