import Entity from './Entity';

export default class Settings extends Entity {
    getDefinitions() {
        return {
            primaryKey: 'id',
            properties: {
                  "radiusIPAddress": {
                    "type": "string",
                  },
                  "authenticationPort": {
                    "type": "string",
                  },
                  "accountingPort": {
                    "type": "string",
                  },
                  "radiusSharedSecret": {
                    "type": "string",
                  },
            },
            endpoints: {
                getSingle: {
                  path: '/api/settings',
                  alias: 'getSettings',
                },
            },
            UIRoutes: {
            },
            decorators: {
                singleHeading: 'Connection Settings',
                labels: {
                    'radiusIPAddress': 'RADIUS IP Address',
                    'authenticationPort': 'Authentication Port',
                    'accountingPort': 'Accouting Port',
                    'radiusSharedSecret': 'RADIUS Shared Secret',
                },
            },
            escort: null
        }
    }
}