import React, { Fragment } from 'react';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import * as moment from 'moment';

export default function FormattedValueDetailsField({
    fieldValue,
    fieldDef
}) {

    switch (fieldDef.format) {
        case 'successFailedIcon':
            return (
                <Fragment>
                    {fieldValue
                        ? <CheckCircleRoundedIcon style={{color: "green"}}/>
                        : <ErrorRoundedIcon style={{color: "red"}}/>
                    }
                </Fragment>
            )
        case 'date':
            return (
                <span className="selectable">
                    {moment(fieldValue).format('L LTS')}
                </span>
            )
        default:
            return (
                <span className="selectable">
                    {fieldValue}
                </span>
            )
    }
}