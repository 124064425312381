import React, { useState, useEffect, Fragment } from 'react';
import { Typography, Slider, Button } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';


export default function HourlyRestrictions({
    userAccessRestrictions,
    updateUserAccessRestrictions
}) {


    const hourlyRestriction = userAccessRestrictions.hourlyRestriction ? userAccessRestrictions.hourlyRestriction : 24;

    const [value, setValue] = useState(hourlyRestriction);
    const [modified, setModified] = useState(false);

    useEffect(() => {
        setModified(false);
    }, [setModified]);

    const getFormattedHours = value => {
        const valueFractions = value.toString().split('.');
        if (valueFractions.length === 2) {
            value = valueFractions[0] + ' hours, 30 min';
        } else {
            value = valueFractions[0] + ' hours';
        }
        return value;
    }

    return(
        <div className="fullWidth horizontalFlex">
            <Typography variant="body2">Set amount of hours user will have access to your Wi-Fi Network every day <b>({getFormattedHours(value)} {(value === 24) && ", i.e. (unset)"})</b>:</Typography>
            <div className="flexWrap"></div>
            {modified &&
                <Fragment>
                    <Button
                        onClick={() => { 
                            userAccessRestrictions.hourlyRestriction = value;
                            updateUserAccessRestrictions(userAccessRestrictions);
                        }}
                        variant='contained'
                        color='primary'
                        size='small'
                        startIcon={<CheckCircleIcon />}
                    >
                        Save
                    </Button>
                    <div className="flexWrap"></div>
                    <br/>
                </Fragment>
            }
            <div style={{ marginTop: "20px" }} className="fullWidth">
                <Slider
                    defaultValue={24}
                    aria-labelledby="discrete-slider"
                    valueLabelDisplay="auto"
                    step={0.5}
                    marks
                    min={0.5}
                    max={24}
                    value={value}
                    onChange={(event, value) => {
                        setValue(value);
                        setModified(true);
                    }}
                />
            </div>
        </div>
    );
}