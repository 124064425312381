import React, { useState, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { defaultAPIClient } from '../api-client/config';
import { requestAPI } from '../actions/apiRequestActions';
import { CircularProgress, Card, CardContent, Typography } from '@material-ui/core';
import CRUDLEscortFlatEntityFormComponent from './common/crudl/CRUDLEscortFlatEntityFormComponent';
import FullEditFormToolsetComponent from './common/crudl/FullEditFormToolsetComponent';
import { setSaveState, setEditMode, clearUpdatedSelectedEntityWarnings } from '../actions/CRUDLEscortComponentActions';
import { API_ERRORS_IGNORINGS } from '../api-client/config';
import { Alert } from '@material-ui/lab';

export default connect(null, {
    requestAPI,
    setSaveState,
    setEditMode,
    clearUpdatedSelectedEntityWarnings,
})(function ProfileView({
    Model,
    requestAPI,
    setSaveState,
    setEditMode,
    clearUpdatedSelectedEntityWarnings,
}) {

    const [validationErrors, setValidationErrors] = useState(null);
    const [requestError, setRequestError] = useState(null);
    const [updateWarnings, setUpdateWarnings] = useState(null);
    const [loading, setLoading] = useState(true);
    const [profile, setProfile] = useState(null);

    useEffect(() => {
        requestAPI({
            apiClient: defaultAPIClient,
            requestParams: {
                url: Model.getDefinitions().endpoints.getSingle.path,
                method: 'GET',
            },
            requestAlias: Model.getDefinitions().endpoints.getSingle.alias,
            responseCallBack: (response) => { 
                processAPIResponse(response);
            },
        });
    }, []);

    const processSaveResponse = (response) => {
        if (response.data) {
            if(response.data.error) {
                if(response.data.errors) {
                    let validationErrors = {};
                    response.data.errors.forEach(error => {
                        const {
                            field,
                            defaultMessage,
                        } = error;
    
                        validationErrors[field] = defaultMessage;
                    });
    
                    setValidationErrors(validationErrors);
                }

                setRequestError(response.data.error);

                setSaveState('not-saved');
                setEditMode(true);
                setLoading(false);
            } else {
                setUpdateWarnings(null);
                setValidationErrors(null);
                setValidationErrors(null);
                setRequestError(null);
                setSaveState('saved', response.data);
                processAPIResponse(response);
                setEditMode(false);
            }
        }
    }

    const processAPIResponse = (response) => {
        setProfile(Model.mapDataToProperties(response.data));
        setLoading(false);
    }

    return (
        <Fragment>
            {loading 
                ?
                    <CircularProgress />
                :
                    <Fragment>
                        {profile &&
                            <Card variant="outlined" style={{ minHeight: "800px", height: "100%" }}>
                                <CardContent>
                                    <Typography variant="h6" component="h2">
                                        {Model.getDefinitions().decorators.singleHeading}
                                        <hr/>
                                    </Typography>

                                    <FullEditFormToolsetComponent
                                        Model={Model} 
                                        selectedEntity={profile}
                                    />

                                    {requestError && API_ERRORS_IGNORINGS.indexOf(requestError) === -1 && 
                                            <Fragment>
                                                <Alert severity='error'>{requestError}</Alert>
                                                <br/>
                                            </Fragment>
                                    }

                                    <CRUDLEscortFlatEntityFormComponent
                                        Model={Model} 
                                        selectedEntity={profile}
                                        labelsOrientation='row'
                                        showLabeledOnly={true}
                                        minLabelsColumnWidth={'200px'}
                                        processSaveResponse={processSaveResponse}
                                        validationErrors={validationErrors}
                                        updateWarnings={updateWarnings}
                                    />

                                </CardContent>
                                <br/>
                            </Card>
                        }
                    </Fragment>
            }
        </Fragment>
    );
})