import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { defaultAPIClient } from '../../api-client/config';
import { requestAPI } from '../../actions/apiRequestActions';
import { LinearProgress, withTheme, withStyles, Box } from '@material-ui/core';
import CRUDLEscortFlatEntityFormComponent from '../common/crudl/CRUDLEscortFlatEntityFormComponent';
import FullEditFormToolsetComponent from '../common/crudl/FullEditFormToolsetComponent';
import { sideMenuBarMaxWidth, sideMenuBarMaxCollapsedWidth } from '../../ui-constants';
import { setSaveState, setEditMode, clearUpdatedSelectedEntityWarnings } from '../../actions/CRUDLEscortComponentActions';
import clsx from 'clsx';
import { API_ERRORS_IGNORINGS } from '../../api-client/config';

import User from '../../entities/User';
import { Alert } from '@material-ui/lab';

const useStyles = theme => ({
    formContainer: {
        position: 'absolute',
        top: theme.spacing(24),
        left: sideMenuBarMaxWidth,
        bottom: '0',
        right: '0',
        overflow: 'auto',
        display: 'flex',
        flexFlow: 'column',
        padding: theme.spacing(1),

        [theme.breakpoints.down('sm')]: {
            top: theme.spacing(20),
            left: '0',
        }
    },
    formContainerMenuCollapsed: {
        left: sideMenuBarMaxCollapsedWidth,

        [theme.breakpoints.down('sm')]: {
            left: '0',
        }
    }
});

class UserFullEdit extends Component {

    state = {
        userEntity: null,
        userEntityUpdated: false,
        validationErrors: null,
        updateWarnings: null,
        requestError: null,
    }

    constructor(props) {
        super(props);

        this.Model = new User(true);
    }

    _isMounted = false;

    componentDidMount() {

        this._isMounted = true;

        this.userId = this.props.match.params[this.Model.getDefinitions()['primaryKey']];

        this.getUserEntity();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setStateIfMounted(setStateObj) {
        if (this._isMounted) {
            this.setState(setStateObj);
        }
    }

    componentDidUpdate() {
        const {
            cancelled,
            updatedSelectedEntity,
            updatedSelectedEntityWarnings,
            clearUpdatedSelectedEntityWarnings,
        } = this.props;

        if (cancelled) this.setStateIfMounted({ 
            validationErrors: null,
            updateWarnings: null,
        });

        if (updatedSelectedEntityWarnings) {
            this.setStateIfMounted({ 
                updateWarnings: updatedSelectedEntityWarnings,
                userEntity: updatedSelectedEntity,
            });
            clearUpdatedSelectedEntityWarnings();
        }
    }

    getUserEntity() {
        const {
            requestAPI,
            setSaveState,
        } = this.props;

        setSaveState(null);

        requestAPI({
            apiClient: defaultAPIClient,
            requestParams: {
                url: this.Model.getDefinitions().endpoints.getSingle.path.replace('{entityId}', this.userId),
                method: 'GET',
            },
            requestAlias: this.Model.getDefinitions().endpoints.getSingle.alias,
            responseCallBack: (response) => { 
                this.setEntityFromAPIResponse(response);
             },
        });
    }

    processSaveResponse = (response) => {
        const {
            setSaveState,
            setEditMode,
        } = this.props;

        if (response.data) {
            if(response.data.error) {
                if(response.data.errors) {
                    let validationErrors = {};
                    response.data.errors.forEach(error => {
                        const {
                            field,
                            defaultMessage,
                        } = error;
    
                        validationErrors[field] = defaultMessage;
                    });
    
                    this.setState({ validationErrors });
                }

                this.setState({ requestError: response.data.error });

                setSaveState('not-saved');
                setEditMode(true);
            } else {
                this.setState({
                    requestError: null,
                    validationErrors: null,
                    updateWarnings: null,
                });
                setSaveState('saved', response.data);
            }
        }
    }

    setEntityFromAPIResponse(response) {
        let userEntity = new this.Model.constructor();
        userEntity.mapDataToProperties(response.data)

        this.setStateIfMounted({
                userEntity,
                validationErrors: null,
                updateWarnings: null,
        });
    }

    render() {
        const {
            classes,
            menuBarCollapsed,
        } = this.props;
        const {
            userEntity,
        } = this.state;

        return (
            <Fragment>
                {!userEntity && <LinearProgress /> }

                {Boolean(userEntity) &&
                    <Fragment>
                        <FullEditFormToolsetComponent
                            Model={this.Model} 
                            selectedEntity={userEntity}
                        />
                        <hr style={{ height: '1px', width: '100%' }}/>
                        <Box className={clsx(classes.formContainer, menuBarCollapsed ? classes.formContainerMenuCollapsed : null)}>
                            {this.state.requestError && API_ERRORS_IGNORINGS.indexOf(this.state.requestError) === -1 && 
                                    <Fragment>
                                        <Alert severity='error'>{this.state.requestError}</Alert>
                                        <br/>
                                    </Fragment>
                            }

                            <CRUDLEscortFlatEntityFormComponent
                                Model={this.Model} 
                                selectedEntity={userEntity}
                                propertiesDefinition='properties'
                                labelsOrientation='row'
                                saveAPIEndpoint='save'
                                processSaveResponse={this.processSaveResponse}
                                validationErrors={this.state.validationErrors}
                                updateWarnings={this.state.updateWarnings}
                            />
                        </Box>
                    </Fragment>
                }
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    apiRequestId:           state.apiRequestReducer.apiRequestId,
    apiRequestResult:       state.apiRequestReducer.apiRequestResult,
    apiIsRequested:         state.apiRequestReducer.apiIsRequested,
    cancelled:              state.CRUDLEscortComponentReducer.cancelled,
    saveState:              state.CRUDLEscortComponentReducer.saveState,
    updatedSelectedEntity:  state.CRUDLEscortComponentReducer.updatedSelectedEntity,
    updatedSelectedEntityWarnings: state.CRUDLEscortComponentReducer.updatedSelectedEntityWarnings,
    menuBarCollapsed:       state.sideMenuBarReducer.menuBarCollapsed,
    responsiveSideMenuBarVisibility: state.sideMenuBarReducer.responsiveSideMenuBarVisibility,
});

export default connect(mapStateToProps, {
    requestAPI,
    setSaveState,
    setEditMode,
    clearUpdatedSelectedEntityWarnings,
})(withRouter(withTheme(withStyles(useStyles)(UserFullEdit))));


UserFullEdit.propTypes ={
    requestAPI: PropTypes.func,
    setSaveState: PropTypes.func,
    setEditMode: PropTypes.func,
    clearUpdatedSelectedEntityWarnings: PropTypes.func,

    classes: PropTypes.object,
    match: PropTypes.object,
    cancelled: PropTypes.bool,
    menuBarCollapsed: PropTypes.bool,
    updatedSelectedEntity: PropTypes.object,
    updatedSelectedEntityWarnings: PropTypes.object,
}