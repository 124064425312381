import { 
    EXCEPTION_EXTINGUISH, 
    AUTH_VERIFY_AUTHENTICATION, 
    AUTH_SET_AUTHENTICATED, 
    AUTH_SET_UNAUTHENTICATED, 
    AUTH_GET_AUTHENTICATED, 
    AUTH_REAUTHENTICATION, 
    AUTH_AUTHENTICATION_FAILED, 
    AUTH_LOG_OUT,
} from '../actions/types';
import { defaultAPIClient } from '../api-client/config';
import { requestAPI } from '../actions/apiRequestActions';

export const authenticate = (credentials) => dispatch => {
    var authenticationCredentials = new FormData();

    if (credentials.refresh_token === undefined) {
        /**
         * Credentials from Login component
         */
        authenticationCredentials.append('username', credentials.identity);
        authenticationCredentials.append('password', credentials.password);
        authenticationCredentials.append('grant_type', 'password');
    } else {
        /**
         * Refresh token credentials
         */
        authenticationCredentials.append('refresh_token', credentials.refresh_token);
        authenticationCredentials.append('grant_type', 'refresh_token');
    }

    const requestParams = {
        url: '/oauth/token',
        method: 'POST',
        data: authenticationCredentials,
        headers: { 
            'Content-Type': 'multipart/form-data',
        },
        /**
         * `auth` indicates that HTTP Basic auth should be used, and supplies credentials.
         * This will set an `Authorization` header, overwriting any existing
         * `Authorization` custom headers you have set using `headers`.
         * Please note that only HTTP Basic auth is configurable through this parameter.
         * For Bearer tokens and such, use `Authorization` custom headers instead.
         */
        auth: {
            username: defaultAPIClient.initParams.basicHTTPAuthorization.clientId,
            password: defaultAPIClient.initParams.basicHTTPAuthorization.password,
        },
    };

    dispatch(dispatch => {
        let { apiClientRequestPromise } = dispatch(requestAPI({
            apiClient: defaultAPIClient,
            requestParams,
            requestAlias: (credentials.identity && !credentials.refresh_token) ? 'authentication-credentials' : 'authentication-refresh-token',
            repeatIfRequestHasFailed: (credentials.identity && !credentials.refresh_token) ? false : true,
        }));

       apiClientRequestPromise.then((result) => {
            if (!result.error) {
                /**
                * 
                * 
                * Set authenticated here
                * 
                * Persist authentication request payload to localStorage
                * 
                */
                defaultAPIClient.persistParams(result.data);
                dispatch({
                    type: AUTH_SET_AUTHENTICATED,
                    payload: {}
                });
           } else if (result.error.request.status === 0) {
                dispatch({
                    type: EXCEPTION_EXTINGUISH,
                    payload: {}
                });
                dispatch({
                    type: AUTH_AUTHENTICATION_FAILED,
                    payload: {
                        displayText: 'Service is temporarily unavailable',
                    }
                });
           }
       });
    });
}


export const verifyAuthentication = () => dispatch => {

    dispatch({
        type: AUTH_VERIFY_AUTHENTICATION,
        payload: {}
    });

    if (defaultAPIClient.initParams.persistedParams.access_token) {

        const requestParams = {
            url: '/oauth/check_token?token=' + defaultAPIClient.initParams.persistedParams.access_token,
            method: 'POST',
            auth: {
                username: defaultAPIClient.initParams.basicHTTPAuthorization.clientId,
                password: defaultAPIClient.initParams.basicHTTPAuthorization.password,
            },
        };


        dispatch(dispatch => {
            let { apiClientRequestPromise } = dispatch(requestAPI({
                apiClient: defaultAPIClient,
                requestParams,
                requestAlias: 'authentication-verification',
            }));

           apiClientRequestPromise.then((result) => {
                if (!result.error) {
                    /**
                    * 
                    * 
                    * Set authenticated here
                    * 
                    * Persist authentication request payload to localStorage
                    * 
                    */
                    defaultAPIClient.persistParams(result.data);
                    dispatch({
                        type: AUTH_SET_AUTHENTICATED,
                        payload: {}
                    });
               } else if (result.error) {
                    if (result.error.response && result.error.response.data.error.indexOf('invalid_token') > -1) {
                        dispatch(logout());
                        dispatch({
                            type: AUTH_AUTHENTICATION_FAILED,
                            payload: {
                                displayText: 'Invalid authenticaton token provided',
                            }
                        });
                        return;
                    }
                    if (result.error.request) {
                        dispatch({
                            type: AUTH_AUTHENTICATION_FAILED,
                            payload: {
                                displayText: 'Service is temporarily unavailable',
                            }
                        });
                    } else {
                        dispatch(logout());
                        dispatch({
                            type: AUTH_AUTHENTICATION_FAILED,
                            payload: {
                                displayText: 'Invalid authenticaton token provided',
                            }
                        });
                    }
              }
           });
        });

    } else {
        dispatch({
            type: AUTH_LOG_OUT,
            payload: {}
        });
    }
}

export const reAuthenticate = () => dispatch => {
    dispatch({
        type: AUTH_REAUTHENTICATION,
        payload: {}
    });
}

export const refreshAuthenticationToken = () => dispatch => {
    if (defaultAPIClient.initParams.persistedParams.refresh_token !== null) {
        dispatch({
            type: AUTH_VERIFY_AUTHENTICATION,
            payload: {}
        });
        dispatch(authenticate({
            refresh_token: defaultAPIClient.initParams.persistedParams.refresh_token,
        }));
    } else {
        dispatch({
            type: AUTH_LOG_OUT,
            payload: {}
        });
    }
}

export const logout = () => dispatch => {
    defaultAPIClient.clearPersistedParams();
    sessionStorage.clear();
    dispatch({
        type: AUTH_LOG_OUT,
        payload: {}
    });
}

export const unauthenticate = () => dispatch => {
    dispatch({
        type: AUTH_SET_UNAUTHENTICATED,
        payload: {}
    });
}

export const getAuthenticated = () => dispatch => {
    dispatch({
        type: AUTH_GET_AUTHENTICATED,
        payload: {}
    });
}