import React, { Fragment } from 'react';
import Entity from "./Entity";
import BeenhereIcon from '@material-ui/icons/Beenhere';
import PreviewDetailsComponent from '../components/common/crudl/PreviewDetailsComponent';
import * as moment from 'moment';
import { TextField } from '@material-ui/core';

export default class AccessLog extends Entity {
    getDefinitions() {
        return {
            primaryKey: 'id',
            properties: {
                "id": {
                    "type": "string",
                    "key": "hidden",
                  },
                  "created": {
                    "type": "string",
                    "format": "date",
                    "renderer": value => moment(value).format('L LTS')
                  },
                  "login": {
                    "type": "string",
                    "filter": {
                      component: (props) => <TextField {...props}/>,
                      default: true,
                    }
                  },
                  "userDeviceMac": {
                    "type": "string",
                  },
                  "networkDeviceMac": {
                    "type": "string",
                  },
                  "success": {
                    "type": "string",
                  },
            },
            operationallyUpdatedDTOProperties: {
                "created": {
                    "type": "string",
                    "format": "date",
                  },
                  "login": {
                    "type": "string",
                  },
                  "userDeviceMac": {
                    "type": "string",
                  },
                  "networkDeviceMac": {
                    "type": "string",
                  },
                  "success": {
                    "type": "string",
                    "format": "successFailedIcon",
                  },

            },
            endpoints: {
                list: {
                    path: '/api/authlogs',
                    pageSize: 10,
                    /**
                     * Redux API_RESPONSE action payload property
                     */
                    alias: 'authlogsList',
                },
                getSingle: {
                //   path: '/api/authlogs/{logId}',
                //   alias: 'authlogSingle',
                    mode: 'showSelectedEntityDetails'
                },
            },
            UIRoutes: {
              list: '/access-log',
            },
            decorators: {
                singleHeading: 'Access Log Entry',
                listHeading: 'Access Logs',
                labels: {
                    'created': 'Time',
                    'login': 'Username',
                    'userDeviceMac': 'User Device MAC',
                    'networkDeviceMac': 'Network Device MAC',
                    'success': 'Authenticated',
                },
                table: {
                    cells: [
                        { property: 'created', label: 'Time', doNotCollapse: true },
                        { property: 'login', doNotCollapse: true },
                        { property: 'userDeviceMac' },
                        { property: 'networkDeviceMac' },
                    ]
                },
            },
            escort: {
              tabs: [
                { 
                  heading: <Fragment><BeenhereIcon/>Access Log Entry</Fragment>, 
                  component: {
                    class: PreviewDetailsComponent,
                    props: {
                      propertiesDefinition: 'operationallyUpdatedDTOProperties',
                    }
                  },
                  // toolset: {
                  //   class: CRUDLFormToolsetComponent,
                  //   props : { }
                  // } 
                },
              ]
            }
        }
    }
}