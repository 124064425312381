import React, { Component } from 'react';

class CRUDLEntityComponent extends Component {
    constructor(props, EntityModel) {
        super(props);

        this.EntityModel = EntityModel;

        var sessionStorageKey = this.constructor.name + 'CRUDLEntityComponentState';

        var sessionState = {
            listFilter: {},
        };

        this.restoreStateFromSessionStorage = () => {
            try {
                let sessionStateFromStorage = JSON.parse(sessionStorage.getItem(sessionStorageKey));
                sessionState = Object.assign({}, sessionState, sessionStateFromStorage || {});
            } catch {
                console.error('Session Storage is disabled');
            }
        };
        this.persistsStateToSessionStorage = () => {
            sessionStorage.setItem(sessionStorageKey, JSON.stringify(sessionState));
        };

        /**
         * List Request Params
         */
        this.getListFilterRequestParams = () => {
            let listRequestParams = [];
            if (sessionState.listFilter) {
                for (let [key, value] of Object.entries(sessionState.listFilter)) {
                    if (value !== '') listRequestParams.push(encodeURI(key + '=' + value));
                }
            }

            return '?' + listRequestParams.join('&');
        };
        this.getListFilter = () => {
            return sessionState.listFilter;
        };
        this.setListFilter = (listFilterParams) => {
            let listFilter = Object.assign(sessionState.listFilter, listFilterParams || {});
            sessionState = { ...sessionState, listFilter }
            this.persistsStateToSessionStorage();
        };
        this.reSetListFilter = (listFilter) => {
            sessionState = { ...sessionState, listFilter }
            this.persistsStateToSessionStorage();
        };

        this.restoreStateFromSessionStorage();
    }

    setDefaultListFilter(defaultListFilter) {
        if (Object.entries(this.getListFilter()).length === 0) {
            this.setListFilter(defaultListFilter);
        }
    }

    componentWillUnmount(){
        this.persistsStateToSessionStorage();
    }

    render() {
        return (
            <div>CRUDLEntityComponent</div>
        )
    }
}

export default CRUDLEntityComponent;