import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles, IconButton, Tooltip, TextField } from '@material-ui/core';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import CancelIcon from '@material-ui/icons/Cancel';
import DirectionsIcon from '@material-ui/icons/Directions';

const useStyles = ({
    buttonsSpacer: {
        width: '4px',
    },
});

class CRUDLTablePaginationComponent extends Component {

    state = {
        manuallyInputtedPage: null,
    }

    gotoPage() {
        const {
            setPageHandler,
        } = this.props;

        setPageHandler(this.state.manuallyInputtedPage - 1);
        this.setState({ manuallyInputtedPage: null });
    }

    componentDidUpdate() {
        const {
            listFilterChanged,
        } = this.props;
        if (listFilterChanged && this.state.manuallyInputtedPage !== null) {
            this.setState({ manuallyInputtedPage: null });
        }
    }

    render() {
        const {
            prevPageHandler,
            nextPageHandler,
            prevDisabled,
            nextDisabled,
            currentPage,
            listFilterChanged,
            classes,
        } = this.props;

        if (listFilterChanged) return null;

        return (
            <Fragment>
                {!this.state.manuallyInputtedPage &&
                    <IconButton 
                        onClick={() => {
                            this.setState({ manuallyInputtedPage: null });
                            prevPageHandler();
                        }}
                        disabled={ 
                            prevDisabled
                        }
                        color='primary' aria-label='prev' size='small'>
                        <Tooltip title='Previous page'>
                            <ArrowLeftIcon fontSize='large'/>
                        </Tooltip>
                    </IconButton>
                }

                <div className={classes.buttonsSpacer} />

                {/* <Typography variant='subtitle1'>
                    {currentPage + 1}
                </Typography> */}

                <TextField
                    value={
                        this.state.manuallyInputtedPage ? this.state.manuallyInputtedPage : currentPage + 1
                    }
                    onChange={(event) => {
                        this.setState({ 
                            manuallyInputtedPage: Number.parseInt(event.currentTarget.value) < 0 ? 0 : Number.parseInt(event.currentTarget.value),
                        });
                    }}
                    onFocus={(event) => {
                        event.target.select();
                    }}
                    onTouchStart={(event) => {
                        event.target.select();
                    }}
                    onKeyDown={(event) => {
                        //Enter
                        if (event.key === 'Enter') {
                            this.gotoPage();
                            event.target.select();
                        }
                    }}
                    variant='outlined'
                    size='small'
                    inputProps={{
                        style: {
                            textAlign: 'center',
                            padding: 4,
                            width: 20 * (this.state.manuallyInputtedPage ? this.state.manuallyInputtedPage.toString().length : (currentPage + 1).toString().length)
                        }
                    }}
                />

                <div className={classes.buttonsSpacer} />

                {!this.state.manuallyInputtedPage
                ?
                    <IconButton 
                        onClick={() => {
                            this.setState({ manuallyInputtedPage: null });
                            nextPageHandler();
                        }}
                        disabled={
                            nextDisabled
                        }
                        aria-label='next' size='small'>
                            <Tooltip title='Next page'>
                                <ArrowRightIcon fontSize='large'/>
                            </Tooltip>
                    </IconButton>
                : 
                    <Fragment>
                        <IconButton 
                            onClick={() => {
                                this.gotoPage();
                            }}
                            color='primary' aria-label='next' size='medium'>
                                <Tooltip title={ 'Go to page ' + this.state.manuallyInputtedPage }>
                                    <DirectionsIcon  color='secondary'/>
                                </Tooltip>
                        </IconButton>
                        <div className={classes.buttonsSpacer} />
                        <IconButton 
                            onClick={() => {
                                this.setState({ manuallyInputtedPage: null });
                            }}
                            aria-label='next' size='medium'>
                                <Tooltip title='Cancel'>
                                    <CancelIcon/>
                                </Tooltip>
                        </IconButton>
                    </Fragment>
                }

            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    listFilterChanged:          state.CRUDLEscortComponentReducer.listFilterChanged,
});

export default connect(mapStateToProps, {})(withStyles(useStyles)(CRUDLTablePaginationComponent));

CRUDLTablePaginationComponent.propTypes = {
    prevPageHandler: PropTypes.func,
    nextPageHandler: PropTypes.func,
    setPageHandler: PropTypes.func,

    prevDisabled: PropTypes.bool,
    nextDisabled: PropTypes.bool,
    currentPage:  PropTypes.number,
    listFilterChanged: PropTypes.bool,
    theme: PropTypes.object,
    classes: PropTypes.object,
}