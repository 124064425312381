import React, { Fragment } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import HelpIcon from '@material-ui/icons/Help';
import { IconButton, Tooltip, Toolbar, withStyles, Button, CircularProgress, Typography } from '@material-ui/core';
import CRUDLEscortToolsetComponent, { connect } from '../../base/crudl/CRUDLEscortToolsetComponent';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';

import clsx from 'clsx';

const useStyles = theme => ({
    root: {
    },
    grow: {
        flexGrow: 1,
    },
    buttonMargin: {
        margin: theme.spacing(1),
    },
    modifiedSaveButtonState: {
        boxShadow: '0px 0px 4px 4px ' + theme.palette.warning.light,
    }
});

class CRUDLEscortAddNewEntityFormToolsetComponent extends CRUDLEscortToolsetComponent {

    render() {
        const {
            Model,
            classes,
            modified,
            saveState,
            /**
             * Actions
             */
            setSaveState, 
        } = this.props;

        return (
            <Toolbar position='fixed'>
                {saveState === 'saving' &&
                    <Fragment>
                        <CircularProgress size={24} />&nbsp;&nbsp;saving...
                    </Fragment>
                }

                {saveState !== 'saving' &&
                    <Fragment>
                        {(saveState === null || saveState === 'saved' || saveState === 'not-saved') &&
                            <Fragment>
                                <Button
                                    onClick={() => {
                                        setSaveState(null);
                                    }}
                                    component={RouterLink}
                                    to={Model.getDefinitions().UIRoutes.list}
                                    variant='contained'
                                    color='default'
                                    size='small'
                                    startIcon={<CancelIcon />}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={() => { 
                                        setSaveState('initiated');
                                    }}
                                    variant='contained'
                                    color='primary'
                                    size='small'
                                    className={clsx(classes.buttonMargin, (modified) ? classes.modifiedSaveButtonState : null)}
                                    startIcon={<CheckCircleIcon />}
                                >
                                    Create
                                </Button>
                            </Fragment>
                        }
                        &nbsp;
                        {saveState === 'not-saved' &&
                            <Fragment>
                                <WarningIcon color='error' />
                                <Typography variant='caption' color='error'>
                                    (not created)
                                </Typography>
                            </Fragment>
                        }
                    </Fragment>
                }

                    <div className={classes.grow} />

                    <Typography variant='subtitle2'>
                        {Model.getDefinitions().decorators.newHeading}
                    </Typography>

                    <div className={classes.grow} />

                    <IconButton onClick={() => { console.log('Help'); }}>
                        <Tooltip title='Help'>
                            <HelpIcon />
                        </Tooltip>
                    </IconButton>
            </Toolbar>
        )
    }
}

export default connect(withStyles(useStyles)(CRUDLEscortAddNewEntityFormToolsetComponent));