import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { getAuthenticated } from '../../actions/authActions';
import { connect } from 'react-redux';

class ProtectedComponent extends Component {
    constructor(props) {
        super(props);

        const { getAuthenticated } = this.props;

        getAuthenticated();
    }
    render() {
        const { 
            isAuthenticated 
        } = this.props;

        if (isAuthenticated) {
            return (
                <Fragment>
                    { this.props.component }
                </Fragment>
            )
        }
        else return (
            <Fragment>
                {(() => {
                    switch (this.props.action) {
                        case 'exclude': 
                            return null;
                        default:
                            return <Redirect to='/login' />;
                    }
                })()}
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.authReducer.isAuthenticated
});

export default connect(mapStateToProps, { 
    getAuthenticated,
})(ProtectedComponent);


ProtectedComponent.propTypes = {
    getAuthenticated: PropTypes.func,

    isAuthenticated: PropTypes.bool,
    component: PropTypes.object,
    action: PropTypes.string,
}