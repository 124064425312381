import React, { useState, Fragment, useEffect } from 'react';
import { List, ListItem, ListItemSecondaryAction, ListItemText, ListItemAvatar, Avatar, IconButton, Slider, Button } from '@material-ui/core';
import ScheduleIcon from '@material-ui/icons/Schedule';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import AddAlarmIcon from '@material-ui/icons/AddAlarm';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

export default function TimeRangeRestrictions({
    userAccessRestrictions,
    updateUserAccessRestrictions
}) {

    const [newTimeRange, setNewTimeRange] = useState([8, 12]);
    const [timeRanges, setTimeRanges] = useState(userAccessRestrictions.timeRangeRestrictions);
    const [modified, setModified] = useState(false);

    useEffect(() => {
        setModified(false);
    }, [setModified]);

    const changeRange = (id, from, to) => {
        setTimeRanges(timeRanges.map(timeRange => {
            if (id === timeRange.id) {
                timeRange.from = from;
                timeRange.to = to;
            }
            return timeRange;
        }));
        setModified(true);
    }

    const getFormattedHours = value => {
        const valueFractions = value.toString().split('.');
        let hours = parseInt(valueFractions[0]);
        let dt = '';

        if (value < 1) {
            hours += 12;
            dt = 'AM';
        } else if (value < 12) {
            dt = 'AM';
        } else if (value >= 12 && value < 13) {
            dt = 'PM';
        } else if (value >= 13 && value < 24) {
            hours -= 12;
            dt = 'PM';
        } else {
            hours = 12;
            dt = 'AM';
        }

        if (valueFractions.length === 2) {
            value = hours.toString() + ':30 ' + dt;
        } else {
            value = hours.toString() + ':00 ' + dt;
        }
        
        return value;
    }

    return(
        <div className="fullWidth">
            {modified &&
                <Fragment>
                    <Button
                        onClick={() => { 
                            userAccessRestrictions.timeRangeRestrictions = timeRanges;
                            updateUserAccessRestrictions(userAccessRestrictions);
                        }}
                        variant='contained'
                        color='primary'
                        size='small'
                        startIcon={<CheckCircleIcon />}
                    >
                        Save
                    </Button>
                    <div className="flexWrap"></div>
                    <br/>
                </Fragment>
            }
            <List className="fullWidth">
                {timeRanges.map(timeRange => {
                    return (
                        <ListItem key={timeRange.id}>
                            <ListItemAvatar>
                                <Avatar>
                                    <ScheduleIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                                <div>From: {getFormattedHours(timeRange.from)} To: {getFormattedHours(timeRange.to)}</div>
                                <Slider
                                    value={[timeRange.from, timeRange.to]}
                                    onChange={(event, values) => {
                                        changeRange(timeRange.id, ...values);
                                    }}
                                    step={0.5}
                                    marks
                                    min={0.0}
                                    max={24}
                                    // valueLabelDisplay="auto"
                                    aria-labelledby="range-slider"
                                    className="flexEpxander"
                                />
                            </ListItemText>
                            <ListItemSecondaryAction>
                                <IconButton 
                                    onClick={() => { 
                                        setTimeRanges(timeRanges.filter(_timeRange => {
                                            return timeRange.id !== _timeRange.id;
                                        }));
                                        setModified(true);
                                    }}
                                    edge="end" aria-label="delete">
                                    <HighlightOffIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    );
                })}
            </List>

            <br/>
            <div className="fullWidth" style={{ padding: "20px", borderRadius: "8px", backgroundColor: "#dbffe3" }}>
                <Button
                    onClick={() => { 
                        setTimeRanges([...timeRanges, {
                            id: Date.now(),
                            from: newTimeRange[0],
                            to: newTimeRange[1],
                        }]);
                        setModified(true);
                    }}
                    variant='contained'
                    color='primary'
                    size='small'
                    startIcon={<AddAlarmIcon />}
                >
                    Add New Time Range
                </Button>
                <br/>
                <div>From: {getFormattedHours(newTimeRange[0])} To: {getFormattedHours(newTimeRange[1])}</div>
                <Slider
                    value={newTimeRange}
                    onChange={(event, values) => {
                        setNewTimeRange(values);
                    }}
                    step={0.5}
                    marks
                    min={0.0}
                    max={24}
                    // valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                    className="flexEpxander"
                />
            </div>

        </div>
    );
}