import React, { Fragment } from 'react';
import Entity from "./Entity";
import RouterIcon from '@material-ui/icons/Router';
import OperationalUpdateForm from '../components/common/crudl/OperationalUpdateForm';
import CRUDLFormToolsetComponent from '../components/common/crudl/CRUDLFormToolsetComponent';

export default class NetworkDevice extends Entity {
    getDefinitions() {
        return {
            primaryKey: 'id',
            properties: {
                "id": {
                    "type": "string",
                    "key": "hidden",
                  },
                  "created": {
                    "type": "string",
                    "format": "date",
                  },
                  "name": {
                    "type": "string",
                  },
                  "description": {
                    "type": "string",
                  },
                  "macAddress": {
                    "type": "string",
                  },
                  "networkdevicetype": {
                    "type": "string",
                  },
            },
            operationallyUpdatedDTOProperties: {
              "name": {
                "type": "string"
              },
              "description": {
                "type": "string",
              },
              "macAddress": {
                "type": "string",
                "readonly":  true,
              },
            },
            endpoints: {
                list: {
                    path: '/api/networkdevices',
                    pageSize: 10,
                    /**
                     * Redux API_RESPONSE action payload property
                     */
                    alias: 'networkDeviceList',
                },
                getSingle: {
                  path: '/api/networkdevice/{entityId}',
                  alias: 'networkDeviceSingle',
                },
                /**
                 * Optional
                 */
                operationallyUpdateNetworkDevice: {
                  path: '/api/networkdevice',
                  alias: 'operationallyUpdateNetworkDevice',
                },
            },
            UIRoutes: {
              list: '/network-devices',
            },
            decorators: {
                singleHeading: 'Network Device',
                listHeading: 'Network Devices',
                editHeading: 'Edit Network Device',
                labels: {
                    'name': 'Device Name',
                    'macAddress': 'MAC Address',
                    'description': 'Description',
                },
                table: {
                    cells: [
                        { property: 'name', label: 'Device Name', doNotCollapse: true },
                        { property: 'macAddress' },
                        { property: 'description' },
                    ]
                },
            },
            escort: {
              tabs: [
                { 
                  heading: <Fragment><RouterIcon/>Network Device</Fragment>, 
                  component: {
                    class: OperationalUpdateForm,
                    props: {
                      propertiesDefinition: 'operationallyUpdatedDTOProperties',
                      saveAPIEndpoint: 'operationallyUpdateNetworkDevice',
                    }
                  },
                  toolset: {
                    class: CRUDLFormToolsetComponent,
                    props : { }
                  } 
                },
              ]
            }
        }
    }
}