import React, { Fragment } from 'react';
import Entity from './Entity';
import Dummy from '../components/Dummy';
import OperationalUpdateForm from '../components/common/crudl/OperationalUpdateForm';
import CRUDLFormToolsetComponent from '../components/common/crudl/CRUDLFormToolsetComponent';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import TuneIcon from '@material-ui/icons/Tune';
// import UserOrganizationInfoEscortComponent from '../components/user/UserOrganizationInfoEscortComponent';
import { TextField } from '@material-ui/core';
import UserAccessRestrictionsComponent from '../components/user/UserAccessRestrictionsComponent';

export default class User extends Entity {
    getDefinitions() {
        return {
            primaryKey: 'id',
            properties: {
                  "firstName": {
                    "type": "string",
                    "filter": {
                      component: (props) => <TextField {...props}/>,
                      default: true,
                    }
                  },
                  "lastName": {
                    "type": "string",
                    "filter": {
                      component: (props) => <TextField {...props}/>,
                      default: true,
                    }
                  },
                  "login": {
                    "type": "string",
                    "filter": {
                      component: (props) => <TextField {...props}/>,
                      default: false,
                    }
                  },
                  "created": {
                    "type": "string",
                    "format": "date",
                  },
                  "email": {
                    "type": "string",
                    // "filter": {
                    //   component: (props) => <TextField {...props}/>,
                    //   default: true,
                    // }
                  },
                  "expire": {
                    "type": "string",
                    "format": "date-time",
                    "renderer": (value) => {
                      let d = new Date(value),
                      dFormat = [d.getMonth(),
                                 d.getDate(),
                                 d.getFullYear()].join('/') + ' ' +
                                [('0' + d.getHours()).slice(-2),
                                 ('0' + d.getMinutes()).slice(-2),
                                 ('0' + d.getSeconds()).slice(-2)].join(':');
                      return dFormat;
                    }
                  },
                  "password": {
                    "type": "string"
                  },
                  "id": {
                    "type": "string",
                    "key": "show",
                  },
                  "organizationId": {
                    "type": "string",
                    "key": "show",
                  },
            },
            userCreationDTOProperties: {
              "login": {
                "type": "string"
              },
              "password": {
                "type": "string"
              },
              "email": {
                "type": "string",
                "hidden": true
              },
              "firstName": {
                "type": "string"
              },
              "lastName": {
                "type": "string"
              },
              "expire": {
                "type": "string",
                "format": "date-time",
                "hidden": true
              },
            },
            operationallyUpdatedDTOProperties: {
              "login": {
                "type": "string",
                "readonly": true
              },
              "password": {
                "type": "string",
                "readonly": true
              },
              "email": {
                "type": "string",
                "hidden": true
              },
              "expire": {
                "type": "string",
                "format": "date",
                "hidden": true
              },
              "firstName": {
                "type": "string"
              },
              "lastName": {
                "type": "string"
              },
            },
            endpoints: {
                list: {
                    // path: '/api/users',
                    path: '/api/users/filter',
                    filterRequestParamsTransformer: (listFilterRequestParams) => {
                      const re = /(\?page=[0-9]&)/g;
                      let [ pageParam, searchParams ]  = listFilterRequestParams.split(re).filter(el => el !== '');
                      if (searchParams) {
                        let searchParam = searchParams.split('&').flatMap((searchParam) => searchParam.replace('=', '~'));
                        return pageParam + 'search=' + searchParam.join(';');
                      } else {
                        return pageParam;
                      }
                    },
                    pageSize: 10,
                    /**
                     * Redux API_RESPONSE action payload property
                     */
                    alias: 'usersList',
                },
                getSingle: {
                  path: '/api/user/{entityId}',
                  alias: 'usersSingle',
                },
                save: {
                  path: '/api/user',
                  alias: 'updateUser',
                },
                create: {
                  path: '/api/user',
                  method: 'POST',
                  alias: 'createUser',
                },
                delete: {
                  path: '/api/user/{entityId}',
                  method: 'DELETE',
                  alias: 'deleteUser',
                  allowMultipleDeletion: false,
                },
                /**
                 * Optional
                 */
                operationallyUpdateUser: {
                  path: '/api/user',
                  alias: 'operationallyUpdateUser',
                },
                getUserAccessRestrictions: {
                  path: '/api/access-restrictions/:userId',
                  alias: 'getUserAccessRestrictions',
                },
                saveUserAccessRestrictions: {
                  path: '/api/access-restrictions',
                  alias: 'saveUserAccessRestrictions',
                },
            },
            UIRoutes: {
              list: '/users',
              new: '/users/new-user',
              // edit: '/users/user/:id',
            },
            decorators: {
                singleHeading: 'User',
                listHeading: 'Users',
                editHeading: 'Edit User',
                newHeading: 'New User',
                labels: {
                    'email': 'Email',
                    'login': 'Login',
                    'password': 'Password',
                    'firstName': 'First Name',
                    'lastName': 'Last Name',
                    'expire': 'Validity Expires',
                },
                table: {
                    cells: [
                        { property: 'login', label: 'Login', doNotCollapse: true },
                        // { property: 'email' },
                        { property: 'firstName' },
                        { property: 'lastName' },
                        // { property: 'expire' },
                    ]
                },
            },
            escort: {
              tabs: [
                { 
                  heading: <Fragment><AssignmentIndIcon/>User</Fragment>, 
                  component: {
                    class: OperationalUpdateForm,
                    props: {
                      propertiesDefinition: 'operationallyUpdatedDTOProperties',
                      saveAPIEndpoint: 'operationallyUpdateUser',
                    }
                  },
                  toolset: {
                    class: CRUDLFormToolsetComponent,
                    props : { }
                  } 
                },
                { 
                  heading: <Fragment><TuneIcon/>Access Restrictions</Fragment>, 
                  component: {
                    class: UserAccessRestrictionsComponent,
                    props: {
                      Model: this
                    },
                    style: {
                      marginTop: "20px"
                    }
                  }
                },
                // { 
                //   heading: 'Dummy', 
                //   component: {
                //     class: Dummy,
                //     props: { }
                //   }
                // }
              ]
            }
        }
    }

    // @Override
    // getPersistableEntity(mutableEntity) {
    //   return mutableEntity;
    // }
}
