import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { displayContentPadding, contentMainPadding } from '../ui-constants';
import ProtectedComponent from './base/ProtectedComponent';
import NavHeader from './NavHeader';
import routes from './config/routes'


const useStyles = theme => ({
    appBarSpacer: theme.mixins.toolbar,
    displayContent: {
        flexGrow: 1,
        display: 'flex',
        flexFlow: 'column',
        padding: displayContentPadding,
        marginTop: -5,
    },
    contentMain: {
        flexGrow: 1,
        display: 'flex',
        flexFlow: 'column',
        maxHeight: '100vh',
        overflow: 'auto',
        padding: contentMainPadding,
    },

});

class MainDisplay extends Component {
    render() {
        const {
            classes,
        } = this.props;

        return (
            <Box className={classes.displayContent}>
                <ProtectedComponent component={<div className={classes.appBarSpacer} />} action='exclude' />

                <ProtectedComponent component={ <NavHeader /> } action='exclude' />

                <main className={classes.contentMain}>
                    { routes }
                </main>
            </Box>
        )
    }
}

export default withTheme(withStyles(useStyles)(MainDisplay));


MainDisplay.propTypes = {
    classes: PropTypes.object,
};