import { API_REQUEST, API_RESPONSE, API_ERROR } from '../actions/types';

const initialState = {
    apiRequestId: null,
    apiRequestResult: null,
    apiRequestAlias: null,
    apiIsRequested: false,
};

const apiRequestReducer = (state = initialState, action) => {
    switch (action.type) {
        case API_REQUEST: {
            return {
                ...state,
                apiRequestId: action.payload.requestId,
                apiRequestAlias: action.payload.alias,
                apiIsRequested: action.payload.requesting,
            }
        }
        case API_RESPONSE:
            return {
                ...state,
                apiRequestId: action.payload.requestId,
                apiRequestResult: action.payload.response,
                apiRequestAlias: action.payload.alias,
                apiIsRequested: false,
            }
        case API_ERROR:
            return {
                ...state,
                apiRequestId: action.payload.requestId,
                apiRequestResult: {
                    error: action.payload.error
                },
                apiRequestAlias: action.payload.alias,
                apiIsRequested: false,
            }
        default:
            return state;
    }
}

export default apiRequestReducer;