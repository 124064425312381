import React from 'react';
import Home from '@material-ui/icons/Home';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AccountCircleRounded from '@material-ui/icons/AccountCircleRounded';
import SecurityIcon from '@material-ui/icons/Security';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import RouterIcon from '@material-ui/icons/Router';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import SettingsIcon from '@material-ui/icons/Settings';
import BeenhereIcon from '@material-ui/icons/Beenhere';

const routesDecorators = [
    {
        route: '/',
        alias: 'home',
        label: 'Home',
        icon: <Home />
    },
    {
        route: '/login',
        alias: 'login',
        label: 'Login',
        icon: null
    },
    {
        route: '/dashboard',
        alias: 'dashboard',
        label: 'Dashboard',
        icon: <DashboardIcon />
    },
    {
        route: '/users',
        alias: 'users',
        label: 'Users',
        icon: <PeopleOutlineIcon />
    },
    {
        route: '/users/user/:id',
        alias: 'user',
        label: 'User',
        icon: <PersonOutlineIcon />
    },
    {
        route: '/users/new-user',
        alias: 'new-user',
        label: 'New User',
        icon: <PersonAddIcon />
    },
    {
        route: '/network-devices',
        alias: 'network-devices',
        label: 'Network Devices',
        icon: <RouterIcon />
    },
    {
        route: '/access-log',
        alias: 'access-log',
        label: 'Access Log',
        icon: <BeenhereIcon />
    },
    {
        route: '/profile',
        alias: 'profile',
        label: 'Profile',
        icon: <AccountCircleRounded />
    },
    {
        route: '/identities',
        alias: 'identities',
        label: 'Identities',
        icon: <SecurityIcon />
    },
    {
        route: '/connection-settings',
        alias: 'connection-settings',
        label: 'Connection Settings',
        icon: <SettingsIcon />
    },
];

export default routesDecorators;

export const getDecoratorForRoute = (route) => {
    return routesDecorators.find(routeDecorator => routeDecorator.route === route);
}

export const getDecoratorForAlias = (alias, pathname = null) => {
    let routeDecorator = routesDecorators.find(routeDecorator => routeDecorator.alias === alias);

    const routeVariablesChunksIndex = routeDecorator.route.indexOf(':');
    if (routeVariablesChunksIndex > -1) {
        let route = routeDecorator.route;
        let pathnameVariablesChunks = pathname.substr(routeVariablesChunksIndex).split('/');
        routeDecorator.route.match(/:[a-z]+/g).forEach((routeVariablesChunk, index) => {
            route = route.replace(routeVariablesChunk, pathnameVariablesChunks[index]);
        });
        routeDecorator = {
            ...routeDecorator, 
            route,
        };
    }
    return routeDecorator;
}