import axios from 'axios';
import storage from '../components/config/storage';
import { rememberMePreset } from '../components/config/storage';

/**
 * Server API client
 * @class
 * @classdesc APIClient performs remote server API calls and processing responses, dispatching Redux actions
 */
class APIClient {
    /**
     * APIClient constructor
     * @constructor
     * @param {Object} initParams               - APIClient initialization object
     * @param {String} initParams.alias         - Web Storage API key
     * @param {String} initParams.baseURL       - Remote server API base URL
     */
    constructor(initParams) {
        if (initParams === undefined) { console.error('API Client is not properly initialized'); return; }
        if (initParams.baseURL === null || initParams.baseURL === undefined) { console.error('API Client baseURL is not defined in initParams'); }
        if (initParams.alias === null || initParams.alias === undefined) { console.error('Web Storage API key (alias) is not defined in initParams'); }


        const persistedParams = JSON.parse(storage.getItem(initParams.alias));

        this.initParams = {
            ...initParams,
            persistedParams: persistedParams || {},
        };

        this.defaultRequestParams = {
            baseURL: this.initParams.baseURL,
            headers: this.getHTTPHeaders(),
            responseType: 'json',
        };

        var postponedRequestAPICallParamsQueue = [];

        this.getPostponedRequestAPICallParamsQueue = (clear = false) => {
            const _postponedRequestAPICallParamsQueue = [...postponedRequestAPICallParamsQueue];
            if (clear) postponedRequestAPICallParamsQueue = [];
            return _postponedRequestAPICallParamsQueue;
        }

        this.addPostponedRequestAPICallParams = (requestAPICallParams) => {
            if (postponedRequestAPICallParamsQueue.length < 2)
                postponedRequestAPICallParamsQueue.push(requestAPICallParams);
        }
    }


    processRequest(requestParams) {
        this.updateHeaders();
        const request = Object.assign({}, this.defaultRequestParams, requestParams || {});
        console.log(request);
        return axios(request);
    }

    updateHeaders() {
        this.defaultRequestParams.headers = this.getHTTPHeaders();
    }

    getHTTPHeaders() {
        const defaultHeaders = {
            'Content-Type': 'application/json',
        }

        let headers = defaultHeaders;

        if (this.initParams.persistedParams.access_token) {
            headers = {
                ...headers,
                'Authorization' : 'Bearer ' + this.initParams.persistedParams.access_token
            };
        }

        return headers;
    }

    clearPersistedParams() {
        storage.removeItem(this.initParams.alias);
        this.initParams = {
            ...this.initParams,
            persistedParams: {},
        };
    }

    hasHepsistedParams() {
        return this.initParams.persistedParams !== {};
    }

    persistParams(params) {
        this.initParams.persistedParams = Object.assign(this.initParams.persistedParams, params || {});
        if (!rememberMePreset()) {
            this.initParams.persistedParams = {...this.initParams.persistedParams, refresh_token: null}
        }
        storage.setItem(this.initParams.alias, JSON.stringify(this.initParams.persistedParams));
    }
}

export default APIClient;