import { INFO_FIRE, INFO_EXTINGUISH } from './types';

export const fireInfo = (infoMessage) => dispatch => {
    dispatch({
        type: INFO_FIRE,
        payload: {
            infoMessage,
        }
    });
}

export const extinguishInfo = () => dispatch => {
    dispatch({
        type: INFO_EXTINGUISH,
        payload: {}
    });
}