import uuidv4 from 'uuid/v4';

export default class Entity {

    constructor(modelOnly) {
        if (!modelOnly) {
            this.UIUUID = uuidv4();
        }

        this.mapDefinitionsToProperties();
    }

    getDefinitions() {
        console.error('getDefinitions() is not implemented for' + this.constructor.name);
    }

    mapDefinitionsToProperties() {
        const propertygetDefinitions = this.getDefinitions().properties;
        Object.keys(propertygetDefinitions).forEach(propertygetDefinition => {
            this[propertygetDefinition] = null;
        });
    }

    mapDataToProperties(entityData) {
        Object.keys(entityData).forEach((property) => {
            if (this[property] !== undefined) {
                this[property] = entityData[property];
            }
        });

        return this;
    }

    getPersistableEntity(mutableEntity, properties) {
        const persistableEntity = {};
        if (mutableEntity[this.getDefinitions()['primaryKey']]) {
            persistableEntity[this.getDefinitions()['primaryKey']] = mutableEntity[this.getDefinitions()['primaryKey']];
        }
        Object.keys(properties).forEach((property) => {
            persistableEntity[property] = mutableEntity[property];
        });
        return persistableEntity;
    }

    getPropertiesFilters() {
        let propertiesFilters = [];
        const propertygetDefinitions = this.getDefinitions().properties;
        Object.keys(propertygetDefinitions).forEach((property) => {
            if (propertygetDefinitions[property].filter) {
                propertiesFilters = [...propertiesFilters, { [property]: propertygetDefinitions[property].filter}]
            }
        });
        return propertiesFilters;
    }
}
