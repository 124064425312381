import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles } from '@material-ui/core/styles';
import { Breadcrumbs, Box } from '@material-ui/core';
import NavigateNext from '@material-ui/icons/NavigateNext';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { connect } from 'react-redux';
import routesDecorators, { getDecoratorForAlias } from './config/routesDecorators';
import { selectSideMenuItem } from '../actions/sideMenuBarActions';

const useStyles = theme => ({
    link: {
        display: 'flex',
        textDecoration: 'none',
        color: theme.palette.primary.light,
    },
    activeLink: {
        color: theme.palette.primary.dark
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,

        // [theme.breakpoints.down('sm')]: {
        //     marginRight: theme.spacing(0.25),
        //     width: 14,
        //     height: 14,
        // }
    },
});

class NavHeader extends Component {
    render() {
        const { 
            theme,
            classes,
            pathname,
            selectSideMenuItem,
        } = this.props;

        let pathnameAliases = pathname.split('/').filter(pathnameChunk => routesDecorators.some(routesDecorator => routesDecorator.alias === pathnameChunk));
        pathnameAliases = ['home', ...pathnameAliases];

        return (
            <Fragment>
                <Box style={{ paddingLeft: theme.spacing(1) }}>
                    <Breadcrumbs separator={<NavigateNext fontSize='small' />}>
                        {pathnameAliases.map((pathnameAlias, i) => {
                            const pathnameAliasDecorator = getDecoratorForAlias(pathnameAlias, pathname);
                            if (pathnameAliasDecorator) {
                                const classNames = [classes.link];
                                /* Set last link active */
                                if (i === pathnameAliases.length - 1) classNames.push(classes.activeLink);
                                return (
                                    <NavLink 
                                        onClick={() => selectSideMenuItem(null)}
                                        to={pathnameAliasDecorator.route} className={clsx(classNames)} key={'navHeaderLink' + i}>
                                        {pathnameAliasDecorator.icon !== null && React.cloneElement(pathnameAliasDecorator.icon, {className: classes.icon}) }
                                        {pathnameAliasDecorator.label}
                                    </NavLink>
                                )
                            } return null;
                        })}
                    </Breadcrumbs>
                </Box>
                <hr style={{ width: '100%', height: '1px', border: '0px', background: theme.palette.secondary.main }}/>
            </Fragment>
        )
    }
}
const mapStateToProps = state => ({
    pathname: state.router.location.pathname,
    search: state.router.location.search,
    hash: state.router.location.hash,
})

export default connect(mapStateToProps, { 
    selectSideMenuItem,
})(withTheme(withStyles(useStyles)(NavHeader)));


NavHeader.propTypes = {
    theme: PropTypes.object,
    classes: PropTypes.object,
    pathname: PropTypes.string,
};
