import { 
    CRUDL_ESCORT_SET_EDIT_MODE, 
    CRUDL_ESCORT_SET_MODIFIED, 
    CRUDL_ESCORT_CANCEL, 
    CRUDL_ESCORT_SAVE,
    CRUDL_ESCORT_UPDATE_SELECTED_ENTITY,
    CRUDL_ESCORT_CLEAR_UPDATED_SELECTED_ENTITY_WARNINGS,
    CRUDL_LIST_SET_FILTER_APPLIED,
    CRUDL_LIST_SET_FILTER_CHANGED,
} from '../actions/types';

const initialState = {
    editMode: false,
    modified: false,
    cancelled: false,
    saveState: null,
    savedEntity: null,
    updatedSelectedEntity: null,
    updatedSelectedEntityWarnings: null,
    listFilterApplied: false,
    listFilterChanged: false,
};

const CRUDLEscortComponentReducer = (state = initialState, action) => {
    switch (action.type) {
        case CRUDL_ESCORT_SET_EDIT_MODE:
            return {
                ...state,
                editMode: action.payload.editMode,
                cancelled: false,
            }
        case CRUDL_ESCORT_SET_MODIFIED:
            return {
                ...state,
                modified: action.payload.modified,
            }
            case CRUDL_ESCORT_CANCEL:
                return {
                    ...state,
                    cancelled: true,
                    saveState: null,
                }
            case CRUDL_ESCORT_SAVE:
                return {
                    ...state,
                    saveState:  action.payload.saveState,
                    savedEntity: action.payload.savedEntity,
                }
            case CRUDL_ESCORT_UPDATE_SELECTED_ENTITY:
                return {
                    ...state,
                    updatedSelectedEntity:  action.payload.updatedSelectedEntity,
                    updatedSelectedEntityWarnings: Boolean(action.payload.updatedSelectedEntity) ? action.payload.updatedSelectedEntityWarnings : null,
                }
            case CRUDL_ESCORT_CLEAR_UPDATED_SELECTED_ENTITY_WARNINGS:
                return {
                    ...state,
                    updatedSelectedEntityWarnings: null,
                }
            case CRUDL_LIST_SET_FILTER_APPLIED:
                return {
                    ...state,
                    listFilterApplied: action.payload.listFilterApplied,
                }
            case CRUDL_LIST_SET_FILTER_CHANGED:
                return {
                    ...state,
                    listFilterChanged: action.payload.listFilterChanged,
                }
        default:
            return state;
    }
}

export default CRUDLEscortComponentReducer;