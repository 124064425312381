import React, { Fragment } from 'react';
import CRUDLEntityComponent from '../base/crudl/CRUDLEntityComponent';
import NetworkDevice from '../../entities/NetworkDevice';
import CRUDLComponent from '../base/crudl/CRUDLComponent';

class NetworkDevices extends CRUDLEntityComponent {
    constructor(props) {
        const EntityModel = new NetworkDevice(true);

        super(props, EntityModel);

        this.setDefaultListFilter({
            page: 0,
        });
    }

    render() {
        return (
            <Fragment>
                <CRUDLComponent 
                    Model={this.EntityModel} 
                    CRUDLEntityComponentImpl={this} 
                    tableToolset={false}
                    tableCheckColumn={false}/>
            </Fragment>
        )
    }
}

export default NetworkDevices;