import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect as reduxConnect } from 'react-redux';
import { setEditMode, setModified, setSaveState, setUpdatedSelectedEntity } from '../../../actions/CRUDLEscortComponentActions';
import { requestAPI } from '../../../actions/apiRequestActions';
import { defaultAPIClient } from '../../../api-client/config';


class CRUDLEscortComponent extends Component {

    state = {
        mutableEntity: null,
    }

    constructor(props) {
        super(props);

        this.notSavedNavigationPrompt = false;
        this.successfullySavedSnackBarOpened = false;

        this.processSaveResponseImpl = props.processSaveResponse || this._processSaveResponse;
    }

    _isMounted = false;

    componentDidMount() {
        this._isMounted = true;

        const {
            selectedEntity,
            setEditMode,
            setModified,
        } = this.props;

        if (selectedEntity) this.setState({ mutableEntity: selectedEntity });

        setEditMode(false);
        setModified(false);
    }

    componentWillUnmount() {
        this._isMounted = false;

        const {
            setModified,
        } = this.props;

        setModified(false);
    }

    setStateIfMounted(setStateObj) {
        if (this._isMounted) {
            this.setState(setStateObj);
        }
    }

    componentDidUpdate() {
        const {
            Model,
            selectedEntity,
            propertiesDefinition,
            saveAPIEndpoint = 'save',

            cancelled,
            editMode,
            saveState,
            savedEntity,

            authenticationVerification,
            /**
             * Actions
             */
            requestAPI,
            setEditMode,
            setSaveState,
            setModified,
            setUpdatedSelectedEntity,
        } = this.props;

        const {
            mutableEntity,
        } = this.state;

        /**
         * 
         * Cancel editing
         * 
         */
        if (cancelled && editMode) {
            this.setStateIfMounted({ mutableEntity: selectedEntity });
            setEditMode(false);
            this.notSavedNavigationPrompt = false;
            return;
        }

        switch (saveState) {
            case 'initiated': {
                const persistableEntity = Model.getPersistableEntity(mutableEntity, Model.getDefinitions()[propertiesDefinition]);

                /**
                 * 
                 * Do API request here to save Entity to this.props.saveAPIEndpoint
                 * 
                 */
                requestAPI({
                    apiClient: defaultAPIClient,
                    requestParams: {
                        url: Model.getDefinitions().endpoints[saveAPIEndpoint].path,
                        method: Model.getDefinitions().endpoints[saveAPIEndpoint].method ? Model.getDefinitions().endpoints[saveAPIEndpoint].method : 'PUT',
                        data: persistableEntity,
                    },
                    requestAlias: Model.getDefinitions().endpoints[saveAPIEndpoint].alias,
                    responseCallBack: this.processSaveResponseImpl,
                    responseCallBackOnResponseError: true,
                });

                setSaveState('saving');
                break;
            }
            case 'saving':
                break;
            case 'saved': {
                let updatedMutableEntity = Object.assign(mutableEntity, savedEntity || {});

                /**
                 * Handle non-saveable properties
                 */
                let propertiesNotSaved = [];
                Object.keys(selectedEntity).forEach(property => {
                    if (mutableEntity[property] !== selectedEntity[property]) {
                        if (savedEntity[property] === undefined && property !== 'UIUUID') {
                            updatedMutableEntity = { ...updatedMutableEntity, [property]: selectedEntity[property] } ;
                            propertiesNotSaved.push(property);
                            this.setStateIfMounted({ mutableEntity: {...mutableEntity, [property]: selectedEntity[property]} });
                        }
                    }
                });

                setUpdatedSelectedEntity(updatedMutableEntity, { propertiesNotSaved });

                this.successfullySavedSnackBarOpened = true;
                this.notSavedNavigationPrompt = false;

                setEditMode(false);
                setSaveState(null);
                setModified(false);
                break;
            }
            case 'saved-created':
                // let updatedMutableEntity = mutableEntity;
                // Object.keys(Model).forEach(property => {
                //     updatedMutableEntity = { ...updatedMutableEntity, [property]: savedEntity[property] ? savedEntity[property] : mutableEntity[property] }
                // });

                this.successfullySavedSnackBarOpened = true;
                this.notSavedNavigationPrompt = false;

                setEditMode(false);
                setSaveState(null);
                setModified(false);

                let createdEntity = {
                    [Model.getDefinitions().primaryKey]: savedEntity[Model.getDefinitions().primaryKey],
                    UIUUID: 'JUST_CREATED_ENTITY',
                }

                setUpdatedSelectedEntity(createdEntity);
                break;
            default:
                break;
        }

        this.updateMutableEntity();

        if (saveState !== null && authenticationVerification) {
            setSaveState('saving');
        }
    }

    updateMutableEntity() {
        const {
            selectedEntity,
            /**
             * Actions
             */
            setModified,
        } = this.props;

        const {
            mutableEntity,
        } = this.state;

        /**
         * Updating mutableEntity
         */
        if (Boolean(selectedEntity)
            && Boolean(mutableEntity)
            && selectedEntity.UIUUID !== undefined) {

            let modified = false;
            for (let property in selectedEntity) {
                /**
                 * Compare mutable and selectedEnity (immutable) values for each property
                 * if any one has changed - set to modified state
                 */
                if (mutableEntity[property] !== selectedEntity[property] && property !== 'UIUUID') {

                    modified = true;
                    break;
                }
            }
            /**
             * 
             * Set promt, preventing navigation
             * 
             */
            this.notSavedNavigationPrompt = modified;

            setModified(modified);
        }
    }

    _processSaveResponse = () => {
        console.error('processSaveResponse is not implemented for CRUDLEscortComponent', this);
    }

    handleMutableEntityPropertyChanged(propertyObj) {
        const {
            mutableEntity,
        } = this.state;
        const {
            property,
            value,
        } = propertyObj;

        this.setState({ mutableEntity: {...mutableEntity, [property]: value} });
    }

    render() {
        return (
            <div>
                CRUDLEscortComponent
            </div>
        )
    }
}


const mapStateToProps = state => ({
    apiRequestId:           state.apiRequestReducer.apiRequestId,
    apiRequestAlias:        state.apiRequestReducer.apiRequestAlias,
    apiRequestResult:       state.apiRequestReducer.apiRequestResult,
    apiIsRequested:         state.apiRequestReducer.apiIsRequested,
    authenticationVerification:     state.authReducer.authenticationVerification,
    editMode:               state.CRUDLEscortComponentReducer.editMode,
    modified:               state.CRUDLEscortComponentReducer.modified,
    cancelled:              state.CRUDLEscortComponentReducer.cancelled,
    saveState:              state.CRUDLEscortComponentReducer.saveState,
    savedEntity:            state.CRUDLEscortComponentReducer.savedEntity,
});

export function connect(Component){
    return reduxConnect(mapStateToProps, { 
        /** Actions */
        requestAPI,
        setEditMode,
        setModified,
        setSaveState,
        setUpdatedSelectedEntity,
    })(Component);
}

export default CRUDLEscortComponent;


CRUDLEscortComponent.propTypes = {
    requestAPI: PropTypes.func,
    processSaveResponse: PropTypes.func,
    setEditMode: PropTypes.func,
    setModified: PropTypes.func,
    setSaveState: PropTypes.func,
    setUpdatedSelectedEntity: PropTypes.func,

    Model: PropTypes.object,
    selectedEntity: PropTypes.object,
    savedEntity: PropTypes.object,
    propertiesDefinition: PropTypes.string,
    saveAPIEndpoint: PropTypes.string,
    cancelled: PropTypes.bool,
    editMode: PropTypes.bool,
    saveState: PropTypes.string,
    authenticationVerification: PropTypes.bool,
}
