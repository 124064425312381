import React, { Fragment } from 'react';
import { withStyles, Grid, Box, TextField, Typography, Snackbar, withTheme } from '@material-ui/core';
import CRUDLEscortComponent, { connect } from '../../base/crudl/CRUDLEscortComponent';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
import clsx from 'clsx';
import { Prompt } from 'react-router-dom';
import { Alert } from '@material-ui/lab';
import WarningIcon from '@material-ui/icons/Warning';
import TimezonePicker from 'react-timezone';


const useStyles = theme => ({
    root: {
        maxWidth: '99vw',
    },
    entityForm: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    valueGridItem: {
        minWidth: '300px',
        maxWidth: '600px',
    },
    valueLeftPadding: {
        paddingLeft: '2px'
    },
    proppertyDiplayContainer: {
        padding: '5px',
    },
    proppertyLabel: {
        maxWidth: '50vw',
        margin: 'auto',
        textAlign: 'right',
        paddingRight: '4px',

        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
            paddingRight: 0,
        }
    },
    inputField: {
        backgroundColor: '#ffffff',
    },
    inputFieldReadOnly: {
        backgroundColor: '#efefef',
    }
});

class CRUDLEscortFlatEntityFormComponent extends CRUDLEscortComponent {

    render() {
        const {
            /**
             * Higher order component props
             */
            Model,
            propertiesDefinition = 'properties',
            labelsOrientation = 'column',
            minLabelsColumnWidth,
            showLabeledOnly = false,
            validationErrors,
            updateWarnings,

            classes,
            theme,
            /* Redux */
            editMode,
            saveState,
        } = this.props;

        const {
            mutableEntity,
        } = this.state;

        if (!mutableEntity) return null;

        return (
            <Fragment>

                <Prompt when={this.notSavedNavigationPrompt} message={'You haven\'t saved your changes.\n\nAre you sure you want to proceed?'}/>

                {/**
                 * 
                 * Successfully saveState 'saved' from Escort
                 * 
                 */}
                <Snackbar 
                    open={this.successfullySavedSnackBarOpened} 
                    autoHideDuration={1500}
                    onClose={() => { this.successfullySavedSnackBarOpened = false; this.setState({}); }}
                    anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                >
                    <Alert severity='success' variant='filled'>Saved successfully!</Alert>
                </Snackbar>



                <Box className={classes.entityForm}>
                    {/**
                     * 
                     * Entity properties
                     * 
                     */}
                    <Grid container spacing={2} direction='column' className={classes.root}>
                        {Object.keys(Model.getDefinitions()[propertiesDefinition]).map((property, index) => {
                            const hidden = Model.getDefinitions()[propertiesDefinition][property].hidden;
                            if (hidden !== true) {
                                if (!showLabeledOnly || (Model.getDefinitions().decorators.labels[property] && showLabeledOnly)) {

                                    /**
                                     * 
                                     * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input
                                     * 
                                     */
                                    let type = 'text';
                                    let nonStandardEditComponent = null;
                                    /** date */
                                    const format = Model.getDefinitions()[propertiesDefinition][property].format;
                                    // console.log(property, type, format);
                                    switch (format) {
                                        case 'date': 
                                                type = 'datetime-local';
                                            break;
                                        case 'date-time': 
                                            type = 'datetime-local';
                                            if (editMode  && saveState == null) {
                                                nonStandardEditComponent = 
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    {/**
                                                     * 
                                                     * https://material-ui-pickers.dev/api/KeyboardDatePicker
                                                     * 
                                                     */}
                                                    <KeyboardDatePicker
                                                        value={mutableEntity[property] || ''}
                                                        error={(validationErrors && validationErrors[property] !== undefined)}
                                                        onChange={(date) => { 
                                                            this.handleMutableEntityPropertyChanged({
                                                                property,
                                                                value: date
                                                            });
                                                        }}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                        fullWidth
                                                        inputVariant='outlined'
                                                        autoOk={true}
                                                        variant='inline'
                                                        format='yyyy-MM-dd HH:mm:ss'
                                                        margin='none'
                                                        size='small'
                                                        className={classes.inputField}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            }
                                            break;
                                            case 'timezone': 
                                                if (editMode  && saveState == null) {
                                                    nonStandardEditComponent = 
                                                    <TimezonePicker
                                                        value={mutableEntity[property]}
                                                        onChange={timezone => {
                                                            this.handleMutableEntityPropertyChanged({
                                                                property,
                                                                value: timezone
                                                            });
                                                        }}
                                                        inputProps={{
                                                            placeholder: 'Select Timezone...',
                                                            name: 'timezone',
                                                        }}
                                                        className={classes.inputField}
                                                        style={{
                                                            width: "100%",
                                                            fontSize: "14px",
                                                            border: "solid 1px #c4c4c4",
                                                            borderRadius: "4px",
                                                            position: "relative"
                                                        }}
                                                    />
                                                }
                                            break;
                                        default: type = 'text';
                                    }

                                    /**
                                     * 
                                     * Render appropriate UI element 
                                     * for a given Entity property type
                                     * 
                                     */
                                    return (
                                        <Grid item key={'property' + index}>
                                            <Grid container 
                                                direction={labelsOrientation} 
                                                alignItems={labelsOrientation === 'row' ? 'center' : null}
                                            >
                                                <Grid item
                                                    style={
                                                        labelsOrientation === 'row' ? { minWidth: (minLabelsColumnWidth ? minLabelsColumnWidth : '120px') } : null
                                                    }
                                                >
                                                    {/**
                                                     * 
                                                     * Property label 
                                                     * 
                                                     * */}
                                                    <Typography variant='body2'  className={classes.proppertyLabel}><b>{
                                                        Model.getDefinitions().decorators.labels[property] 
                                                        ? Model.getDefinitions().decorators.labels[property]
                                                        : property
                                                    }:</b></Typography>
                                                    {/**
                                                     * 
                                                     * Validation errors (coming from API)
                                                     * 
                                                     * */}
                                                    {(validationErrors && validationErrors[property] !== null) && 
                                                        <Typography variant='caption' color='error'>
                                                            {validationErrors[property]}
                                                        </Typography>
                                                    }
                                                </Grid>
                                                <Grid item className={clsx(classes.valueLeftPadding, classes.valueGridItem, classes.grow)}>
                                                    
                                                        {Boolean(Model.getDefinitions()[propertiesDefinition][property]['key']) && Model.getDefinitions()[propertiesDefinition][property]['key'] === 'show' 
                                                        ?
                                                            <Fragment>
                                                                <Typography variant='body2'>
                                                                    {mutableEntity[property] || ''}
                                                                </Typography>
                                                            </Fragment>
                                                        : !(Model.getDefinitions()[propertiesDefinition][property]['key']) && 
                                                            <Fragment>
                                                                <Fragment>
                                                                    {nonStandardEditComponent 
                                                                        ? nonStandardEditComponent 
                                                                        : <TextField 
                                                                            value={mutableEntity[property] || ''} 
                                                                            error={(validationErrors && validationErrors[property] !== undefined)}
                                                                            type={type}
                                                                            InputProps={{
                                                                                readOnly: !editMode || saveState === 'saving' || Model.getDefinitions()[propertiesDefinition][property]['readonly'] === true,
                                                                            }}
                                                                            onChange={(event) => {
                                                                                this.handleMutableEntityPropertyChanged({
                                                                                    property,
                                                                                    value: event.target.value
                                                                                });
                                                                            }}
                                                                            size='small'
                                                                            fullWidth 
                                                                            variant='outlined'
                                                                            className={editMode && Model.getDefinitions()[propertiesDefinition][property]['readonly'] !== true ? classes.inputField : classes.inputFieldReadOnly}
                                                                        />
                                                                    }
                                                                </Fragment>
                                                            </Fragment>
                                                        }

                                                        {/** 
                                                         * 
                                                         * Warnings happend while update 
                                                         * 
                                                         * */}
                                                        {(updateWarnings 
                                                            && updateWarnings.propertiesNotSaved 
                                                            && updateWarnings.propertiesNotSaved.find(pw => pw === property)) &&
                                                            <Grid container direction='row'>
                                                                <Grid item>
                                                                    <WarningIcon style={{ fontSize: 16, margin: 2, color: theme.palette.warning.light }}/>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography variant='caption'>
                                                                        <b>{Model.getDefinitions().decorators.labels[property] 
                                                                            ? Model.getDefinitions().decorators.labels[property] 
                                                                            : property}</b> could not be updated from this form
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        }

                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    );
                                } else return null;
                            } else return null;
                        })}

                    </Grid>
                </Box>
            </Fragment>
        )
    }
}

export default connect((withTheme(withStyles(useStyles)(CRUDLEscortFlatEntityFormComponent))));
