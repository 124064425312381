import React, { Fragment } from 'react';
import CRUDLComponent from '../base/crudl/CRUDLComponent';
import User from '../../entities/User';
import CRUDLEntityComponent from '../base/crudl/CRUDLEntityComponent';

class Users extends CRUDLEntityComponent {
    constructor(props) {
        const EntityModel = new User(true);

        super(props, EntityModel);

        this.setDefaultListFilter({
            page: 0,
        });
    }

    render() {
        return (
            <Fragment>
                <CRUDLComponent Model={this.EntityModel} CRUDLEntityComponentImpl={this} />
            </Fragment>
        )
    }
}

export default Users;