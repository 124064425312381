import { EXCEPTION_FIRE, EXCEPTION_EXTINGUISH } from './types';

export const fireException = (exceptionType, exceptionMessage) => dispatch => {
    dispatch({
        type: EXCEPTION_FIRE,
        payload: {
            exceptionType,
            exceptionMessage,
        }
    });
}

export const extinguishException = () => dispatch => {
    dispatch({
        type: EXCEPTION_EXTINGUISH,
        payload: {}
    });
}