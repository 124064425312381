import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { defaultAPIClient } from '../../../api-client/config';
import { requestAPI } from '../../../actions/apiRequestActions';
import { cancel } from '../../../actions/CRUDLEscortComponentActions';
import PropTypes from 'prop-types';
import { Paper, withTheme, withStyles, Box, Typography, IconButton } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import ConfirmationDialogComponent from '../../common/ConfirmationDialogComponent';

const useStyles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'baseline',
        padding: theme.spacing(1), 
        backgroundColor: theme.palette.secondary.light,
    },
    grow: {
        flexGrow: 1,
    },
});

class CRUDLTableGroupActionsComponent extends Component {

    state = {
        confirmationDialogOpened: false,
        confirmationDialogMessage: null,
        confirmationDialogProceedHandler: null,
    }

    componentDidUpdate() {
    }

    deleteSelectedEntities = (forcedly = false) => {

        const {
            selectedEntities,
            Model,
        } = this.props;

        /**
         * 
         * Delete ONLY 1
         * 
         */
        const entityToDeleId = Object.keys(selectedEntities)[0];

        /**
         * 
         * Do API request here to get single Entity
         * 
         */
        this.props.requestAPI({
            apiClient: defaultAPIClient,
            requestParams: {
                url: Model.getDefinitions().endpoints.delete.path.replace('{entityId}', entityToDeleId),
                method: Model.getDefinitions().endpoints.delete.method,
            },
            requestAlias: Model.getDefinitions().endpoints.delete.alias,
            responseCallBack: (response) => { 
                console.log('DELETED', response);
            },
        });

        this.handleConfirmationDialogComponentClose();

    }

    handleConfirmationDialogComponentClose = () => {
        this.setState({ 
            confirmationDialogOpened: false, 
            confirmationDialogMessage: null,
        });
    }

    render() {

        const {
            classes,
            selectedEntities,
            Model,
            cancel,
            modified,
        } = this.props;

        return (
            <Fragment>

                <ConfirmationDialogComponent
                    opened={this.state.confirmationDialogOpened}
                    onCloseHandler={this.handleConfirmationDialogComponentClose}
                    cancelHandler={this.handleConfirmationDialogComponentClose}
                    message={this.state.confirmationDialogMessage}
                    proceedHandler={this.state.confirmationDialogProceedHandler}
                />

                {Object.entries(selectedEntities).length > 0 &&
                    <Fragment>
                        <Paper  className={classes.root}>
                            <Typography variant='subtitle1' style={{ margin: 'auto' }}>
                                <b>{Object.entries(selectedEntities).length}</b> selected
                            </Typography>
                            <div className={classes.grow} />
                            <Box>
                                {/* <IconButton aria-label='mail' color='secondary'>
                                    <MailOutlineIcon />
                                </IconButton>
                                <IconButton aria-label='notify' color='secondary'>
                                    <NotificationsNoneIcon />
                                </IconButton> */}

                                {/**
                                 * 
                                 * Delete action 
                                 * 
                                 * */}
                                <IconButton 
                                    disabled={
                                        !Model.getDefinitions().endpoints.delete.allowMultipleDeletion && Object.entries(selectedEntities).length > 1
                                    }
                                    onClick={() => {

                                        if (modified) {
                                            this.setState({ 
                                                confirmationDialogOpened: true, 
                                                confirmationDialogMessage: 'You haven\'t saved your changes.',
                                                confirmationDialogProceedHandler: () => {
                                                    cancel();
                                                    this.setState({ 
                                                        confirmationDialogOpened: true, 
                                                        confirmationDialogMessage: <Fragment>You are about to <b>delete {Object.entries(selectedEntities).length} selected {Model.getDefinitions().decorators.singleHeading}</b></Fragment>,
                                                        confirmationDialogProceedHandler: this.deleteSelectedEntities,
                                                    });
                                                },
                                            });
                                        } else {
                                            this.setState({ 
                                                confirmationDialogOpened: true, 
                                                confirmationDialogMessage: <Fragment>You are about to <b>delete {Object.entries(selectedEntities).length} selected {Model.getDefinitions().decorators.singleHeading}</b></Fragment>,
                                                confirmationDialogProceedHandler: this.deleteSelectedEntities,
                                            });
                                        }

                                    }}
                                    aria-label='delete' 
                                    color='secondary'>
                                    <DeleteOutlineIcon />
                                </IconButton>

                            </Box>
                        </Paper>
                        <br/>
                    </Fragment>
                }
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    modified:              state.CRUDLEscortComponentReducer.modified,
});

export default connect(mapStateToProps, {
    cancel,
    requestAPI,
})(withTheme(withStyles(useStyles)(CRUDLTableGroupActionsComponent)));


CRUDLTableGroupActionsComponent.propTypes = {
    cancel: PropTypes.func,
    requestAPI: PropTypes.func,

    classes: PropTypes.object,
    selectedEntities: PropTypes.object,
    modified:  PropTypes.bool,
}