import React, { Component } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import store from './config/store';
import AppRoot from './AppRoot';
import { history } from './config/store';
import ErrorBoundary from './base/ErrorBoundary';

import '../styles/common.css';

/**
 * Default Theme
 * https://material-ui.com/customization/default-theme/
 */
const themeDefault = createMuiTheme({
    props: {
    MuiToolbar: {
        variant: 'dense',
      },
    },
    palette: {
        primary: {
            main: '#394e57',
            light: '#729bb8',
            dark: '#202c33',
            contrastText: '#ffffff'
        },
        secondary: {
            main: '#005F8E',
            light: '#e2f1f8',
            dark: '#808e95',
            contrastText: '#000000'
        },
    }
});

class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <ThemeProvider theme={themeDefault}>
                        <ErrorBoundary>
                            <AppRoot/>
                        </ErrorBoundary>
                    </ThemeProvider>
                </ConnectedRouter>
            </Provider>
        )
    }
}

export default App;