import React, { Fragment } from 'react';
import CRUDLEntityComponent from '../base/crudl/CRUDLEntityComponent';
import AccessLog from '../../entities/AccessLog';
import CRUDLComponent from '../base/crudl/CRUDLComponent';

class AccessLogView extends CRUDLEntityComponent {
    constructor(props) {
        const EntityModel = new AccessLog(true);

        super(props, EntityModel);

        this.setDefaultListFilter({
            page: 0,
        });
    }

    render() {
        return (
            <Fragment>
                <CRUDLComponent 
                    Model={this.EntityModel} 
                    CRUDLEntityComponentImpl={this} 
                    tableToolsetSettings={{
                        createButton: false,
                        toolsetComponentStyle: {
                            maxWidth: "400px"
                        }
                    }}
                    tableCheckColumn={false}/>
            </Fragment>
        )
    }
}

export default AccessLogView;