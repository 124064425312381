import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

//The Roboto font will not be automatically loaded by Material-UI. The developer is responsible for loading all fonts used in their application.
//https://material-ui.com/components/typography/
import 'typeface-roboto';

import App from './components/App';

console.log(process.env.REACT_APP_NAME);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
