/*exceptionActions*/
export const EXCEPTION_FIRE                  = 'EXCEPTION_FIRE';
export const EXCEPTION_EXTINGUISH            = 'EXCEPTION_EXTINGUISH';
/*warningActions*/
export const WARNING_FIRE                    = 'WARNING_FIRE';
export const WARNING_EXTINGUISH              = 'WARNING_EXTINGUISH';
/*infoActions*/
export const INFO_FIRE                      = 'INFO_FIRE';
export const INFO_EXTINGUISH                = 'INFO_EXTINGUISH';
/*APIClient actions*/
export const API_REQUEST                    = 'API_REQUEST';
export const API_RESPONSE                   = 'API_RESPONSE';
export const API_ERROR                      = 'API_ERROR';
/*sideMenuBarActions*/
export const SIDE_MENU_TOGGLE_BAR           = 'SIDE_MENU_TOGGLE_BAR';
export const SIDE_MENU_COLLAPSE_BAR         = 'SIDE_MENU_COLLAPSE_BAR';
export const SIDE_MENU_SELECT_ITEM          = 'SIDE_MENU_SELECT_ITEM';
/*authActions*/
export const AUTH_VERIFY_AUTHENTICATION              = 'AUTH_VERIFY_AUTHENTICATION';
export const AUTH_AUTHENTICATION_TOKEN_EXPIRED       = 'AUTH_AUTHENTICATION_TOKEN_EXPIRED';
export const AUTH_GET_AUTHENTICATED                  = 'AUTH_GET_AUTHENTICATED';
export const AUTH_SET_AUTHENTICATED                  = 'AUTH_SET_AUTHENTICATED';
export const AUTH_SET_UNAUTHENTICATED                = 'AUTH_SET_UNAUTHENTICATED';
export const AUTH_AUTHENTICATION_FAILED              = 'AUTH_AUTHENTICATION_FAILED';
export const AUTH_REAUTHENTICATION                   = 'AUTH_REAUTHENTICATION';
export const AUTH_LOG_OUT                            = 'AUTH_LOG_OUT';
/*CRUDLEscortComponent*/
export const CRUDL_ESCORT_SET_EDIT_MODE                                 = 'CRUDL_ESCORT_SET_EDIT_MODE';
export const CRUDL_ESCORT_SET_MODIFIED                                  = 'CRUDL_ESCORT_SET_MODIFIED';
export const CRUDL_ESCORT_CANCEL                                        = 'CRUDL_ESCORT_CANCEL';
export const CRUDL_ESCORT_SAVE                                          = 'CRUDL_ESCORT_SAVE';
export const CRUDL_ESCORT_UPDATE_SELECTED_ENTITY                        = 'CRUDL_ESCORT_UPDATE_SELECTED_ENTITY';
export const CRUDL_ESCORT_CLEAR_UPDATED_SELECTED_ENTITY_WARNINGS        = 'CRUDL_ESCORT_CLEAR_UPDATED_SELECTED_ENTITY_WARNINGS';
export const CRUDL_LIST_SET_FILTER_APPLIED                              = 'CRUDL_LIST_SET_FILTER_APPLIED';
export const CRUDL_LIST_SET_FILTER_CHANGED                              = 'CRUDL_LIST_SET_FILTER_CHANGED';
