import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import sideMenuBarReducer from './sideMenuBarReducer';
import authReducer from './authReducer';
import apiRequestReducer from './apiRequestReducer';
import CRUDLEscortComponentReducer from './CRUDLEscortComponentReducer';
import exceptionReducer from './execptionReducer';
import warningReducer from './warningReducer';
import infoReducer from './infoReducer';

export const rootReducer = (history) => combineReducers({
    router: connectRouter(history),
    apiRequestReducer,
    sideMenuBarReducer,
    authReducer,
    CRUDLEscortComponentReducer,
    exceptionReducer,
    warningReducer,
    infoReducer,
});

export default rootReducer;