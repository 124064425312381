import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect as reduxConnect } from 'react-redux';
import { setEditMode, cancel, setSaveState } from '../../../actions/CRUDLEscortComponentActions';

class CRUDLEscortToolsetComponent extends Component {

    componentDidMount() {
        const {
            selectedEntity,
            setSaveState,
        } = this.props;

        this.Model = new selectedEntity.constructor(true);

        setSaveState(null);
    }

    render() {
        return (
            <div>
                ToolsetComponentInst
            </div>
        )
    }
}


const mapStateToProps = state => ({
    apiRequestId:           state.apiRequestReducer.apiRequestId,
    apiRequestAlias:        state.apiRequestReducer.apiRequestAlias,
    apiRequestResult:       state.apiRequestReducer.apiRequestResult,
    apiIsRequested:         state.apiRequestReducer.apiIsRequested,
    editMode:               state.CRUDLEscortComponentReducer.editMode,
    modified:               state.CRUDLEscortComponentReducer.modified,
    saveState:              state.CRUDLEscortComponentReducer.saveState,
});

export function connect(Component){
    return reduxConnect(mapStateToProps, { 
        /** Actions */
        setEditMode,
        cancel,
        setSaveState,
    })(Component);
}

export default CRUDLEscortToolsetComponent;

CRUDLEscortToolsetComponent.propTypes = {
    setEditMode: PropTypes.func,
    cancel: PropTypes.func,
    setSaveState: PropTypes.func,

    selectedEntity: PropTypes.object,
}

