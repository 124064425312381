import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Container, Card, CardContent, Typography, CardActions, Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = theme => ({
    root: {
        justifyContent: 'center',
    },
    card: {
        maxWidth: '600px',
        minWidth: '350px',
        position: 'absolute',
        left: '50%',
        top: '40%',
        transform: 'translate(-50%, -50%)',
        padding: theme.spacing(1),
    },
});

class RouteNoMatch extends Component {
    render() {
        const {
            classes,
        } = this.props;

        return (
            <Container maxWidth='sm' className={classes.root}>
                <Card className={classes.card}>
                    <CardContent>
                        <Typography align='center' variant='h5' gutterBottom>404</Typography>
                        <Typography align='center' variant='h6' color='error'>Resource not found.</Typography>
                    </CardContent>
                    <CardActions className={classes.root}>
                        <Button size='small' color='primary' variant='contained' component={RouterLink} to='/'>
                            Back to Home 
                        </Button>
                    </CardActions>
                </Card>
            </Container>
        )
    }
}

export default withStyles(useStyles)(RouteNoMatch);


RouteNoMatch.propTypes = {
    theme: PropTypes.object,
    classes: PropTypes.object,
}
