
import uniqid from 'uniqid';
import { 
    EXCEPTION_FIRE, 
    EXCEPTION_EXTINGUISH, 
    API_REQUEST, 
    API_RESPONSE, 
    API_ERROR, 
    AUTH_AUTHENTICATION_TOKEN_EXPIRED,
    AUTH_AUTHENTICATION_FAILED,
} from './types';
import * as HttpStatus from 'http-status-codes';

export const requestAPI = (params) => dispatch => {
    const {
        apiClient,
        requestParams,
        requestAlias,
        responseCallBack,
        responseCallBackOnResponseError = false,
        dispatch_API_RESPONSE = true,
        repeatRequestId = null,
        repeatIfRequestHasFailed = true,
    } = params;

    // console.log('>>>>>>>requestAPI params>>>>>>>>', params);

    const requestId = repeatRequestId ? repeatRequestId : uniqid();

    dispatch({
        type: API_REQUEST,
        payload: {
            requestId: requestId,
            alias: requestAlias,
            requesting: true,
        }
    });

    // console.log('>>>>>>>requestAPI requestParams>>>>>>>>', requestParams);

    const apiClientRequestPromise = new Promise((resolve) => {
        apiClient
        .processRequest(requestParams)
        .then(response => {
            dispatch({
                type: EXCEPTION_EXTINGUISH,
                payload: {}
            });
    
            /**
             * If responseCallBack defined then call responseCallBack
             */
            if (responseCallBack) {
                responseCallBack(response);
            }

            /**
             * Dispatch API_RESPONSE if required
             * default - dispatch
             */
            if (dispatch_API_RESPONSE === true) {
                dispatch({
                    type: API_RESPONSE,
                    payload: {
                        requestId: requestId,
                        response: response,
                        alias: requestAlias,
                    }
                });
            }
    
            dispatch({
                type: API_REQUEST,
                payload: {
                    requestId: requestId,
                    alias: requestAlias,
                    requesting: false,
                }
            });

            resolve(response);
        })
        .catch(error => {
            dispatch({
                type: EXCEPTION_EXTINGUISH,
                payload: {}
            });

            if (error.response) {
                /*
                 * The request was made and the server responded with a
                 * status code that falls out of the range of 2xx
                 */
                console.log('%c requestAPI error response [%s]\nerror.response [%o]', 'color: #fa6000', error.response.status, error.response);
                switch(error.response.status) {
                    case HttpStatus.BAD_REQUEST:
                        if (error.response.data) {
                            if (error.response.data.error_description && error.response.data.error_description.indexOf('Token has expired') > -1) {
                                dispatch({
                                    type: AUTH_AUTHENTICATION_TOKEN_EXPIRED,
                                    payload: {}
                                });
                                break;
                            }
                            if (error.response.data.error_description && error.response.data.error_description.indexOf('Bad credentials') > -1) {
                                dispatch({
                                    type: AUTH_AUTHENTICATION_FAILED,
                                    payload: {
                                        displayText: 'Invalid credentials provided',
                                    }
                                });
                                break;
                            }
                            if (error.response.data.error && error.response.data.error.indexOf('invalid_token') > -1) {
                                dispatch({
                                    type: AUTH_AUTHENTICATION_FAILED,
                                    payload: {
                                        displayText: 'Invalid authentication token provided',
                                    }
                                });
                                break;
                            }

                            // dispatch({
                            //     type: EXCEPTION_FIRE,
                            //     payload: {
                            //         exceptionType: 'api-response-unclassified',
                            //         exceptionMessage: error.response.data.message,
                            //     }
                            // });

                            dispatch({
                                type: API_ERROR,
                                payload: {
                                    requestId: requestId,
                                    error: error,
                                    alias: requestAlias,
                                }
                            });

                            break;

                        }
                    break;
                    case HttpStatus.UNAUTHORIZED:
                            if (error.response.data && error.response.data.error_description && error.response.data.error_description.indexOf('Access token expired') > -1) {
    
                                apiClient.addPostponedRequestAPICallParams({...params, repeatRequestId: requestId});
    
                                dispatch({
                                    type: AUTH_AUTHENTICATION_TOKEN_EXPIRED,
                                    payload: {},
                                });
                            }
                        break;
                    case HttpStatus.NOT_FOUND:
                        dispatch({
                            type: EXCEPTION_FIRE,
                            payload: {
                                exceptionType: 'api-response-resource-not-found',
                                exceptionMessage: 'Resource Not Found',
                            }
                        });
                        break;
                    case HttpStatus.INTERNAL_SERVER_ERROR:
                        dispatch({
                            type: EXCEPTION_FIRE,
                            payload: {
                                exceptionType: 'api-response-internal-server-error',
                                exceptionMessage: 'Internal Server Error',
                            }
                        });
                        break;
                    case HttpStatus.UNSUPPORTED_MEDIA_TYPE:
                        dispatch({
                            type: EXCEPTION_FIRE,
                            payload: {
                                exceptionType: 'api-response-unsupported-media-type-error',
                                exceptionMessage: 'Media Type Not Supported by Server',
                            }
                        });
                        break;
                    default:
                        dispatch({
                            type: API_ERROR,
                            payload: {
                                requestId: requestId,
                                error: error,
                                alias: requestAlias,
                            }
                        });
                        break;
                }

                if (responseCallBackOnResponseError) {
                    responseCallBack(error.response);
                }

            } else if (error.request) {
                /*
                 * The request was made but no response was received, `error.request`
                 * is an instance of XMLHttpRequest in the browser
                 */
                console.log(error.request);
    
                /**
                 * 
                 * Repeat unless explicitly stated otherwise
                 * 
                 */
                if (repeatIfRequestHasFailed === true) apiClient.addPostponedRequestAPICallParams({...params, repeatRequestId: requestId});
    
                dispatch({
                    type: EXCEPTION_FIRE,
                    payload: {
                        exceptionType: 'api-request-error',
                        exceptionMessage: 'Service is temporarily unavailable',
                    }
                });
            } else {
                // Something happened in setting up the request and triggered an Error
                console.log('Error\n', error);

                dispatch({
                    type: EXCEPTION_FIRE,
                    payload: {
                        exceptionType: 'api-response-processing-error',
                        exceptionMessage: 'Error happend while processing server response',
                    }
                });

                dispatch({
                    type: API_ERROR,
                    payload: {
                        requestId: requestId,
                        error: error,
                        alias: requestAlias,
                    }
                });
            }

            resolve({error});
        });
    });

    return { requestId, apiClientRequestPromise };
}