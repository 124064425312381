import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CRUDLEscortAddNewEntityFormToolsetComponent from '../common/crudl/CRUDLEscortAddNewEntityFormToolsetComponent';
import { requestAPI } from '../../actions/apiRequestActions';
import { sideMenuBarMaxWidth, sideMenuBarMaxCollapsedWidth } from '../../ui-constants';
import { withStyles, withTheme, Box } from '@material-ui/core';
import clsx from 'clsx';
import CRUDLEscortFlatEntityFormComponent from '../common/crudl/CRUDLEscortFlatEntityFormComponent';
import { setSaveState, setEditMode, clearUpdatedSelectedEntityWarnings, cancel } from '../../actions/CRUDLEscortComponentActions';
import DateFnsUtils from '@date-io/date-fns';
import { Redirect } from 'react-router-dom';
import { API_ERRORS_IGNORINGS } from '../../api-client/config';

import User from '../../entities/User';
import { Alert } from '@material-ui/lab';


const useStyles = theme => ({
    formContainer: {
        position: 'absolute',
        top: theme.spacing(22),
        left: sideMenuBarMaxWidth,
        bottom: '0',
        right: '0',
        overflow: 'auto',
        display: 'flex',
        flexFlow: 'column',
        padding: theme.spacing(1),

        [theme.breakpoints.down('sm')]: {
            top: theme.spacing(20),
            left: '0',
        }
    },
    formContainerMenuCollapsed: {
        left: sideMenuBarMaxCollapsedWidth,

        [theme.breakpoints.down('sm')]: {
            left: '0',
        }
    }
});

class UserNew extends Component {
    state = {
        requestError: null,
        validationErrors: null,
        updateWarnings: null,
        successfullyCreatedId: null,
    }

    constructor(props) {
        super(props);

        /**
         * 
         * 
         * Common initialization steps
         * 
         */
        this.NewEntityModel = new User(true);
        this.propertiesDefinition = 'userCreationDTOProperties';
        this.saveAPIEndpoint = 'create';

        /**
         * 
         * 
         * Specific to this entity initialization
         * 
         */
// this.NewEntityModel.login = 'test';
// this.NewEntityModel.password = 'password';
// this.NewEntityModel.email = 'test@authvela.com';
// this.NewEntityModel.firstName = 'Test';
// this.NewEntityModel.lastName = 'User';


        let todaysDate = new Date();
        let expiresAt = todaysDate.setFullYear(todaysDate.getFullYear() + 1);

        const DateFnsUtilsInst = new DateFnsUtils();
        let expiresAtFormatted = DateFnsUtilsInst.format(expiresAt, 'yyyy-MM-dd')
        expiresAtFormatted += 'T' + DateFnsUtilsInst.format(expiresAt, 'HH:mm:ss');
        this.NewEntityModel['expire'] = expiresAtFormatted;
    }

    componentDidMount() {
        const {
            setEditMode,
        } = this.props;

        setEditMode(true);
    }

    componentWillUnmount() {
        const {
            setSaveState,
            cancel,
        } = this.props;

        cancel();
        setSaveState(null);
    }

    processSaveResponse = (response) => {
        const {
            setSaveState,
            setEditMode,
        } = this.props;
        if (response.data) {
            if(response.data.error) {
                if(response.data.errors) {
                    let validationErrors = {};
                    response.data.errors.forEach(error => {
                        const {
                            field,
                            defaultMessage,
                        } = error;
    
                        validationErrors[field] = defaultMessage;
                    });
    
                    this.setState({ validationErrors });
                }

                this.setState({ requestError: response.data.error });

                setSaveState('not-saved');
                setEditMode(true);
            } else {
                if(response.data.result && response.data.result.id) {
                    setSaveState('saved-created', response.data.result);
                    this.setState({
                        requestError: null,
                        validationErrors: null,
                        updateWarnings: null,
                        successfullyCreatedId: response.data.result.id,
                    });
                }
            }
        }
    }

    render() {

        const {
            classes,
            menuBarCollapsed,
        } = this.props;

        return (
                <Fragment>

                    {this.state.successfullyCreatedId && <Redirect to={ this.NewEntityModel.getDefinitions().UIRoutes.list } />}

                    <CRUDLEscortAddNewEntityFormToolsetComponent
                        Model={this.NewEntityModel} 
                        selectedEntity={this.NewEntityModel}
                    />
                    <hr style={{ height: '1px', width: '100%' }}/>
                    
                    <Box className={clsx(classes.formContainer, menuBarCollapsed ? classes.formContainerMenuCollapsed : null)}>
                        {this.state.requestError && API_ERRORS_IGNORINGS.indexOf(this.state.requestError) === -1 && 
                            <Fragment>
                                <Alert severity='error'>{this.state.requestError}</Alert>
                                <br/>
                            </Fragment>
                        }

                        <CRUDLEscortFlatEntityFormComponent
                            Model={this.NewEntityModel} 
                            selectedEntity={this.NewEntityModel}
                            propertiesDefinition={this.propertiesDefinition}
                            saveAPIEndpoint={this.saveAPIEndpoint}
                            processSaveResponse={this.processSaveResponse}
                            validationErrors={this.state.validationErrors}
                            updateWarnings={this.state.updateWarnings}
                            labelsOrientation='row'
                        />
                    </Box>
                </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    apiRequestId:           state.apiRequestReducer.apiRequestId,
    apiRequestResult:       state.apiRequestReducer.apiRequestResult,
    apiIsRequested:         state.apiRequestReducer.apiIsRequested,
    cancelled:              state.CRUDLEscortComponentReducer.cancelled,
    saveState:              state.CRUDLEscortComponentReducer.saveState,
    updatedSelectedEntity:  state.CRUDLEscortComponentReducer.updatedSelectedEntity,
    updatedSelectedEntityWarnings: state.CRUDLEscortComponentReducer.updatedSelectedEntityWarnings,
    menuBarCollapsed:       state.sideMenuBarReducer.menuBarCollapsed,
    responsiveSideMenuBarVisibility: state.sideMenuBarReducer.responsiveSideMenuBarVisibility,
});

export default connect(mapStateToProps, {
    requestAPI,
    setSaveState,
    setEditMode,
    cancel,
    clearUpdatedSelectedEntityWarnings,
})(withTheme(withStyles(useStyles)(UserNew)));


UserNew.propTypes ={
    requestAPI: PropTypes.func,
    setSaveState: PropTypes.func,
    setEditMode: PropTypes.func,
    cancel: PropTypes.func,
    clearUpdatedSelectedEntityWarnings: PropTypes.func,

    classes: PropTypes.object,
    match: PropTypes.object,
    cancelled: PropTypes.bool,
    menuBarCollapsed: PropTypes.bool,
    updatedSelectedEntity: PropTypes.object,
    updatedSelectedEntityWarnings: PropTypes.object,
}