import Entity from './Entity';

export default class Profile extends Entity {
    getDefinitions() {
        return {
            primaryKey: 'adminId',
            /**
             * Format(s) are refrenced with CRUDLEscortFlatEntityFormComponent
             */
            properties: {
                  "adminId": {
                    "type": "string",
                  },
                  "email": {
                    "type": "string",
                    "key": "show",
                  },
                  "firstName": {
                    "type": "string",
                  },
                  "lastName": {
                    "type": "string",
                  },
                  "organizationId": {
                    "type": "string",
                  },
                  "organizationName": {
                    "type": "string",
                  },
                  "timezone": {
                    "type": "string",
                    "format": "timezone"
                  },
            },
            endpoints: {
                getSingle: {
                  path: '/api/profile',
                  alias: 'getCurrentAdminProfile',
                },
                save: {
                  path: '/api/profile',
                  alias: 'updateProfile',
                },
            },
            UIRoutes: {
            },
            decorators: {
                singleHeading: 'Profile',
                labels: {
                    'email': 'Email',
                    'firstName': 'First Name',
                    'lastName': 'Last Name',
                    'organizationName': 'Organization Name',
                    'timezone': 'Timezone',
                },
            },
            escort: null
        }
    }

    getPersistableEntity(mutableEntity) {
      return mutableEntity;
    }
}