import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTheme, Typography } from '@material-ui/core';
import { Dialog, DialogContent, DialogActions, Button, Grid } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';


class ConfirmationDialogComponent extends Component {
    render() {
        const {
            opened,
            onCloseHandler,
            cancelHandler,
            proceedHandler,
            message,
            theme,
        } = this.props;
        return (
            <Dialog
                open={opened}
                onClose={onCloseHandler}
                fullWidth
            >
                <DialogContent>
                    <Grid container direction='row'>
                        <Grid item>
                            <WarningIcon style={{ color: theme.palette.warning.main }} fontSize='large'/>
                        </Grid>
                        <Grid item style={{ verticalAlign: 'middle' }}>
                            <Typography variant='h6'>
                                Warning
                            </Typography>
                        </Grid>
                    </Grid>
                    <Typography variant='body1'>
                        <br/>
                        {message}
                        <br/><br/>
                        Are you sure you want to proceed?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={proceedHandler} color="primary">
                        Proceed
                    </Button>
                    <Button onClick={cancelHandler} color="primary" autoFocus>
                        Cancel
                    </Button>
                </DialogActions>
        </Dialog>
        )
    }
}
export default withTheme(ConfirmationDialogComponent);


ConfirmationDialogComponent.propTypes = {
    onCloseHandler: PropTypes.func,
    cancelHandler: PropTypes.func,
    proceedHandler: PropTypes.func,

    opened: PropTypes.bool,
    theme: PropTypes.object,
}