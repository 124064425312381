import { 
    CRUDL_ESCORT_SET_EDIT_MODE, 
    CRUDL_ESCORT_SET_MODIFIED, 
    CRUDL_ESCORT_CANCEL, 
    CRUDL_ESCORT_SAVE, 
    CRUDL_ESCORT_UPDATE_SELECTED_ENTITY, 
    CRUDL_ESCORT_CLEAR_UPDATED_SELECTED_ENTITY_WARNINGS, 
    CRUDL_LIST_SET_FILTER_APPLIED,
    CRUDL_LIST_SET_FILTER_CHANGED,
} from './types';

export const setEditMode = (editMode = false) => dispatch => {
    dispatch({
        type: CRUDL_ESCORT_SET_EDIT_MODE,
        payload: {editMode}
    });
}

export const setModified = (modified = false) => dispatch => {
    dispatch({
        type: CRUDL_ESCORT_SET_MODIFIED,
        payload: {modified}
    });
}

export const cancel = () => dispatch => {
    dispatch({
        type: CRUDL_ESCORT_CANCEL,
        payload: {}
    });
}

export const setSaveState = (saveState, savedEntity = null) => dispatch => {
    dispatch({
        type: CRUDL_ESCORT_SAVE,
        payload: { 
            saveState,
            savedEntity,
        }
    });
}

export const setUpdatedSelectedEntity = (updatedSelectedEntity, updatedSelectedEntityWarnings) => dispatch => {
    dispatch({
        type: CRUDL_ESCORT_UPDATE_SELECTED_ENTITY,
        payload: { 
            updatedSelectedEntity,
            updatedSelectedEntityWarnings,
        }
    });
}

export const clearUpdatedSelectedEntityWarnings = () => dispatch => {
    dispatch({
        type: CRUDL_ESCORT_CLEAR_UPDATED_SELECTED_ENTITY_WARNINGS,
        payload: {}
    });
}

export const setListFilterApplied = (listFilterApplied = false) => dispatch => {
    dispatch({
        type: CRUDL_LIST_SET_FILTER_APPLIED,
        payload: {
            listFilterApplied,
        }
    });
}

export const setListFilterChanged = (listFilterChanged = false) => dispatch => {
    dispatch({
        type: CRUDL_LIST_SET_FILTER_CHANGED,
        payload: {
            listFilterChanged,
        }
    });
}
