import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container } from '@material-ui/core';

class ErrorBoundary extends Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true, error };
    }
  
    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
      console.log(error, errorInfo);
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return <Container><h2>Something went wrong.</h2><br/>Refresh the page, please.</Container>;
      } else {
        return this.props.children;
      }
    }
}

export default ErrorBoundary;


ErrorBoundary.propTypes = {
  children: PropTypes.object,
}