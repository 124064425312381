import React, { Component, Fragment, Suspense } from 'react';
import JSONPretty from 'react-json-pretty';

const ExpComponent = React.lazy(() => import('../components/splitted/ExpComponent'));

class Dummy extends Component {

    render() {
        return (
            <Fragment>
                <Suspense fallback={<div>Loading ExpComponent...</div>}>
                    <ExpComponent />
                </Suspense>

                <JSONPretty id='json-pretty' data={this.props}></JSONPretty>
            </Fragment>
        );
    }
}

export default Dummy;