import { WARNING_FIRE, WARNING_EXTINGUISH } from '../actions/types';

const initialState = {
    warning: false,
    warningType: null,
    warningMessage: null,
};

const warningReducer = (state = initialState, action) => {
    switch (action.type) {
        case WARNING_FIRE:
            return {
                ...state,
                warning: true,
                warningType: action.payload.warningType,
                warningMessage: action.payload.warningMessage ? action.payload.warningMessage : null,
            }
        case WARNING_EXTINGUISH:
            return {
                ...state,
                warning: false,
                warningType: null,
                warningMessage: null,
            }
        default:
            return state;
    }
}

export default warningReducer;