import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toggleSideMenuBar, setSideMenuBarCollapsed } from '../actions/sideMenuBarActions';
import { withTheme, withStyles } from '@material-ui/core/styles';
import { Drawer, Hidden } from '@material-ui/core';
import SideMenu from './SideMenu';
import storage from '../components/config/storage';

import { sideMenuBarWidth, sideMenuBarMaxWidth, sideMenuBarMaxCollapsedWidth } from '../ui-constants';

const useStyles = theme => ({
    drawer: {
        width: sideMenuBarWidth,
        maxWidth: sideMenuBarMaxWidth,
        flexShrink: 0,
        overflowX: 'hidden',
    },
    drawerCollapsed: {
        width: sideMenuBarWidth,
        maxWidth: sideMenuBarMaxCollapsedWidth,
        flexShrink: 0,
        overflowX: 'hidden',
    },
    drawerPaper: {
        width: sideMenuBarWidth,
        maxWidth: sideMenuBarMaxWidth,
        overflowX: 'hidden',
    },
    drawerPaperCollapsed: {
        width: sideMenuBarWidth,
        maxWidth: sideMenuBarMaxCollapsedWidth,
        overflowX: 'hidden',
    },
    appBarSpacer: theme.mixins.toolbar,
});

class SideMenuBar extends Component {

    constructor(props) {
        super(props);

        const { 
            setSideMenuBarCollapsed,
        } = this.props;

        setSideMenuBarCollapsed(Boolean(storage.getItem('menuBarCollapsed')) || false);
    }

    toggleMenuBarCollapsed = () => {

        const { 
            menuBarCollapsed,
            setSideMenuBarCollapsed,
        } = this.props;

        setSideMenuBarCollapsed(!menuBarCollapsed);
        storage.setItem('menuBarCollapsed', !menuBarCollapsed);
    }

    render() {
        const { 
            theme,
            classes, 
            responsiveSideMenuBarVisibility,
            menuBarCollapsed,
            toggleSideMenuBar,
        } = this.props;

        return (
            <Fragment>
                <Hidden smDown>
                    <Drawer 
                        className={menuBarCollapsed ? classes.drawerCollapsed : classes.drawer}
                        variant='permanent'
                        classes={{
                            paper: menuBarCollapsed ? classes.drawerPaperCollapsed : classes.drawerPaper
                        }}
                    >
                        <div className={classes.appBarSpacer} />
                        <SideMenu  
                            toggleMenuBarCollapsed={this.toggleMenuBarCollapsed}
                            menuBarCollapsed={menuBarCollapsed}
                        />
                    </Drawer>
                </Hidden>

                <Hidden mdUp>
                    <Drawer 
                        className={menuBarCollapsed ? classes.drawerCollapsed : classes.drawer}
                        variant='temporary'
                        open={responsiveSideMenuBarVisibility}
                        onClose={toggleSideMenuBar}
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        classes={{
                            paper: menuBarCollapsed ? classes.drawerPaperCollapsed : classes.drawerPaper
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        <SideMenu 
                            toggleMenuBarCollapsed={this.toggleMenuBarCollapsed}
                            menuBarCollapsed={menuBarCollapsed}
                        />
                    </Drawer>
                </Hidden>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    responsiveSideMenuBarVisibility: state.sideMenuBarReducer.responsiveSideMenuBarVisibility,
    menuBarCollapsed:                state.sideMenuBarReducer.menuBarCollapsed,
});

export default connect(mapStateToProps, { 
    toggleSideMenuBar,
    setSideMenuBarCollapsed,
})(withTheme(withStyles(useStyles)(SideMenuBar)));


SideMenuBar.propTypes = {
    setSideMenuBarCollapsed: PropTypes.func,
    toggleSideMenuBar: PropTypes.func,

    theme: PropTypes.object,
    classes: PropTypes.object,
    menuBarCollapsed: PropTypes.bool,
    responsiveSideMenuBarVisibility: PropTypes.bool,
}