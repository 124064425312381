import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defaultAPIClient } from '../../../api-client/config';
import { requestAPI } from '../../../actions/apiRequestActions';
import SplitterLayout from 'react-splitter-layout';
import '../../../styles/react-splitter-layout.css';
import { Typography, TableContainer, Paper, Table, TableHead, TableCell, TableRow, withStyles, Toolbar, TableBody, LinearProgress, Box, Tabs, Tab, AppBar, withTheme, Hidden, Checkbox, Snackbar } from '@material-ui/core';
import clsx from 'clsx';
import CRUDLTablePaginationComponent from './CRUDLTablePaginationComponent';
import { setEditMode, setSaveState, cancel, setUpdatedSelectedEntity } from '../../../actions/CRUDLEscortComponentActions';
import storage from 'local-storage-fallback';
import ConfirmationDialogComponent from '../../common/ConfirmationDialogComponent';
import CRUDLStyles from '../../../styles/crudl-styles';
import CRUDLTableGroupActionsComponent from './CRUDLTableGroupActionsComponent';
import CRUDLToolsetComponent from './CRUDLToolsetComponent';
import { Alert } from '@material-ui/lab';

const useStyles = theme => ({
    root: {
    },
    grow: {
        flexGrow: 1,
    },
    container: {
        flexGrow: 1,
        display: 'flex',
        flexFlow: 'column',
    }, 
    heading: {
    },
    entitiesListTable: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 2,
        right: 0,
    },
    escortContainer: {
        marginTop: '5px',
    },
    escortTabContainer: {
        position: 'absolute',
        left: '0',
        top: theme.spacing(9),
        bottom: '0',
        right: '0',
        overflow: 'auto',
        display: 'flex',
        flexFlow: 'column',
        padding: theme.spacing(1),
    },
    withToolset: {
        top: theme.spacing(17),
    },
    escortComponentToolset: {
        marginTop: theme.spacing(1),
    }
});

export class CRUDLComponent extends Component {

    state = {
        entities: [],
        entitiesFetching: false,
        selectedEntity: null,
        selectedEscortTabIndex: 0,
        notSavedChangesConfirmationDialogOpened: false,
        notSavedChangesConfirmationDialogProceedCallback: null,
        selectedEntities: {},
        successfullyCreatedEntitySnackBarOpened: false,
        justCreatedEntityId: null,
        deletedEntitiesRefreshDone: false,
    };

    _isMounted = false;

    setStateIfMounted(setStateObj) {
        if (this._isMounted) {
            this.setState(setStateObj);
        }
    }

    componentDidMount() {
        this._isMounted = true;

        this.setState({ 
            entityFetching: false,
        });

        /**
         * 
         * Clear selections (could happen when clicking on re-authentication backdrop)
         * 
         */
        if (window.getSelection) {
            if (window.getSelection().empty) {  // Chrome
                window.getSelection().empty();
            } else if (window.getSelection().removeAllRanges) {  // Firefox
                window.getSelection().removeAllRanges();
            }
        } else if (document.selection) {  // IE?
            document.selection.empty();
        }

        this.requestList(null, null, true);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate() {
        const {
            updatedSelectedEntity,
            Model,
            apiRequestAlias,
            /**
             * Actions
             */
            setUpdatedSelectedEntity,
        } = this.props;
        
        const {
            entities,
        } = this.state;

        /**
         * 
         * Handle Entity update/create
         * 
         */
        if (updatedSelectedEntity) {
            const primaryKey = Model.getDefinitions().primaryKey;
            if (updatedSelectedEntity.UIUUID !== 'JUST_CREATED_ENTITY') {
                const updatedEntities = entities.map(entity => {
                    if (entity[primaryKey] === updatedSelectedEntity[primaryKey]) {
                        const entityToBeUpdated = Object.assign(entity, updatedSelectedEntity);
                        return entityToBeUpdated;
                    }
                    else return entity;
                });
                this.setState({ entities: updatedEntities });
                this.setSelectedEntity(updatedSelectedEntity);
            } else {
                this.setState({ 
                    successfullyCreatedEntitySnackBarOpened: true,
                    justCreatedEntityId: updatedSelectedEntity[primaryKey],
                });
            }
            setUpdatedSelectedEntity(null);
        }

        /**
         * 
         * Handle List refresh on deleted entities
         * 
         * 
         */
        if (Model.getDefinitions().endpoints.delete) {
            if (!this.state.deletedEntitiesRefreshDone && apiRequestAlias === Model.getDefinitions().endpoints.delete.alias) {
                this.setState({ deletedEntitiesRefreshDone: true });
                this.requestList(null, null, true);
            }
        }
    }

    requestList = (filterParams, preRequsetAction = null, forced = false) => {
        const {
            Model,
            CRUDLEntityComponentImpl,
            modified,
        } = this.props;

        if (modified && !this.state.notSavedChangesConfirmationDialogOpened && !forced) {
            this.setState({ 
                notSavedChangesConfirmationDialogProceedCallback: () => { 
                    this.requestList(filterParams, preRequsetAction);
                 },
                notSavedChangesConfirmationDialogOpened: true 
            });
        } else {
            if (typeof preRequsetAction === 'function') {
                /**
                 * 
                 * see filter for details
                 * 
                 */
                preRequsetAction();
            }
            this.setState({ 
                entities: [],
                entitiesFetching: true,
                selectedEntities: {},
                selectedEntity: null 
            });

            CRUDLEntityComponentImpl.setListFilter(filterParams);

            /**
             * Fetch entitiesList
             */
            let requestParams = CRUDLEntityComponentImpl.getListFilterRequestParams();
            if (typeof Model.getDefinitions().endpoints.list.filterRequestParamsTransformer === 'function') {
                requestParams = Model.getDefinitions().endpoints.list.filterRequestParamsTransformer(requestParams);
            }
            this.props.requestAPI({
                apiClient: defaultAPIClient,
                requestParams: {
                    url: Model.getDefinitions().endpoints.list.path + requestParams,
                    method: 'GET',
                },
                requestAlias: Model.getDefinitions().endpoints.list.alias,
                responseCallBack: this.setEntities,
            });
        }
    }

    setEntities = (response) => {
        const {
            Model,
        } = this.props;

        this.setStateIfMounted({ 
            entities: response.data,
            entitiesFetching: false,
            deletedEntitiesRefreshDone: false,
        });
        /**
         * 
         * Refresh just created entity
         * 
         */
        if (this.state.justCreatedEntityId !== null) {

            this.getSelectedEntityFromAPI({
                [Model.getDefinitions().primaryKey]: this.state.justCreatedEntityId,
            });

            this.setStateIfMounted({ 
                justCreatedEntityId: null,
            });
        } else {
            const sessionSelectedEntityPrimaryKey =  sessionStorage.getItem(this.constructor.name + Model.constructor.name + 'SelectedEntityPrimaryKey');
            for (let [i] of Object.entries(this.state.entities)) {
                if(this.state.entities[i][Model.getDefinitions().primaryKey] === sessionSelectedEntityPrimaryKey) {
                    this.setSelectedEntity(this.state.entities[i]);
                    break;
                }
            }
        }
    }

    setSelectedEntity(entityData) {
        const {
            Model,
            /**
             * Actions
             */
            setEditMode,
            setSaveState,
        } = this.props;

        setSaveState(null);
        setEditMode(false);
        const entity = new Model.constructor();
        entity.mapDataToProperties(entityData);

        this.setStateIfMounted({
            selectedEntity: entity,
            entityFetching: false,
        });

        sessionStorage.setItem(this.constructor.name + Model.constructor.name + 'SelectedEntityPrimaryKey', entity[Model.getDefinitions().primaryKey]);
    }

    handleEntitiesTableClick(event, selectedEntityData, targetType) {
        event.stopPropagation();

        const {
            modified,
            saveState,
            Model,
        } = this.props;

        if (this.state.entityFetching || saveState !== null) {
            return;
        }

        if (targetType === 'row') {
            if (Model.getDefinitions().endpoints.getSingle && Model.getDefinitions().endpoints.getSingle.mode !== 'showSelectedEntityDetails') {
                if (modified) {
                    this.setState({ 
                        notSavedChangesConfirmationDialogProceedCallback: () => { this.getSelectedEntityFromAPI(selectedEntityData); },
                        notSavedChangesConfirmationDialogOpened: true 
                    });
                } else {
                    this.getSelectedEntityFromAPI(selectedEntityData);
                }
            } else {
                this.setSelectedEntity(selectedEntityData);
            }
            return;
        }

        const primaryKey = Model.getDefinitions().primaryKey;

        /**
         * 
         * Group table actions handling
         * 
         */
        if (targetType === 'headerCB') {
            if (Object.entries(this.state.selectedEntities).length > 0) {
                this.setState({ selectedEntities: {} });
            } else {
                let selectedEntities = this.state.entities.reduce((obj, item) => (obj[item[primaryKey]] = (item, obj)), {});
                this.setState({ selectedEntities });
            }
            return;
        }

        if (targetType === 'tableCellCB') {
            let selectedEntities = {...this.state.selectedEntities};
            if (!selectedEntities[selectedEntityData[primaryKey]]) {
                selectedEntities[selectedEntityData[primaryKey]] = selectedEntityData;
            } else delete selectedEntities[selectedEntityData[primaryKey]];
            this.setState({ selectedEntities });
            return;
        }
    }

    getSelectedEntityFromAPI(selectedEntityData) {
        const {
            Model,
        } = this.props;

        this.setState({
            entityFetching: true,
        });

        /**
         * 
         * Do API request here to get single Entity
         * 
         */
        this.props.requestAPI({
            apiClient: defaultAPIClient,
            requestParams: {
                url: Model.getDefinitions().endpoints.getSingle.path.replace('{entityId}', selectedEntityData[Model.getDefinitions().primaryKey]),
                method: 'GET',
            },
            requestAlias: Model.getDefinitions().endpoints.getSingle.alias,
            responseCallBack: (response) => { 
                let entity = new Model.constructor();
                entity.mapDataToProperties(response.data)
                this.setSelectedEntity(entity);
             },
        });
    }

    setPageHandler = (page) => {
        this.requestList({ page });
    }

    prevPageHandler = () => {
        const {
            CRUDLEntityComponentImpl,
        } = this.props;

        this.requestList({
            page: CRUDLEntityComponentImpl.getListFilter().page - 1 || 0,
        });
    }

    nextPageHandler = () => {
        const {
            CRUDLEntityComponentImpl,
        } = this.props;

        this.requestList({
            page: CRUDLEntityComponentImpl.getListFilter().page + 1 || 0,
        });
    }

    handleNotSavedChangesConfirmationDialogClose = () => {
        this.setState({ 
            notSavedChangesConfirmationDialogOpened: false, 
            notSavedChangesConfirmationDialogProceedCallback: null,
        });
    }

    render() {
        const {
            theme,
            classes,
            Model,
            CRUDLEntityComponentImpl,
            modified,
            apiIsRequested,
            apiRequestAlias,
            cancel,
            listFilterApplied,
            listFilterChanged,
        } = this.props;

        const primaryKey = Model.getDefinitions().primaryKey;

        const StyledTableHeaderCell = withStyles(theme => ({
            head: {
              backgroundColor: listFilterApplied ? CRUDLStyles.filterAppliedColor : theme.palette.background.default,
            },
            body: {
              fontSize: 14,
            },
        }))(TableCell);

        /***
         * 
         * 
         * DO NOTHING on empty Entities List
         * 
         * 
         */
        if (!this.state.entities) return null;

        return (
            <Fragment>

                {/**
                 * 
                 * Successfully saveState 'saved' from Escort
                 * 
                 */}
                <Snackbar 
                    open={this.state.successfullyCreatedEntitySnackBarOpened} 
                    autoHideDuration={1500}
                    onClose={() => { this.setState({ successfullyCreatedEntitySnackBarOpened: false }); }}
                    anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                >
                    <Alert severity='success' variant='filled'>{Model.getDefinitions().decorators.singleHeading} successfully created!</Alert>
                </Snackbar>

                {/** 
                 * 
                 * Modifications Confirmation Dialog 
                 * 
                 * */}
                <ConfirmationDialogComponent
                    opened={this.state.notSavedChangesConfirmationDialogOpened}
                    onCloseHandler={this.handleNotSavedChangesConfirmationDialogClose}
                    cancelHandler={this.handleNotSavedChangesConfirmationDialogClose}
                    message={'You haven\'t saved your changes.'}
                    proceedHandler={() => {
                        cancel();
                        if (typeof this.state.notSavedChangesConfirmationDialogProceedCallback === 'function') this.state.notSavedChangesConfirmationDialogProceedCallback();
                        this.handleNotSavedChangesConfirmationDialogClose();
                    }}
                />


                {/*
                  _____ _____  _    _ _____  _        _    _                _           
                 / ____|  __ \| |  | |  __ \| |      | |  | |              | |          
                | |    | |__) | |  | | |  | | |      | |__| | ___  __ _  __| | ___ _ __ 
                | |    |  _  /| |  | | |  | | |      |  __  |/ _ \/ _` |/ _` |/ _ \ '__|
                | |____| | \ \| |__| | |__| | |____  | |  | |  __/ (_| | (_| |  __/ |   
                 \_____|_|  \_\\____/|_____/|______| |_|  |_|\___|\__,_|\__,_|\___|_|   
                                                                                        
                                                                                        
                */}
                <Toolbar>
                    {/**
                     * 
                     * Heading 
                     * 
                     * */}
                    <Hidden smDown>
                        <Typography className={classes.heading} variant='h6'>
                            {Model.getDefinitions().decorators.listHeading}
                        </Typography>
                    </Hidden>


                    {this.props.tableToolset !== false &&
                        <CRUDLToolsetComponent 
                            Model={Model}
                            CRUDLEntityComponentImpl={CRUDLEntityComponentImpl}
                            tableToolsetSettings={this.props.tableToolsetSettings}
                            requestList={this.requestList}
                        />
                    }

                    <div className={classes.grow} />

                    <CRUDLTablePaginationComponent 
                        prevPageHandler={this.prevPageHandler}
                        nextPageHandler={this.nextPageHandler}
                        setPageHandler={this.setPageHandler}
                        prevDisabled={ CRUDLEntityComponentImpl.getListFilter().page === 0  }
                        nextDisabled={ this.state.entities.length < (Model.getDefinitions().endpoints.list.pageSize || 10) }
                        currentPage={ CRUDLEntityComponentImpl.getListFilter().page }
                    />

                </Toolbar>

                {/** 
                 * 
                 * Table Group Actions 
                 * 
                 * */}
                <CRUDLTableGroupActionsComponent 
                    Model={Model}
                    selectedEntities={this.state.selectedEntities}
                />

                <Box className={classes.container}>

                    {this.state.entitiesFetching && <LinearProgress /> }

                    <SplitterLayout 
                        onSecondaryPaneSizeChange={(secondaryInitialSize) => {
                            storage.setItem(CRUDLEntityComponentImpl.constructor.name + 'CRUDLComponentSplitterSecondaryInitialSize', parseInt(secondaryInitialSize));
                        }}
                        percentage={true} 
                        vertical={true} 
                        primaryMinSize={10} 
                        secondaryMinSize={10}
                        secondaryInitialSize={
                            parseInt(storage.getItem(CRUDLEntityComponentImpl.constructor.name + 'CRUDLComponentSplitterSecondaryInitialSize')) || 40
                        }
                    >
                        {/*
                          _______    _     _      
                         |__   __|  | |   | |     
                            | | __ _| |__ | | ___ 
                            | |/ _` | '_ \| |/ _ \
                            | | (_| | |_) | |  __/
                            |_|\__,_|_.__/|_|\___|
                        */}
                        <Fragment>

                            <TableContainer component={Paper} className={clsx(classes.entitiesListTable)} style={{ userSelect: 'text', backgroundColor: this.state.entityFetching ? '#e7e7e7aa' : null }}>
                                <Table stickyHeader size='small' aria-label='entities-list'>
                                    <TableHead>
                                        <TableRow>

                                            {/**
                                             * 
                                             * Header group actions checkbox 
                                             * 
                                             * */}
                                             {this.props.tableCheckColumn !== false &&
                                                <StyledTableHeaderCell 
                                                    onClick={event => event.preventDefault()}
                                                    key={'CRUDLTableHeaderCheckBoxCell'}
                                                >
                                                    <Checkbox
                                                        indeterminate={ Object.entries(this.state.selectedEntities).length > 0 && Object.entries(this.state.selectedEntities).length <  Model.getDefinitions().endpoints.list.pageSize }
                                                        checked={ Object.entries(this.state.selectedEntities).length ===  Model.getDefinitions().endpoints.list.pageSize }
                                                        onClick={event => this.handleEntitiesTableClick(event, null, 'headerCB')}
                                                        size='small'
                                                    />
                                                </StyledTableHeaderCell>
                                             }

                                            {Model.getDefinitions().decorators.table.cells.map(cell => {
                                                const tableHeaderCell = 
                                                        <StyledTableHeaderCell key={cell.property}>
                                                            {cell.label ? cell.label 
                                                                        : Model.getDefinitions().decorators.labels[cell.property] 
                                                                        ? Model.getDefinitions().decorators.labels[cell.property] 
                                                                        : <i>{cell.property}</i>}
                                                        </StyledTableHeaderCell>
                                                return (
                                                    <Fragment key={'tableHeaderCellCollapser' + cell.property}>
                                                        {cell.doNotCollapse ? 
                                                            <Fragment>{tableHeaderCell}</Fragment>
                                                            : 
                                                            <Hidden smDown>
                                                                {tableHeaderCell}
                                                            </Hidden>
                                                        }
                                                    </Fragment>
                                                )
                                            })}
                                        </TableRow>
                                    </TableHead>
                                    {/** 
                                     * 
                                     * 
                                     * Table Rows 
                                     * 
                                     * 
                                     * */}
                                    {!this.state.entitiesFetching &&
                                        <TableBody>
                                            {this.state.entities.map(entity => (
                                                <TableRow
                                                    hover={!this.state.entityFetching}
                                                    style={{
                                                        height: "48px",
                                                        backgroundColor: 
                                                        (this.state.selectedEntities[entity[primaryKey]] && (this.state.selectedEntity && entity[primaryKey] === this.state.selectedEntity[primaryKey])) 
                                                        ? '#c7fff4' 
                                                        : 
                                                            this.state.selectedEntities[entity[primaryKey]] && !(this.state.selectedEntity && entity[primaryKey] === this.state.selectedEntity[primaryKey]) 
                                                            ? theme.palette.secondary.light 
                                                            :  ((this.state.selectedEntity && entity[primaryKey] === this.state.selectedEntity[primaryKey]) ? CRUDLStyles.selectedEntityColor : null)
                                                    }}
                                                    onClick={ event => this.handleEntitiesTableClick(event, entity, 'row') }
                                                    tabIndex={-1}
                                                    key={entity[primaryKey]}
                                                    selected={this.state.selectedEntity && entity[primaryKey] === this.state.selectedEntity[primaryKey]}
                                                >
                                                    {/**
                                                     * 
                                                     * Row group actions checkbox 
                                                     * 
                                                     * */}
                                                     {this.props.tableCheckColumn !== false &&
                                                        <TableCell 
                                                            onClick={event => event.preventDefault()}
                                                            key={'CRUDLTableContentCheckBoxCell' + entity[primaryKey]}
                                                        >
                                                            <Checkbox
                                                                checked={this.state.selectedEntities[entity[primaryKey]] !== undefined}
                                                                onClick={event => this.handleEntitiesTableClick(event, entity, 'tableCellCB')}
                                                                size='small'
                                                            />
                                                        </TableCell>
                                                    }

                                                    {Model.getDefinitions().decorators.table.cells.map(cell => {
                                                        const tableContentCell = 
                                                                <TableCell key={cell.property + '_value'}>
                                                                    {entity[cell.property] 
                                                                        ? typeof Model.getDefinitions().properties[cell.property].renderer === 'function' 
                                                                            ? Model.getDefinitions().properties[cell.property].renderer(entity[cell.property])
                                                                            : entity[cell.property] 
                                                                        : null}
                                                                </TableCell>
                                                        return (
                                                            <Fragment key={'tableBodyCellCollapser' + cell.property}>
                                                                {cell.doNotCollapse ?
                                                                    <Fragment>{tableContentCell}</Fragment>
                                                                    : 
                                                                    <Hidden smDown>
                                                                        {tableContentCell}
                                                                    </Hidden>
                                                                }
                                                            </Fragment>
                                                        )
                                                    })}
                                                </TableRow>
                                            ))}
                                        </TableBody>}
                                </Table>
                            </TableContainer>
                        </Fragment>

                        {/*
                         ______                    _   
                        |  ____|                  | |  
                        | |__   ___  ___ ___  _ __| |_ 
                        |  __| / __|/ __/ _ \| '__| __|
                        | |____\__ \ (_| (_) | |  | |_ 
                        |______|___/\___\___/|_|   \__|
                        */}
                        <Fragment>
                            {this.state.selectedEntity && !this.state.entityFetching && !listFilterChanged
                            ? 
                                <Box className={classes.escortContainer}>
                                    <AppBar position='static' color='inherit'>
                                        <Tabs 
                                            value={this.state.selectedEscortTabIndex} 
                                            variant='scrollable'
                                            scrollButtons='auto'
                                            indicatorColor='secondary'
                                            onChange={ (event, escortTabIndex) => { 
                                                if (modified) {
                                                    this.setState({ 
                                                        notSavedChangesConfirmationDialogProceedCallback: () => { this.setState({ selectedEscortTabIndex: escortTabIndex }); },
                                                        notSavedChangesConfirmationDialogOpened: true 
                                                    });
                                                } else this.setState({ selectedEscortTabIndex: escortTabIndex }); 
                                            } }
                                        >
                                            {Model.getDefinitions().escort.tabs.map((escortTab, index) => (
                                                <Tab label={escortTab.heading} key={'entityEscortTabHeading' + index} value={index} />
                                            ))}
                                        </Tabs>
                                    </AppBar>

                                    {Model.getDefinitions().escort.tabs.map((escortTab, index) => {
                                        /** 
                                         * 
                                         * Escort Components 
                                         * 
                                         * */

                                        const EscortComponent = escortTab.component.class;
                                        const escortComponentProps = {
                                            ...escortTab.component.props,
                                            Model,
                                            selectedEntity: this.state.selectedEntity,
                                        }

                                        let EscortComponentToolset = null;
                                        let escortComponentToolsetProps = null;
                                        if (Boolean(escortTab.toolset)) {
                                            EscortComponentToolset = escortTab.toolset.class;
                                            escortComponentToolsetProps = {
                                                ...escortTab.toolset.props,
                                                Model,
                                                selectedEntity: this.state.selectedEntity,
                                            }
                                        }

                                        return (
                                            (Model.getDefinitions().endpoints.getSingle &&
                                                <Fragment key={'entityEscortTabComponent' + index}>
                                                    {(apiIsRequested && 
                                                      apiRequestAlias === Model.getDefinitions().endpoints.getSingle.alias && 
                                                      Model.getDefinitions().endpoints.getSingle.mode !== 'showSelectedEntityDetails') 
                                                        ? <LinearProgress className={classes.grow} style={{maxHeight: '1px'}} color='secondary'/>
                                                        : <Fragment>
                                                            {index === this.state.selectedEscortTabIndex &&
                                                                <Fragment>
                                                                    {/** 
                                                                     * 
                                                                     * Escort Toolset 
                                                                     * 
                                                                     * */}
                                                                    <Box className={classes.escortComponentToolset}>
                                                                        {EscortComponentToolset &&
                                                                            <EscortComponentToolset {...escortComponentToolsetProps}/>
                                                                        }
                                                                    </Box>
                                                                    
                                                                    {Boolean(EscortComponentToolset) && <hr style={{ heght: '1px' }}/>}

                                                                    {/** 
                                                                     * 
                                                                     * Escort Tab Component 
                                                                     * 
                                                                     * */}
                                                                    <Box  style={escortTab.component.style} className={clsx(classes.escortTabContainer, EscortComponentToolset ? classes.withToolset : null)}>
                                                                        <EscortComponent {...escortComponentProps}/>
                                                                    </Box>
                                                                </Fragment>
                                                            }
                                                        </Fragment>
                                                    }
                                                </Fragment>
                                            )
                                        )
                                    })}
                                </Box>
                            : 
                                (Model.getDefinitions().endpoints.getSingle &&
                                    <Fragment>
                                        {(apiIsRequested && apiRequestAlias === Model.getDefinitions().endpoints.getSingle.alias) && <LinearProgress className={classes.grow}/> }
                                    </Fragment> 
                                )
                            }
                        </Fragment>
                    </SplitterLayout>
                </Box>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    apiRequestId:           state.apiRequestReducer.apiRequestId,
    apiRequestAlias:        state.apiRequestReducer.apiRequestAlias,
    apiRequestResult:       state.apiRequestReducer.apiRequestResult,
    apiIsRequested:         state.apiRequestReducer.apiIsRequested,
    modified:               state.CRUDLEscortComponentReducer.modified,
    saveState:              state.CRUDLEscortComponentReducer.saveState,
    updatedSelectedEntity:  state.CRUDLEscortComponentReducer.updatedSelectedEntity,
    listFilterApplied:      state.CRUDLEscortComponentReducer.listFilterApplied,
    listFilterChanged:          state.CRUDLEscortComponentReducer.listFilterChanged,
});


export default connect(mapStateToProps, {
    requestAPI,
    setEditMode,
    setSaveState,
    cancel,
    setUpdatedSelectedEntity,
})(withTheme(withStyles(useStyles)(CRUDLComponent)));


CRUDLComponent.propTypes = {
    requestAPI: PropTypes.func,
    setEditMode: PropTypes.func,
    setSaveState: PropTypes.func,
    cancel: PropTypes.func,
    setUpdatedSelectedEntity: PropTypes.func,

    processSaveResponse: PropTypes.func,


    selectedEntity: PropTypes.object,
    listFilterApplied: PropTypes.bool,
    listFilterChanged: PropTypes.bool,
    classes: PropTypes.object,
    theme: PropTypes.object,
    Model: PropTypes.object,
    CRUDLEntityComponentImpl: PropTypes.object,
    apiRequestId: PropTypes.string,
    apiRequestAlias: PropTypes.string,
    apiRequestResult: PropTypes.object,
    apiIsRequested: PropTypes.bool,
    modified: PropTypes.bool,
    saveState: PropTypes.string,
    updatedSelectedEntity: PropTypes.object,
}