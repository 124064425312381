import { WARNING_FIRE, WARNING_EXTINGUISH } from './types';

export const fireWarning = (warningType, warningMessage) => dispatch => {
    dispatch({
        type: WARNING_FIRE,
        payload: {
            warningType,
            warningMessage,
        }
    });
}

export const extinguishWarning = () => dispatch => {
    dispatch({
        type: WARNING_EXTINGUISH,
        payload: {}
    });
}