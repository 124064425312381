import React, { Component, Fragment } from 'react';
import CRUDLEscortFlatEntityFormComponent from './CRUDLEscortFlatEntityFormComponent';
import { connect } from 'react-redux';
import JSONPretty from 'react-json-pretty';
import { Typography, LinearProgress } from '@material-ui/core';
import FormattedValueDetailsField from './FormattedValueDetailsField';

class PreviewDetailsComponent extends Component {

    state = {
        loading: false
    }

    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.selectedEntity!==this.props.selectedEntity){
            this.setState({ loading: true });
            setTimeout(() => this.setState({ loading: null }), 200);
        }
    }

    render() {
        const {
            Model,
            propertiesDefinition,
            selectedEntity,
        } = this.props;

        return (
            <Fragment>
                {this.state.loading && <LinearProgress style={{maxHeight: '2px'}} color='secondary'/>}
                <table className="previewDetailsComponent">
                    <tbody>
                    {Object.keys(Model.getDefinitions()[propertiesDefinition]).map((property, index) => {
                        const hidden = Model.getDefinitions()[propertiesDefinition][property].hidden;
                        if (!hidden) {
                            return (
                                <tr key={'property' + index}>
                                    <td style={{ width: "30%", height: "36px" }}>
                                        <Typography variant='body1'>
                                            <strong>
                                                {
                                                    Model.getDefinitions().decorators.labels[property] 
                                                    ? Model.getDefinitions().decorators.labels[property]
                                                    : property
                                                }:</strong>
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography variant='body1'>
                                            <FormattedValueDetailsField 
                                                fieldValue={selectedEntity[property]}
                                                fieldDef={Model.getDefinitions()[propertiesDefinition][property]}
                                            />
                                        </Typography>
                                    </td>
                                </tr>
                            )
                        }
                    })}
                    </tbody>
                </table>
            </Fragment>
        )

        return <JSONPretty data={selectedEntity}/>
    }
}

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, null)(PreviewDetailsComponent);