import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ExpansionPanel, Typography, ExpansionPanelSummary, ExpansionPanelDetails, CircularProgress } from '@material-ui/core';
import HourlyRestrictions from '../access-restrictions/HourlyRestrictions';
import TimeRangeRestrictions from '../access-restrictions/TimeRangeRestrictions';
import { connect } from 'react-redux';
import { defaultAPIClient } from '../../api-client/config';
import { requestAPI } from '../../actions/apiRequestActions';
import UserAccessRestriction from '../../models/UserAccessRestrictions';

export default connect(null, {
    requestAPI,
})(function UserAccessRestrictionsComponent({
    requestAPI,
    Model,
    selectedEntity,
}) {

    const [loading, setLoading] = useState(true);
    const [userAccessRestrictions, setUserAccessRestrictions] = useState(new UserAccessRestriction(selectedEntity.id));

    const getUserAccessRestrictions = () => {
        setLoading(true);
        requestAPI({
            apiClient: defaultAPIClient,
            requestParams: {
                url: Model.getDefinitions().endpoints.getUserAccessRestrictions.path.replace(':userId', selectedEntity.id),
                method: 'GET',
            },
            requestAlias: Model.getDefinitions().endpoints.getUserAccessRestrictions.alias,
            responseCallBack: (response) => { 
                processAPIResponse(response);
            },
        });
    }

    useEffect(() => {
        getUserAccessRestrictions();
    }, []);

    const processAPIResponse = (response) => {
        if (response.data) {
            setUserAccessRestrictions(response.data.accessRestrictions);
            console.log('>>>>>>>>>>>>>>', response.data.accessRestrictions);
        }
        setLoading(false);
    }

    const updateUserAccessRestrictions = updatedUserAccessRestrictions => {
        // console.log('>>>>>>>>>>>>>>', {...updatedUserAccessRestrictions});
        setLoading(true);
        requestAPI({
            apiClient: defaultAPIClient,
            requestParams: {
                url: Model.getDefinitions().endpoints.saveUserAccessRestrictions.path,
                method: 'POST',
                data: {...updatedUserAccessRestrictions},
            },
            requestAlias: Model.getDefinitions().endpoints.saveUserAccessRestrictions.alias,
            responseCallBack: (response) => { 
                getUserAccessRestrictions();
            },
        });
    }

    if (loading) {
        return (<CircularProgress />)
    }

    return (
        <div style={{ width: "100%" }}>
            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <div className="horizontalFlex fullWidth">
                        <Typography variant="subtitle2">Hourly Restrictions</Typography>
                        <div className="flexExpander"></div>
                        {!userAccessRestrictions.hourlyRestriction && <i>Not Set</i>}
                        <div className="flexExpander"></div>
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <HourlyRestrictions userAccessRestrictions={userAccessRestrictions} updateUserAccessRestrictions={updateUserAccessRestrictions}/>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <br/>
            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="subtitle2">Time Range Restrictions</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <TimeRangeRestrictions userAccessRestrictions={userAccessRestrictions}  updateUserAccessRestrictions={updateUserAccessRestrictions}/>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    );

})